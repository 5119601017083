import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';
import { Body } from '../Typography';

const Wrapper = styled.div`
  position: relative;
  background: ${({ theme, isActive, isDisabled }) =>
    isActive ? (isDisabled ? theme.colors.successLight : theme.colors.success) : theme.colors.gray500};
  border-radius: 32px;
  width: 30px;
  height: 18px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'no-drop' : 'pointer')};

  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.surface};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    width: 16px;
    height: 16px;
    margin: 2px;
    transition: 200ms;
    left: ${({ isActive }) => (isActive ? 11 : -1)}px;
    top: -1px;
  }
`;

const TextBefore = styled(Body)`
  margin-right: 10px;
`;

const Text = styled(Body)`
  margin-left: 10px;
`;

const Toggle = ({ value, onChange, text, textBefore, isDisabled, className }) => (
  <FlexMiddle className={className}>
    {textBefore && <TextBefore>{textBefore}</TextBefore>}
    <Wrapper isActive={!!value} isDisabled={isDisabled} onClick={isDisabled ? undefined : (e) => onChange(!value, e)} />
    {text && <Text>{text}</Text>}
  </FlexMiddle>
);

export default Toggle;
