import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { changePageTitle } from '@ubeya/shared/hooks/usePageTitle';
import { FlexColumn, NavigationMenu, FlexCenter, FlexMiddle, Body } from '@ubeya/shared-web/components';
import { ReactComponent as MenuIcon } from '@ubeya/shared-web/assets/expand.svg';
import { ReactComponent as ArrowIcon } from '@ubeya/shared-web/assets/minimize.svg';
import { media } from '@ubeya/shared-web/style';
import { scrollbar2 } from '@ubeya/shared-web/style/scrollbar';
import { Tooltip } from '../Tooltip';

const Wrapper = styled(FlexColumn)`
  position: relative;
  display: none;
  ${media.tablet`display: flex`};
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgroundDark};
  transition: 200ms;
  width: 66px;
  ${({ $isOpen }) => $isOpen && 'width: 250px;'}
`;

const CircleIcon = styled(FlexCenter)`
  padding: 9px;
  border-radius: 8px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  transition: 200ms;
`;

const Label = styled(Body)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.surface};
  transition: 200ms;
  white-space: nowrap;
`;

const Link = styled(FlexMiddle)`
  text-decoration: none;
  padding: 12px 15px;
  transition: 200ms;

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      > ${Label} {
        display: none;
      }
    `}

  &.active {
    ${({ $isOpen }) => $isOpen && `background: #dcd9ff38;`}
    > ${CircleIcon} {
      background: ${({ theme }) => theme.colors.primarySemiLight};
    }
  }

  &:hover {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            background: #444677;
          `
        : css`
            > ${CircleIcon} {
              background: ${({ theme }) => theme.colors.primary};
            }
          `}
  }
`;

const ToggleButtonWrapper = styled(FlexCenter)`
  position: absolute;
  top: 80px;
  right: -20px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 100%;
  padding: 2px;
  cursor: pointer;
  z-index: 4;
`;

const ToggleButton = styled(MenuIcon)`
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.colors.primary};
  padding: 2px;
  border-radius: 100%;

  ${({ $isOpen, theme }) => ($isOpen ? `> path{fill: ${theme.colors.surface};}` : `stroke: ${theme.colors.surface};`)}
`;

const Links = styled(FlexColumn)`
  position: absolute;
  top: 0;
  bottom: 66px;
  left: 0;
  right: 0;
  overflow: hidden auto;
  ${scrollbar2}

  &:not(:first-child) {
    top: 120px;
  }
`;

const AppSideBarItem = ({ to, Icon, t, label, isOpen, LinkRenderer }) => {
  const onClick = useCallback(() => changePageTitle(label, t), [label, t]);
  return (
    <Tooltip body={t(label)} placement="right" disabled={isOpen}>
      <div>
        <Link as={LinkRenderer} to={to} $isOpen={isOpen} onClick={onClick}>
          <CircleIcon>
            <Icon />
          </CircleIcon>
          <Label>{t(label)}</Label>
        </Link>
      </div>
    </Tooltip>
  );
};

const AppSideBar = ({ links, linkRenderer: LinkRenderer = NavLink, children, mobileRenderer = children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const flatLinks = useMemo(() => links.flat(), [links]);

  return (
    <>
      <NavigationMenu linkRenderer={LinkRenderer} links={flatLinks}>
        {React.cloneElement(mobileRenderer, { isMobile: true })}
      </NavigationMenu>

      <Wrapper $isOpen={isOpen}>
        <ToggleButtonWrapper onClick={() => setIsOpen(!isOpen)}>
          <ToggleButton $isOpen={isOpen} as={isOpen ? ArrowIcon : undefined} />
        </ToggleButtonWrapper>

        <FlexColumn>
          {links.map((subLinks, index) => (
            <Links key={index}>
              {subLinks
                .filter(({ isVisible = true }) => isVisible)
                .map(({ to, icon: Icon, label }, linkIndex) => (
                  <AppSideBarItem
                    key={linkIndex}
                    to={to}
                    Icon={Icon}
                    t={t}
                    label={label}
                    isOpen={isOpen}
                    LinkRenderer={LinkRenderer}
                  />
                ))}
            </Links>
          ))}
        </FlexColumn>

        <Link>{children}</Link>
      </Wrapper>
    </>
  );
};

export default AppSideBar;
