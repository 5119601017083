import React from 'react';
import styled from 'styled-components';
import starFill from '../../../assets/star-fill.svg';
import { FlexMiddle } from '../../Flex';

const StarIcon = styled.div`
  width: 13px;
  height: 13px;
  background: url(${starFill});
  background-size: cover;
  margin-right: 2px;
`;

const RatingChip = ({ value }) => (
  <FlexMiddle>
    <StarIcon />
    <span>({value})</span>
  </FlexMiddle>
);

export default RatingChip;
