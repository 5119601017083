import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import useFilters from '@ubeya/shared/hooks/account/useFilters';
import useFields from '@ubeya/shared/hooks/account/useFields';
import { formatOptions, removeFilter } from '../../utils/filters';
import { FlexMiddle } from '../Flex';
import DropDown from '../DropDown';
import FilterOptions from '../Filter/FilterOptions';
import Chip from './Chip';
import PositionChip from './CustomChips/PositionsChip';
import RatingChip from './CustomChips/RatingChip';
import EmployeeTypeChip from './CustomChips/EmployeeTypeChip';
import AdminChip from './CustomChips/AdminChip';
import TagsChip from './CustomChips/TagsChip';
import ClientsChip from './CustomChips/ClientsChip';
import RestrictedClientChip from './CustomChips/RestrictedClientChip';
import LocationsChip from './CustomChips/LocationsChip';
import FieldChip from './CustomChips/FieldChip';

const COMPONENTS_MAP = {
  positions: PositionChip,
  rating: RatingChip,
  employeeType: EmployeeTypeChip,
  adminType: AdminChip,
  tags: TagsChip,
  clients: ClientsChip,
  locations: LocationsChip,
  restrictedClient: RestrictedClientChip
};

const Wrapper = styled(FlexMiddle)`
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -10px;
`;

const FiltersRow = ({ selectedKey, filters, setFilters, disabledFilters = [], usePortal, className }) => {
  const { t } = useTranslation();
  const { filters: data } = useFilters();
  const { mappedFields } = useFields();

  const options = useMemo(() => {
    const basicOptions = formatOptions({ options: data[selectedKey], type: 'basic', filters });

    const fieldsOptions = formatOptions({
      options: data[selectedKey],
      type: 'fields',
      formatSlug: (id) => mappedFields?.[id]?.name,
      filters
    });

    return [...basicOptions, ...fieldsOptions].filter(({ value }) => Number.isInteger(value) || !isEmpty(value));
  }, [data, filters, mappedFields, selectedKey]);

  const handleRemoveFilter = useCallback(
    ({ type, id }) => {
      setFilters(removeFilter({ type, id, allFilters: filters }));
    },
    [filters, setFilters]
  );

  return (
    <Wrapper className={className}>
      {options.map(({ slug, value, type, id }, index) => {
        const isDisabled = disabledFilters.includes(slug);
        const Container = isDisabled ? ({ renderValue }) => renderValue : DropDown;
        const Component = type === 'basic' ? COMPONENTS_MAP[slug] : FieldChip;
        return (
          <Container
            key={index}
            openFromRight={!usePortal}
            usePortal={usePortal}
            showArrow={false}
            renderValue={
              <Chip
                label={t(slug)}
                value={Component ? <Component id={id} value={value} /> : Array.isArray(value) ? value.join(',') : value}
                onRemove={isDisabled ? undefined : () => handleRemoveFilter({ type, id })}
              />
            }>
            <FilterOptions {...{ selectedKey, filters, setFilters, selectedOpenFilter: { type, id, slug, value } }} />
          </Container>
        );
      })}
    </Wrapper>
  );
};

export default FiltersRow;
