import React, { useEffect, useState } from 'react';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import AccountForm from './AccountForm';
import AccountExists from './AccountExists';
import ValidateEmail from './ValidateEmail';

const CreateAccount = () => {
  const [accountExists, setAccountExists] = useState(false);
  const [accountInfo, setAccountInfo] = useState();
  const { email, company, countryId } = useQueryParams();

  useEffect(() => {
    if (email && company) {
      setAccountInfo({ ...(accountInfo || {}), email, company, countryId });
    }
  }, [accountInfo, company, email, countryId]);

  return (
    <>
      {accountInfo ? (
        <ValidateEmail
          accountInfo={accountInfo}
          onNewAccount={() => {
            setAccountExists(false);
            setAccountInfo();
          }}
        />
      ) : accountExists ? (
        <AccountExists />
      ) : (
        <AccountForm setAccountExists={setAccountExists} setAccountInfo={setAccountInfo} email={email} />
      )}
    </>
  );
};

export default CreateAccount;
