import { useState, useCallback, useMemo, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { openViaMobile } from '@ubeya/shared/atoms/shared';
import usePersistedState from './usePersistedState';

const useVisibleColumn = (allColumns = [], storageName) => {
  const isMobile = useRecoilValue(openViaMobile);

  const getVisibleColumn = useCallback((allColumns) => {
    const columns = allColumns
      .filter((column) => column)
      .reduce((prev, { dataKey, visible }) => ({ ...prev, [dataKey]: visible !== false }), {});
    return columns;
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = usePersistedState(storageName, () => getVisibleColumn(allColumns));

  const updateVisibleColumns = (selectedVisibleColumns) => {
    setVisibleColumns(selectedVisibleColumns.reduce((prev, dataKey) => ({ ...prev, [dataKey]: true }), {}));
    setIsMenuOpen(false);
  };

  const columns = useMemo(
    () =>
      allColumns.filter(
        (column) => column && visibleColumns[column.dataKey] && (isMobile ? !column.hideOnMobile : true)
      ),
    [allColumns, isMobile, visibleColumns]
  );

  const resetToDefault = useCallback(() => {
    setVisibleColumns(getVisibleColumn(allColumns));
    localStorage.removeItem(storageName);
  }, [allColumns, getVisibleColumn, setVisibleColumns, storageName]);

  useEffect(() => {
    if (!storageName) {
      setVisibleColumns(getVisibleColumn(allColumns));
    }
  }, [allColumns, getVisibleColumn, setVisibleColumns, storageName]);

  return { visibleColumns: columns, updateVisibleColumns, isMenuOpen, setIsMenuOpen, resetToDefault };
};

export default useVisibleColumn;
