import React from 'react';
import BaseTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

const Tooltip = ({ body, children, disabled, className, placement = 'top' }) =>
  disabled ? (
    children
  ) : (
    <BaseTooltip
      placement={placement}
      destroyTooltipOnHide={{ keepParent: false }}
      overlay={Array.isArray(body) ? body.map((item, index) => <div key={index}>{item}</div>) : body}
      overlayClassName={className}>
      {children}
    </BaseTooltip>
  );

export default Tooltip;
