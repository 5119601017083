import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Body, FlexCenter, FlexMiddle, FlexSpace, bodySemiBold } from '@ubeya/shared-web/components';
import { ReactComponent as ResendIcon } from '@ubeya/shared-web/assets/resend.svg';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import * as api from 'shared/services/api';
import Layout from './Layout';
import useDigitInput from 'createAccount/hooks/useDigitInput';

const Code = styled(FlexMiddle).attrs(() => ({ dir: 'rtl' }))`
  margin-top: 30px;
`;

const Input = styled.input`
  width: 64px;
  height: 71px;
  font-size: 40px;
  text-align: center;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  &:focus {
    box-shadow: ${({ theme }) => theme.colors.inputShadow};
  }
  margin-right: 1px;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Button = styled(FlexCenter)`
  ${bodySemiBold};
  min-height: 38px;
  cursor: pointer;

  > svg {
    margin-right: 16px;
  }
`;

const ErrorBox = styled(FlexCenter)`
  margin: 20px 0;
  border: 1px solid ${({ theme }) => theme.colors.failure};
  color: ${({ theme }) => theme.colors.failure};
  background: ${({ theme }) => theme.colors.failureLight};
  border-radius: 8px;
  padding: 10px;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

const CODE_LENGTH = 5;

const ValidateEmail = ({ accountInfo }) => {
  const { code } = useQueryParams();
  const { t } = useTranslation();
  const [value, onChange] = React.useState('');
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: CODE_LENGTH,
    value,
    onChange,
    onFinish: (code) => sendCode({ email: accountInfo.email, code })
  });

  useEffect(() => {
    if (code) {
      sendCode({ email: accountInfo.email, code });
    }
  }, [accountInfo.email, code, sendCode]);

  const { mutateAsync: sendCode, isLoading: sendCodeLoading, isError } = useMutation(api.sendCode, {
    onSuccess: () =>
      (window.location = `${process.env.WEB_URL}?name=${encodeURIComponent(accountInfo.company)}${
        !accountInfo.countryId ? '' : `&countryId=${encodeURIComponent(accountInfo.countryId)}`
      }`)
  });

  const { mutateAsync: resendEmail, isLoading: resendEmailLoading } = useMutation(api.resendEmail);

  return (
    <Layout title={t('checkInbox')} left width={420} loading={sendCodeLoading || resendEmailLoading}>
      {accountInfo.email && <Body>{t('emailSentTo', { email: accountInfo.email })}</Body>}
      <Body>{t('fillBelow')}</Body>

      <Code>
        {[...Array(CODE_LENGTH)].map((_, index) => (
          <Input key={index} autoFocus={index === 0} inputMode="decimal" {...digits[index]} />
        ))}
      </Code>

      <ErrorBox $visible={isError}>{t('invalidCode')}</ErrorBox>

      <FlexSpace>
        <Button onClick={() => resendEmail({ email: accountInfo.email })}>
          <ResendIcon />
          {t('resendEmail')}
        </Button>
      </FlexSpace>
    </Layout>
  );
};

export default ValidateEmail;
