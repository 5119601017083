import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const useTranslatedOptions = (options) => {
  const { t } = useTranslation();

  const translatedOptions = useMemo(() => options.map((option) => ({ ...option, label: t(option.label) })), [
    options,
    t
  ]);

  return translatedOptions;
};

export default useTranslatedOptions;
