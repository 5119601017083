import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Flex } from '../Flex';
import Button from '../Button';
import Prompt from '../Prompt';

const Wrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.surface};
  flex-direction: row-reverse;
  padding: 15px 19px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray800};

  > button {
    margin-left: 10px;
  }
`;

const ActionsBar = ({ isChanged, submitting, form }) => {
  if (!isChanged) {
    return null;
  }

  return (
    <Wrapper>
      <Button primary isLoading={submitting}>
        <Trans>save</Trans>
      </Button>

      <Button secondary disabled={submitting} onClick={() => form.reset()}>
        <Trans>cancel</Trans>
      </Button>

      <Prompt when />
    </Wrapper>
  );
};

export default ActionsBar;
