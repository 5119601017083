import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Trans } from 'react-i18next';
import useTimeout from '@ubeya/shared/hooks/useTimeout';
import useShowToaster from '@ubeya/shared/hooks/useShowToaster';
import { bodySemiBold, Card, flexMiddle } from '@ubeya/shared-web/components';
import successIcon from '@ubeya/shared-web/assets/success-icon.svg';
import failureIcon from '@ubeya/shared-web/assets/failure-icon.svg';
import warningIcon from '@ubeya/shared-web/assets/warning-icon.svg';
import infoIcon from '@ubeya/shared-web/assets/info-icon.svg';

const TYPES = {
  success: { icon: successIcon, color: 'success', prefix: 'toasterSuccessPrefix' },
  error: { icon: failureIcon, color: 'failure', prefix: 'toasterErrorPrefix' },
  warning: { icon: warningIcon, color: 'warning', prefix: 'toasterWarningPrefix' },
  info: { icon: infoIcon, color: 'info', prefix: 'toasterInfoPrefix' }
};

const animation = keyframes`
	0%{transform: translateX(-430px);}
	6%{transform: translateX(0px);}
	94%{transform: translateX(0px);}
	100%{transform: translateX(-430px);}
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10;
`;

const Message = styled(Card)`
  ${flexMiddle};
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.gray200};
  padding: 20px;
  border-radius: 8px;
  animation: ${animation} 4s ease-out forwards;
  margin-top: 20px;
  max-height: 64px;

  &:before {
    content: '';
    width: 64px;
    height: 64px;
    display: block;
    margin: -20px 20px -20px -20px;
    border-radius: 8px 0 0 8px;
    background: ${({ theme, $type }) => theme.colors[TYPES[$type].color]};
    background-image: url(${({ $type }) => TYPES[$type].icon});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const Prefix = styled.span`
  ${bodySemiBold}
  color: ${({ theme, $type }) => theme.colors[TYPES[$type].color]};
  margin-right: 20px;
`;

const Text = styled.div`
  max-width: 210px;
`;

const Toaster = () => {
  const { data, removeFirst } = useShowToaster();

  useTimeout(removeFirst, 4000, [data]);

  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      {data?.map((message, index) => (
        <Message key={index} $type={message.type}>
          <Prefix $type={message.type}>
            <Trans>{TYPES[message.type].prefix}</Trans>
          </Prefix>

          <Text>{message.text}</Text>
        </Message>
      ))}
    </Wrapper>
  );
};

export default Toaster;
