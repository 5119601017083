import React, { memo } from 'react';
import HeaderCell from './HeaderCell';
import { HeaderRowWrapper, ColumnsListActions } from './styles';

const HeaderRow = ({
  columns,
  sortBy,
  sortDirection,
  onSort,
  onColumnResize,
  HeaderCellRenderer,
  showColumnsMenu,
  columnsActionListRenderer,
  setIsMenuOpen,
  isRtl
}) => (
  <HeaderRowWrapper>
    {columns.map((column) => (
      <HeaderCell
        key={column.dataKey}
        column={column}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
        onResize={onColumnResize}
        HeaderCellRenderer={HeaderCellRenderer}
        isRtl={isRtl}
      />
    ))}

    {showColumnsMenu && (
      <ColumnsListActions>{columnsActionListRenderer?.({ onOpen: () => setIsMenuOpen(true) })}</ColumnsListActions>
    )}
  </HeaderRowWrapper>
);

export default memo(HeaderRow);
