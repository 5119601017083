const privileges = {
  UBEYA_ADMIN: -1,
  ACCOUNT_ADMIN: 1,
  BRANCH_ADMIN: 2,
  MANAGER_ADMIN: 3,
  EDIT_PERMISSIONS: 4,
  TIMESHEET: 5,
  PAYROLL: 6,
  DIRECTORY: 7,
  COMMUNICATION: 8,
  CRM: 9,
  SCHEDULING: 10,
  FEED: 11,
  EDIT_HR: 12,
  SEQUENCE: 13,
  BILLING: 14,
  ORDERS: 9
};

export default privileges;

// make a map from privileges
export const mapPrivileges = (privileges = []) =>
  (privileges || []).reduce((prev, id) => ({ ...prev, [id]: true }), {});

export const allPrivilegesExcept = (...exceptPrivileges) =>
  Object.values(privileges).filter((value) => !exceptPrivileges.includes(value));

export const ubeyaPrivileges = allPrivilegesExcept(privileges.BRANCH_ADMIN, privileges.MANAGER_ADMIN).reduce(
  (prev, value) => ({ ...prev, [value]: true }),
  { [privileges.UBEYA_ADMIN]: true }
);
