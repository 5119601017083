import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useTranslatedOptions from '@ubeya/shared/hooks/useTranslatedOptions';
import { getDatesRange } from '@ubeya/shared/constants';
import { Flex, FlexColumn, FlexColumnCenter } from '../Flex';
import Button from '../Button';
import { body } from '../Typography';
import { card } from '../Card';
import BaseDateRangePicker from './DateRangePicker';

const Wrapper = styled(Flex)`
  ${card};

  > div:last-child {
    ${({ isCustom, theme }) => isCustom && `border-left: 1px solid ${theme.colors.gray910}`};
  }
`;

const Option = styled(Button).attrs(() => ({ type: 'button' }))`
  ${body};
  text-align: left;
  border-radius: 0;
  padding: 15px 20px;
  width: 154px;
  color: ${({ theme }) => theme.colors.gray200};

  ${({ theme, isActive }) => isActive && `background-color:${theme.colors.backgroundLight}!important`};
`;

const DateRangePicker = styled(BaseDateRangePicker)`
  .DayPicker__withBorder {
    box-shadow: none;
  }
`;

const ApplyButton = styled(Button)`
  margin-bottom: 20px;
  margin-top: -10px;
`;

const DatePickerWithOptions = ({ value, onChange, onClose, allOption, options: newOptions, className }) => {
  const { t } = useTranslation();
  const customOptions = useMemo(() => newOptions || (allOption ? { all: {}, ...getDatesRange() } : getDatesRange()), [
    allOption,
    newOptions
  ]);

  const options = useTranslatedOptions(
    Object.entries(customOptions).map(([key, { start, end }]) => ({ key, label: key, start, end }))
  );

  const [customRange, setCustomRange] = useState(value);

  return (
    <Wrapper className={className} isCustom={value?.key === 'custom'}>
      <FlexColumn>
        {options.map(({ key, label, start, end }) => (
          <Option
            key={key}
            tertiary
            isActive={value?.key === key}
            onClick={(e) => {
              e.stopPropagation();
              onChange({ key, start, end });
              key !== 'custom' && onClose();
            }}>
            {label}
          </Option>
        ))}
      </FlexColumn>

      {value?.key === 'custom' && (
        <FlexColumnCenter>
          <DateRangePicker
            startDate={customRange.start}
            endDate={customRange.end}
            onChange={({ startDate, endDate }) => setCustomRange({ key: 'custom', start: startDate, end: endDate })}
            isDayBlocked={customOptions.custom.isDayBlocked}
          />

          <ApplyButton
            primary
            disabled={!customRange.start || !customRange.end}
            onClick={(e) => {
              e.stopPropagation();
              onChange(customRange);
              onClose();
            }}>
            {t('apply')}
          </ApplyButton>
        </FlexColumnCenter>
      )}
    </Wrapper>
  );
};

export default DatePickerWithOptions;
