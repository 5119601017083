import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { ReactComponent as BaseVSign } from '../../assets/v.svg';
import { Tooltip } from '../Tooltip';
import TimeAgoRenderer from './TimeAgoRenderer';

const VSign = styled(BaseVSign)`
  > path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

const CheckedTimeAgo = ({ rowData, dataKey, className }) => {
  const value = get(rowData, dataKey);

  return value ? (
    <Tooltip body={<TimeAgoRenderer rowData={rowData} dataKey={dataKey} />}>
      <VSign className={className} />
    </Tooltip>
  ) : (
    <></>
  );
};

export default CheckedTimeAgo;
