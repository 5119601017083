import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import * as api from '@ubeya/shared/services/api';
import { ALLOWED_PHOTO_TYPES } from '@ubeya/shared/constants';
import { flexCenter } from '../Flex';
import { small } from '../Typography';
import { Tooltip } from '../Tooltip';
import { ReactComponent as FileIcon } from '../../assets/camera.svg';
import BaseFileUpload from './FileUpload';
import ImageCrop from './ImageCrop';

const FileUpload = styled(BaseFileUpload)`
  position: relative;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'default')};
  ${flexCenter};
  ${small};
  border-radius: 100%;
  width: 150px;
  height: 150px;
  min-height: 150px;
  background: ${({ theme }) => theme.colors.surface};

  &&& {
    background-image: url(${({ image }) => image});
    background-size: cover;
    background-position: center;
  }
  color: ${({ theme }) => theme.colors.gray400};
  border: 2px solid ${({ theme }) => theme.colors.gray700};
  text-align: center;
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const UploadBackground = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid ${({ theme }) => theme.colors.background};
  transition: background 200ms;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }
`;

const UploadIcon = styled(FileIcon)`
  width: 20px;
  height: 20px;
`;

const defaultDataSetter = (value) => value;
const defaultDataGetter = (value) => value.path;

const ImageUpload = ({
  value,
  onChange,
  children,
  dataSetter = defaultDataSetter,
  dataGetter = defaultDataGetter,
  className,
  showUploadIcon = true,
  cropBeforeUpload = true,
  enabled = true
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(dataSetter(value));
  const [cropModal, setCropModal] = useState(false);

  const onDrop = useCallback(
    (file) => {
      if (cropBeforeUpload) {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          setCropModal(fileReader.result);
        };
        fileReader.readAsDataURL(file);
      } else {
        setImage(URL.createObjectURL(file));
      }
    },
    [cropBeforeUpload]
  );

  const onUploaded = useCallback((data, file) => onChange(dataGetter(data), file && URL.createObjectURL(file)), [
    dataGetter,
    onChange
  ]);

  useEffect(() => {
    if (value?.includes?.('http')) {
      setImage(dataSetter(value));
    }
  }, [dataSetter, value]);

  return (
    <>
      <FileUpload
        className={className}
        enabled={enabled}
        image={image}
        apiFunc={cropBeforeUpload ? undefined : api.uploadImage}
        onUploaded={onUploaded}
        onDrop={onDrop}
        accept={ALLOWED_PHOTO_TYPES}
        multiple={false}>
        {!image && (children ?? <Trans>addPhoto</Trans>)}
        {showUploadIcon && (
          <Tooltip body={t('upload')}>
            <UploadBackground>
              <UploadIcon />
            </UploadBackground>
          </Tooltip>
        )}
      </FileUpload>

      {cropModal && (
        <ImageCrop
          image={cropModal}
          onClose={() => setCropModal()}
          onConfirm={async (image) => {
            const { data } = await api.uploadImage([image]);
            setImage(URL.createObjectURL(image));
            onUploaded(data, image);
          }}
        />
      )}
    </>
  );
};

export default ImageUpload;
