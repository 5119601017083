import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { FlexMiddle } from '../Flex';
import RadioButton from '../RadioButton';

const Wrapper = styled(FlexMiddle)`
  padding: 9px 0;
`;

const RadioField = ({ name, options = [] }) => (
  <Wrapper>
    {options.map((option) => (
      <Field
        key={option?.value || option}
        name={name}
        render={({ input }) => (
          <RadioButton
            content={option?.label || option}
            id={option?.value || option}
            {...input}
            disabled={option.disabled}
          />
        )}
      />
    ))}
  </Wrapper>
);

export default RadioField;
