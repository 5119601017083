import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import moment from 'moment';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import { API_DATE_TIME_FORMAT } from '@ubeya/shared/constants';
import { FlexMiddle } from '../Flex';
import { Small } from '../Typography';

const COLORS = [3, 3, 2, 2, 2, 1, 1, 1, 0, 0, 0];

const Bar = styled(FlexMiddle)`
  justify-content: flex-end;
  height: 32px;
  background: ${({ theme, percent }) => theme.colors.reportPalette[COLORS[Math.floor(percent / 10)]]};
  border-radius: 8px;
`;

const Label = styled(Small)`
  background: ${({ theme, percent }) => lighten(0.2, theme.colors.reportPalette[COLORS[Math.floor(percent / 10)]])};
  border-radius: 8px;
  padding: 4px 10px;
  margin: 0 2px;
`;

const DurationRenderer = ({ rowData, dataKey, columnData: { minDate } }) => {
  const { t } = useTranslation();
  const value = get(rowData, dataKey);

  if (!value) {
    return <></>;
  }

  const maxDays = moment().diff(minDate, 'days') || 1;
  const currentDate = moment(value, API_DATE_TIME_FORMAT);

  const duration = moment.duration(moment().diff(currentDate, 'milliseconds'));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const width = (duration.asDays() * 100) / maxDays;

  return (
    <Bar style={{ minWidth: `${width}%` }} percent={width}>
      <Label percent={width}>{t('durationText', { years, months, days })}</Label>
    </Bar>
  );
};

export default DurationRenderer;
