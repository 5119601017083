import React from 'react';
import styled from 'styled-components';
import { FlexColumn } from '../Flex';

const Wrapper = styled(FlexColumn)`
  position: relative;
  width: 100%;
`;

const BaseInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  padding: 7px 20px;
  color: ${({ theme }) => theme.colors.gray400};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray800};
  }
`;

const Input = ({ placeholder, value, onChange, inputRef, type, ...restProps }) => (
  <Wrapper>
    <BaseInput
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      ref={inputRef}
      type={type}
      {...restProps}
    />
  </Wrapper>
);

export default Input;
