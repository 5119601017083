import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { ExtraSmallSemiBold } from '../Typography';

const Wrapper = styled(ExtraSmallSemiBold)`
  background: linear-gradient(0.15turn, #ea941a, #fa5c51);
  color: #fff;
  padding: 5px 10px;
  border-radius: 18px;
  cursor: default;
`;

const PremiumChip = ({ className }) => (
  <Wrapper className={className}>
    <Trans>premium</Trans>
  </Wrapper>
);

export default PremiumChip;
