import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import star from '../../assets/star.svg';
import starFill from '../../assets/star-fill.svg';
import { Small } from '../Typography';
import { FlexMiddle } from '../Flex';

const Container = styled(FlexMiddle)`
  flex-direction: row-reverse;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-end')};
`;

const StarIcon = styled.div`
  width: 18px;
  height: 18px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  background: url(${({ selected }) => (selected ? starFill : star)});
  background-repeat: no-repeat;
  margin-right: 2px;

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover ~ &,
      &:hover {
        background: url(${starFill}) !important;
        background-repeat: no-repeat !important;
      }
    `}
`;

const Text = styled(Small)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.gray400};
`;

const RatingBar = ({ className, rating, reviews, onChange, stars = 5, center = true }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {reviews && <Text>{t('ratingBaseOnReviews', { rating, reviews })}</Text>}

      <Container $center={center}>
        {[...Array(stars)].map((_, index) => (
          <StarIcon
            key={index}
            selected={rating >= 5 - index}
            onClick={onChange ? () => onChange(5 - index) : undefined}
          />
        ))}
      </Container>
    </div>
  );
};

export default RatingBar;
