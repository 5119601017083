import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import useSafeState from '@ubeya/shared/hooks/useSafeState';
import { FlexCenter, FlexColumnCenter } from '../Flex';
import { Loader } from '../Loader';
import { Tooltip } from '../Tooltip';
import withPrivileges from '../../hoc/withPrivileges';

const hoverStyle = css`
  background: ${({ theme, $backgroundColor, $hideBackground }) =>
    $hideBackground ? 'none' : theme.colors[$backgroundColor] || theme.colors.gray910};
`;

const Wrapper = styled(FlexColumnCenter)`
  padding: 9px;
  border-radius: 50%;
  cursor: ${({ isDisabled }) => (isDisabled ? 'no-drop' : 'pointer')};
  width: 36px;
  height: 36px;
  transition: background 200ms;

  &:hover {
    ${hoverStyle}
  }

  ${({ isActive }) => isActive && hoverStyle}
`;

const LoaderWrapper = styled(FlexCenter)``;

const CircleIcon = ({
  className,
  icon: Icon,
  children,
  onClick,
  backgroundColor,
  as,
  to,
  href,
  target,
  isActive,
  tooltip,
  placement,
  isDisabled,
  hideBackground,
  isWaitingForResponse = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const onPress = useCallback(
    async (e) => {
      if (isWaitingForResponse) {
        setIsLoading(true);
        await onClick?.(e);
        setIsLoading(false);
      } else {
        onClick?.(e);
      }
    },
    [isWaitingForResponse, onClick, setIsLoading]
  );

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <Loader size="small" />
        </LoaderWrapper>
      )}
      {!isLoading && (
        <Tooltip body={tooltip} disabled={!tooltip} placement={placement}>
          <Wrapper
            className={`circle-icon ${className}`}
            onClick={isDisabled ? undefined : onPress}
            $backgroundColor={backgroundColor}
            isActive={isActive}
            isDisabled={isDisabled}
            as={as}
            to={to}
            href={href}
            $hideBackground={hideBackground}
            target={target}>
            {children ?? <Icon {...props} />}
          </Wrapper>
        </Tooltip>
      )}
    </>
  );
};

export default withPrivileges(CircleIcon);
