import React from 'react';
import styled from 'styled-components';
import useTags from '@ubeya/shared/hooks/account/useTags';
import { FlexMiddle } from '../../Flex';

const Color = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: ${({ color }) => color};
  margin-right: 2px;
`;

const TagsChip = ({ value }) => {
  const { mappedTags } = useTags();

  return (
    <FlexMiddle>
      {value.map((item) => (
        <Color color={mappedTags[item].color} />
      ))}
    </FlexMiddle>
  );
};

export default TagsChip;
