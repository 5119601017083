import React, { useMemo } from 'react';
import CheckBox from '../../components/CheckBox';

const useCheckboxColumn = ({ columns, checkboxData }) => {
  const newColumns = useMemo(() => {
    return checkboxData
      ? [
          {
            Header: () => (
              <CheckBox
                name={`header-checkbox-${Math.random()}`}
                value={checkboxData.isAllChecked}
                onChange={checkboxData.toggleAll}
                indeterminate={!!(checkboxData.value.include || checkboxData.value.exclude)}
              />
            ),
            dataKey: 'checkbox',
            maxWidth: 1,
            sortable: false,
            captureRowClick: false,
            Cell: ({ rowData }) => (
              <CheckBox
                name={Math.random()}
                value={checkboxData.isAllChecked ? !checkboxData.isChecked(rowData) : checkboxData.isChecked(rowData)}
                onChange={(checked) => checkboxData.toggleCheck(rowData, checked)}
              />
            )
          },
          ...columns
        ]
      : columns;
  }, [columns, checkboxData]);

  return newColumns;
};

export default useCheckboxColumn;
