import React from 'react';
import styled from 'styled-components';
import { media } from '@ubeya/shared-web/style';
import { Container, FlexColumn, flexColumn, Header1SemiBold, Loader as BaseLoader } from '@ubeya/shared-web/components';
import { AlreadyUser } from 'shared/components';

const Wrapper = styled(Container).attrs(() => ({ size: 'xsmall' }))`
  ${flexColumn};
  height: 100vh;
  align-items: center;
  padding-top: 23px;
`;

const Content = styled(FlexColumn)`
  max-width: ${({ width }) => width}px;
  position: relative;
  > * {
    ${({ loading }) => loading && 'opacity: 0.5;'};
  }
`;

const Title = styled(Header1SemiBold)`
  font-size: 32rem;
  margin: 25px auto 40px auto;
  ${media.tablet`
    margin-top:100px;
  `}
  ${({ left }) => left && `margin-left:0;`}
  color: ${({ theme }) => theme.colors.gray000};
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
`;

const Layout = ({ title, children, left, width = 330, loading }) => (
  <Wrapper>
    <AlreadyUser />

    <Content left={left} width={width} loading={loading}>
      {loading && <Loader />}

      <Title left={left}>{title}</Title>
      {children}
    </Content>
  </Wrapper>
);

export default Layout;
