import styled, { css } from 'styled-components';
import media from '../../../style/media';

export const cell = css`
  flex: ${({ flexGrow, width }) => (width ? 0 : flexGrow)} 0
    ${({ minWidth, width }) => (width || minWidth ? `${width || minWidth}px` : '0%')};
  min-width: 0px;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: ${({ withTooltip }) => (withTooltip ? 'visible' : 'hidden')};

  padding-left: 10px;
  padding-right: 10px;
  ${media.mobile`
    padding-left: 20px;
    padding-right: 20px;
  `}

  display: none;
  ${({ mustStay = true }) => mustStay && 'display: block'};

  ${media.tablet`display: block;`}

  ${({ hasCheckboxColumn }) =>
    hasCheckboxColumn &&
    css`
      &:first-of-type {
        padding-right: 16px;
      }
    `}
`;

const Cell = styled.div`
  ${cell}
`;

export default Cell;
