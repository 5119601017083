import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { Flex, FlexColumn } from '../Flex';
import { Header2 } from '../Typography';
import BaseCheckBox from '../CheckBox';

const Column = styled(FlexColumn)`
  margin-right: 55px;
`;

const Category = styled(Header2)`
  margin-bottom: 44px;
`;

const CheckBox = styled(BaseCheckBox)`
  margin-bottom: 31px;
`;

const ColumnsMenu = ({
  columnsMenuTitle,
  allColumns,
  visibleColumns,
  onClose,
  updateVisibleColumns,
  resetToDefault,
  showReset = true,
  size,
  confirmText
}) => {
  const { t } = useTranslation();
  const [selectedColumns, setSelectedColumns] = useState(() => visibleColumns.map(({ dataKey }) => dataKey));
  const categories = groupBy(
    allColumns.filter((column) => column?.category),
    'category'
  );

  const handleToggleColumn = useCallback(
    (selectedId, checked) => {
      if (!checked) {
        setSelectedColumns(selectedColumns.filter((id) => id !== selectedId));
      } else {
        setSelectedColumns([...selectedColumns, selectedId]);
      }
    },
    [selectedColumns]
  );

  return (
    <Modal
      size={size}
      onClose={onClose}
      title={columnsMenuTitle}
      confirmText={confirmText}
      onConfirm={() => updateVisibleColumns(selectedColumns)}
      showCancelButton={showReset}
      cancelText={t('resetToDefault')}
      onCancel={() => {
        resetToDefault();
        onClose();
      }}
      usePortal>
      <Flex>
        {Object.entries(categories).map(([category, columnsList]) => (
          <Column key={category}>
            {category !== ' ' && <Category>{category}</Category>}

            {columnsList.map(({ Header, dataKey, columnListLabel }) => (
              <CheckBox
                key={dataKey}
                name={dataKey}
                label={t(columnListLabel || Header)}
                value={selectedColumns.includes(dataKey)}
                onChange={(checked) => handleToggleColumn(dataKey, checked)}
              />
            ))}
          </Column>
        ))}
      </Flex>
    </Modal>
  );
};

export default ColumnsMenu;
