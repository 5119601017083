import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { flexColumn } from '../Flex';
import { Loader, LoaderArea } from '../Loader';
import { responsiveFields } from '../../style/responsive';
import SettingsPage from './SettingsPage';
import ActionsBar from './ActionsBar';
import LogoUpload from './LogoUpload';

const Content = styled.div`
  ${responsiveFields.mobile};
`;

const Container = styled.form`
  ${flexColumn};
  flex: 1;
`;

const Info = ({ title, isLoading, initialValues, onSubmit, showLogo = true, children }) => {
  if (isLoading) {
    return (
      <SettingsPage title={title}>
        <LoaderArea>
          <Loader />
        </LoaderArea>
      </SettingsPage>
    );
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, submitting, form }) => (
        <Container onSubmit={handleSubmit}>
          <SettingsPage title={title} scrollable>
            <Content>
              {showLogo && (
                <div className="full">
                  <LogoUpload />
                </div>
              )}

              {children}
            </Content>
          </SettingsPage>

          <ActionsBar isChanged={!pristine} submitting={submitting} form={form} />
        </Container>
      )}
    />
  );
};

export default Info;
