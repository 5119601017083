import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { body } from '../Typography';
import Label from '../Label';
import scrollbar from '../../style/scrollbar';

const Input = styled(TextareaAutosize)`
  ${body};
  ${scrollbar};
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  outline: 0;
  padding: 10px;
  background: ${({ theme }) => theme.colors.surface};
  min-height: 44px;
  resize: none;

  ${({ theme, error }) => error && `border-color: ${theme.colors.failure}; background:${theme.colors.failureLight};`}

  &:disabled {
    color: ${({ theme }) => theme.colors.gray800};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
  }

  &:read-only {
    background: transparent;
  }
`;

const TextArea = ({ title, name, value, onChange, error, className, required, innerRef, ...inputProps }) => (
  <Label title={title} required={required} className={className} error={error}>
    <Input
      id={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      ref={innerRef}
      error={error}
      {...inputProps}
    />
  </Label>
);
export default TextArea;
