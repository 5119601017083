import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import BaseReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import BaseModal from '../Modal';

const Modal = styled(BaseModal)`
  width: auto;
  max-height: 70vh;
`;

const ReactCrop = styled(BaseReactCrop)`
  img {
    max-height: 53vh;
  }
`;

const ImageCrop = ({ image, onClose, onConfirm }) => {
  const imageRef = useRef();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [crop, setCrop] = useState(() => ({ unit: '%', aspect: 1 / 1 }));

  const onImageLoaded = useCallback(
    (image) => {
      imageRef.current = image;
      const smaller = Math.min(image.width, image.height);

      const crop = { width: smaller, height: smaller, x: 0, y: 0, aspect: 1 / 1 };
      setCrop(crop);
      getCroppedImg(image, crop);
      return false;
    },
    [getCroppedImg]
  );

  const dataURLtoFile = useCallback((dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppedImage = new File([u8arr], filename, { type: mime });
    setCroppedImageUrl(croppedImage);
  }, []);

  const getCroppedImg = useCallback(
    (image, crop) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const reader = new FileReader();
      canvas.toBlob((blob) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          dataURLtoFile(reader.result, 'cropped.jpg');
        };
      });
    },
    [dataURLtoFile]
  );

  const onCropComplete = useCallback(
    (crop) => {
      if (imageRef.current && crop.width && crop.height) {
        getCroppedImg(imageRef.current, crop);
      }
    },
    [getCroppedImg]
  );

  return (
    <Modal
      onClose={onClose}
      onConfirm={async () => {
        await onConfirm(croppedImageUrl);
        onClose();
      }}
      title="cropImage"
      confirmText="crop"
      isPaddingContent={false}
      showCancelButton
      size="medium"
      usePortal>
      <ReactCrop src={image} crop={crop} onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={setCrop} />
    </Modal>
  );
};

export default ImageCrop;
