import styled, { css } from 'styled-components';
import theme from './theme';

const Root = styled.div.attrs(() => ({ className: 'data-grid' }))`
  ${theme}
  --selection-color: #66afe9;
  --font-size: 12px;
  contain: strict;
  contain: size layout style paint;
  content-visibility: auto;
  height: 350px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  overflow: auto;
  overflow: overlay;
  ${'' /* user-select: none; */}
  background-color: var(--background-color);
  color: var(--color);
  font-size: var(--font-size);
  flex: 1;
  position: relative;

  z-index: 0;

  ${({ $isLoading }) => $isLoading && 'opacity: 0.4'};

  ${({ $scrolledLeft }) =>
    $scrolledLeft &&
    css`
      .cell.last-frozen {
        box-shadow: 2px 0 5px -2px rgba(136, 136, 136, 0.3);
        border-right: 1px solid var(--border-color) !important;
      }
    `}
`;

export default Root;
