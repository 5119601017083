import React, { useState, useRef, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { ButtonHeader } from '../Header';
import { body } from '../Typography';
import { FlexMiddle } from '../Flex';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ReactComponent as BaseArrow } from '../../assets/arrow.svg';
import ConfigContext from '../../contexts/ConfigContext';
import { responsiveShow, media } from '../../style';
import BaseDatePickerWithOptions from './DatePickerWithOptions';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'date-range' }))`
  white-space: nowrap;
  ${body};
`;

const Button = styled(ButtonHeader)`
  margin-right: 5px;
  ${media.mobile`margin-right: 10px;`}
`;

const DatesButton = styled(Button)`
  min-width: 155px;
  text-align: center;
  ${({ $isString }) => $isString && 'justify-content: space-between'};
`;

const TodayButton = styled(Button)`
  ${responsiveShow.tablet};
  text-align: center;
  min-width: 85px;
  ${({ theme, isActive }) => isActive && `border-color: ${theme.colors.primary}`};
  ${({ theme, isActive }) => isActive && `color: ${theme.colors.primary}`};
  margin-right: 30px;
`;

const DatePickerWithOptions = styled(BaseDatePickerWithOptions)`
  position: absolute;
  top: 42px;
  ${({ openFromRight }) => (openFromRight ? 'right' : 'left')}: 0;
  z-index: 9;
`;

const Arrow = styled(BaseArrow).attrs(({ $left }) => ({ className: $left ? 'rotate-180' : 'rotate-0' }))``;

const MenuArrow = styled(BaseArrow)`
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 90)}deg);
  margin-left: 10px;
`;

const DateRange = ({
  value,
  onChange,
  hideToday,
  valueAsString,
  openFromRight = false,
  allOption = false,
  options,
  className
}) => {
  const { shortDateFormat } = useContext(ConfigContext);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const isSingleDay = value.key === 'today';
  const isToday = value.key === 'today' && moment(value?.start).startOf('day')?.isSame(moment().startOf('day'));

  const handleDayPrev = useCallback(
    () =>
      onChange({
        ...value,
        start: moment(value?.start).subtract(1, 'day'),
        end: moment(value?.end).subtract(1, 'day')
      }),
    [onChange, value]
  );

  const handleDayNext = useCallback(
    () =>
      onChange({
        ...value,
        start: moment(value?.start).add(1, 'day'),
        end: moment(value?.end).add(1, 'day')
      }),
    [onChange, value]
  );

  const handleToday = useCallback(
    () =>
      onChange({
        key: 'today',
        start: moment(),
        end: moment()
      }),
    [onChange]
  );

  const showValueAsString = valueAsString && value.key !== 'custom';

  return (
    <Wrapper ref={ref} className={className}>
      {!hideToday && (
        <TodayButton isActive={isToday} onClick={handleToday}>
          <Trans>today</Trans>
        </TodayButton>
      )}

      {!hideToday && isSingleDay && (
        <Button onClick={handleDayPrev}>
          <Arrow $left />
        </Button>
      )}

      <DatesButton onClick={() => setIsOpen(true)} $isString={showValueAsString}>
        {showValueAsString ? (
          <Trans>{value.key}</Trans>
        ) : (
          <>
            <span>{moment(value?.start).format(shortDateFormat)}</span>
            <span className="day">, {moment(value?.start).format('ddd')}</span>

            {!isSingleDay && (
              <>
                <span> - </span>
                <span>{moment(value?.end).format(shortDateFormat)}</span>
                <span className="day">, {moment(value?.end).format('ddd')}</span>
              </>
            )}
          </>
        )}

        <MenuArrow $isOpen={isOpen} />

        {isOpen && (
          <DatePickerWithOptions
            value={value}
            onChange={onChange}
            onClose={() => setIsOpen(false)}
            openFromRight={openFromRight}
            options={options}
            allOption={allOption}
          />
        )}
      </DatesButton>

      {!hideToday && isSingleDay && (
        <Button onClick={handleDayNext}>
          <Arrow />
        </Button>
      )}
    </Wrapper>
  );
};

export default DateRange;
