import React from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal';

export class RouteLeavingGuard extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false
  };

  showModal = (location) => this.setState({ modalVisible: true, lastLocation: location });

  closeModal = (callback) => this.setState({ modalVisible: false }, callback);

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    if (!confirmedNavigation) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const {
        history: { push: navigate }
      } = this.props;

      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState({ confirmedNavigation: true }, () => navigate(lastLocation.pathname));
      }
    });

  render() {
    const { when, title = 'changesNotSaved', body = 'areYouSureLeave' } = this.props;
    const { modalVisible } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        {modalVisible && (
          <ConfirmationModal
            onClose={this.closeModal}
            onConfirm={this.handleConfirmNavigationClick}
            title={title}
            body={body}
            confirmText="leaveWithoutSaving"
            usePortal
          />
        )}
      </>
    );
  }
}
export default withRouter(RouteLeavingGuard);
