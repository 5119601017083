import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { ReactComponent as BaseVSign } from '../../assets/v.svg';
import { ReactComponent as BaseErrorSign } from '../../assets/error.svg';
import { ReactComponent as BaseXSign } from '../../assets/x.svg';
import { Tooltip } from '../Tooltip';

const VSign = styled(BaseVSign)`
  > path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;
const ErrorSign = styled(BaseErrorSign)`
  > path {
    fill: ${({ theme }) => theme.colors.error};
  }
`;

const XSign = styled(BaseXSign)`
  height: 15px;
  width: 15px;
`;

const CheckedRenderer = ({
  rowData,
  dataKey,
  columnData: { value: defaultValue, tooltip } = {},
  showFalse = false,
  showTooltipForFalse = false
}) => {
  const value = get(rowData, dataKey);

  return defaultValue || value ? (
    <Tooltip body={tooltip} disabled={!tooltip}>
      <VSign />
    </Tooltip>
  ) : (
    <>
      {showFalse &&
        (showTooltipForFalse ? (
          <Tooltip body={tooltip} disabled={!tooltip}>
            <XSign />
          </Tooltip>
        ) : (
          <ErrorSign />
        ))}
    </>
  );
};

export default CheckedRenderer;
