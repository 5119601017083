import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FlexMiddle } from '../../Flex';
import { bodySemiBold } from '../../Typography';
import { ReactComponent as SortIcon } from '../../../assets/sort.svg';
import { TextWithTooltip } from '../../Tooltip';
import Cell from './Cell';

const SortButtons = styled(FlexMiddle)`
  ${({ isActive }) => !isActive && 'display: none;'}
  margin-left: 7px;
`;

const sortableHeader = css`
  cursor: pointer;
  &:hover {
    ${SortButtons} {
      display: flex;
    }
  }
`;

const Wrapper = styled(Cell).attrs(() => ({ className: 'header-cell' }))`
  ${bodySemiBold};
  padding-top: 19px;
  padding-bottom: 19px;
  :not(:first-of-type) {
    border-left: 1px solid ${({ theme }) => theme.colors.gray900};
  }

  &&& {
    ${({ emptyHeader }) => emptyHeader && 'border-left: none;'}
  }

  ${({ hasCheckboxColumn }) =>
    hasCheckboxColumn &&
    css`
      &:nth-of-type(2) {
        border-left: none;
      }
      &:first-of-type > div > div {
        overflow: visible;
      }
    `}

  ${({ isSortable }) => isSortable && sortableHeader};
`;

const SortArrow = styled(SortIcon)`
  margin-left: 1px;
  cursor: pointer;
  transform: rotate(${({ direction }) => (direction === 'desc' ? 0 : 180)}deg);
  path {
    ${({ theme, $isActive }) => $isActive && `fill: ${theme.colors.gray200};`}
  }
`;

const HeaderCell = ({
  sortable,
  sortBy,
  sortDirection,
  setSort,
  hasCheckboxColumn,
  column: { Header, sortable: isSortable = sortable, dataKey, minWidth, width, maxWidth, flexGrow = 1, mustStay }
}) => {
  const handleSort = useCallback(
    () =>
      isSortable
        ? setSort?.({
            sortBy: dataKey,
            sortDirection: sortDirection === 'desc' ? 'asc' : 'desc'
          })
        : null,
    [dataKey, isSortable, setSort, sortDirection]
  );

  return (
    <Wrapper
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      flexGrow={flexGrow}
      isSortable={isSortable}
      mustStay={mustStay}
      onClick={handleSort}
      hasCheckboxColumn={hasCheckboxColumn}
      emptyHeader={!Header}>
      <FlexMiddle>
        <TextWithTooltip>{typeof Header === 'string' ? Header : <Header />}</TextWithTooltip>

        {isSortable && (
          <SortButtons isActive={dataKey === sortBy}>
            <SortArrow direction="asc" $isActive={dataKey === sortBy && sortDirection === 'asc'} />
            <SortArrow direction="desc" $isActive={dataKey === sortBy && sortDirection === 'desc'} />
          </SortButtons>
        )}
      </FlexMiddle>
    </Wrapper>
  );
};

export default HeaderCell;
