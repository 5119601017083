import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useMenu from '../../../hooks/useMenu';
import usePressKey from '../../../hooks/usePressKey';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow.svg';
import { FlexMiddle } from '../../Flex';
import Label from '../../Label';
import Options from './Options';
import Value from './Value';
import MultiValue from './MultiValue';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'select' }))`
  position: relative;
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  background: ${({ theme }) => theme.colors.surface};
  outline: 0;

  ${({ theme, error }) => error && `border-color: ${theme.colors.failure}; background:${theme.colors.failureLight};`}

  ${({ theme, isOpen }) =>
    isOpen && `box-shadow: ${theme.colors.inputShadow};  border: 1px solid ${theme.colors.primary};`}
  &:hover, &:focus {
    box-shadow: ${({ theme }) => theme.colors.inputShadow};
  }
`;

const Arrow = styled(ArrowIcon).attrs(() => ({ className: 'select-arrow' }))`
  pointer-events: none;
  position: absolute;
  right: 20px;
  transform: rotate(${({ $isOpen }) => ($isOpen ? 270 : 90)}deg);
  transition: 200ms;
  > path {
    ${({ $isDisabled, theme }) => $isDisabled && `fill: ${theme.colors.gray500};`}
  }
`;

const MENU_OFFSET = { top: 7 };

const BaseSelect = ({
  title,
  required,
  className,
  menuClassName,
  value,
  onChange,
  options,
  formatValue,
  formatOption,
  filterFunc,
  defaultValue,
  placeholder,
  isMulti = false,
  usePortal = false,
  isDisabled = false,
  error,
  isRaw = false,
  onFocus,
  creatable = false,
  searchable = true,
  clearable = false,
  valuesComparator = (a, b) => a === b,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const { Portal, wrapperRef, isOpen, setOpen, setClose, setToggle, style, menuRef } = useMenu({
    usePortal,
    menuOffset: MENU_OFFSET
  });

  const handleSelect = useCallback(
    (newValue) => {
      if (isMulti) {
        onChange([...value, newValue]);
      } else {
        onChange(newValue);
      }
      setSearch('');
      setClose();
    },
    [isMulti, onChange, setClose, value]
  );

  const handleRemoveChip = useCallback((selectedValue) => onChange(value.filter((item) => item !== selectedValue)), [
    onChange,
    value
  ]);

  const formattedOptions = useMemo(() => {
    if (isMulti) {
      return options.filter((option) => !value.includes(option.value));
    }
    return options;
  }, [isMulti, options, value]);

  // UP
  usePressKey({ which: 38, ref: wrapperRef, onSelect: () => !isOpen && setOpen() });

  // DOWN
  usePressKey({ which: 40, ref: wrapperRef, onSelect: () => !isOpen && setOpen() });

  // BACKSPACE
  usePressKey({
    which: 8,
    ref: wrapperRef,
    onSelect: () => isMulti && !search && value.length > 0 && handleRemoveChip(value[value.length - 1]),
    preventDefault: false
  });

  useEffect(() => {
    if (search) {
      setOpen();
    }
  }, [search, setOpen]);

  return (
    <Label title={title} required={required} error={error} className={className}>
      <Wrapper ref={wrapperRef} error={error} isOpen={isOpen}>
        {isMulti ? (
          <MultiValue
            value={value}
            options={options}
            onClick={isDisabled ? undefined : setToggle}
            placeholder={placeholder ?? t('searchPlaceholder')}
            search={search}
            setSearch={setSearch}
            isDisabled={isDisabled}
            onRemoveChip={handleRemoveChip}
            isOpen={isOpen}
            {...restProps}
          />
        ) : (
          <Value
            isRaw={isRaw}
            value={value}
            options={options}
            formatValue={formatValue}
            onClick={isDisabled ? undefined : setToggle}
            placeholder={placeholder ?? t('searchPlaceholder')}
            search={search}
            setSearch={setSearch}
            isDisabled={isDisabled}
            searchable={searchable}
            valuesComparator={valuesComparator}
            isOpen={isOpen}
            clearable={clearable}
            onClear={() => handleSelect()}
            handleSelect={handleSelect}
            {...restProps}
          />
        )}

        <Arrow $isOpen={isOpen} $isDisabled={isDisabled} />

        {isOpen && (
          <Portal>
            <Options
              isMulti={isMulti}
              value={value}
              formatOption={formatOption}
              defaultValue={defaultValue}
              handleSelect={handleSelect}
              search={search}
              filterFunc={filterFunc}
              options={formattedOptions}
              creatable={creatable}
              wrapperRef={wrapperRef}
              menuRef={menuRef}
              style={style}
              className={menuClassName}
            />
          </Portal>
        )}
      </Wrapper>
    </Label>
  );
};

export default BaseSelect;
