import styled, { css } from 'styled-components';

export const bigHeader = css`
  font-weight: normal;
  font-size: 27rem;
  line-height: 32rem;
`;

export const BigHeader = styled.h1`
  ${bigHeader}
`;

export const bigHeaderSemiBold = css`
  font-weight: 500;
  font-size: 27rem;
  line-height: 32rem;
`;

export const BigHeaderSemiBold = styled.h1`
  ${bigHeader}
`;

export const header1 = css`
  font-weight: normal;
  font-size: 21rem;
  line-height: 29rem;
`;

export const Header1 = styled.h1`
  ${header1}
`;

export const header1SemiBold = css`
  font-weight: 500;
  font-size: 21rem;
  line-height: 29rem;
`;

export const Header1SemiBold = styled.h1`
  ${header1SemiBold}
`;

export const header2Bold = css`
  font-weight: bold;
  font-size: 18rem;
  line-height: 26rem;
`;

export const Header2Bold = styled.h2`
  ${header2Bold}
`;

export const header2SemiBold = css`
  font-weight: 500;
  font-size: 18rem;
  line-height: 26rem;
`;

export const Header2SemiBold = styled.h2`
  ${header2SemiBold}
`;

export const header2 = css`
  font-weight: normal;
  font-size: 18rem;
  line-height: 26rem;
`;

export const Header2 = styled.h2`
  ${header2}
`;

export const header3SemiBold = css`
  font-weight: 500;
  font-size: 16rem;
  line-height: 30rem;
`;

export const Header3SemiBold = styled.h3`
  ${header3SemiBold}
`;

export const header3 = css`
  font-weight: normal;
  font-size: 16rem;
  line-height: 30rem;
`;

export const Header3 = styled.h3`
  ${header3}
`;

export const bodySemiBold = css`
  font-weight: 500;
  font-size: 14rem;
  letter-spacing: 0.2rem;
  line-height: 22rem;
`;

export const BodySemiBold = styled.div`
  ${bodySemiBold}
`;
export const body = css`
  font-weight: normal;
  font-size: 14rem;
  line-height: 22rem;
`;

export const Body = styled.div`
  ${body}
`;

export const smallBodySemiBold = css`
  font-weight: 500;
  font-size: 12rem;
  line-height: 20rem;
`;

export const SmallBodySemiBold = styled.div`
  ${smallBodySemiBold}
`;

export const smallBodyBold = css`
  font-weight: bold;
  font-size: 12rem;
  line-height: 20rem;
`;

export const SmallBodyBold = styled.div`
  ${smallBodyBold}
`;

export const smallBody = css`
  font-weight: normal;
  font-size: 12rem;
  line-height: 20rem;
`;

export const SmallBody = styled.div`
  ${smallBody}
`;

export const smallSemiBold = css`
  font-weight: 500;
  font-size: 12rem;
  line-height: 20rem;
`;

export const SmallSemiBold = styled.div`
  ${smallSemiBold}
`;

export const small = css`
  font-weight: normal;
  font-size: 12rem;
  line-height: 20rem;
`;

export const Small = styled.div`
  ${small}
`;

export const semiExtraSmall = css`
  font-weight: normal;
  font-size: 11rem;
  line-height: 20rem;
`;

export const SemiExtraSmall = styled.div`
  ${semiExtraSmall}
`;

export const extraSmallSemiBold = css`
  font-weight: 500;
  font-size: 10rem;
  line-height: 18rem;
`;

export const ExtraSmallSemiBold = styled.div`
  ${extraSmallSemiBold}
`;

export const extraSmall = css`
  font-weight: normal;
  font-size: 10rem;
  line-height: 18rem;
`;

export const ExtraSmall = styled.div`
  ${extraSmall}
`;

export const ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Ellipsis = styled.div`
  ${ellipsis}
`;
