import React, { useCallback, useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { API_DATE_TIME_FORMAT } from '@ubeya/shared/constants';
import ConfigContext from '../../contexts/ConfigContext';
import { TextInput } from '../Input';
import AutoCompleteOff from '../AutoCompleteOff';
import useMenu from '../../hooks/useMenu';
import BaseDatePicker from './DatePicker';

const Wrapper = styled.div`
  position: relative;
`;

const DatePicker = styled(BaseDatePicker)`
  z-index: 5;
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}
`;

const DatePickerInput = ({
  name,
  title,
  required,
  value,
  onChange,
  usePortal,
  isDisabled,
  openFromRight,
  error,
  className
}) => {
  const { dateFormat } = useContext(ConfigContext);

  const { Portal, wrapperRef, isOpen, setOpen, setClose, style, menuRef } = useMenu({ usePortal, openFromRight });

  const handleChange = useCallback(
    (date) => {
      onChange(date);
      setClose();
    },
    [onChange, setClose]
  );

  const handleInputChange = useCallback(
    (date) => {
      handleChange(moment(date, dateFormat).toISOString());
    },
    [dateFormat, handleChange]
  );

  const validValue = useMemo(() => (value && moment(value, API_DATE_TIME_FORMAT).isValid() ? value : undefined), [
    value
  ]);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <AutoCompleteOff />
      <TextInput
        name={name}
        title={title}
        required={required}
        value={validValue ? moment(validValue).format(dateFormat) : ''}
        onChange={handleInputChange}
        onFocus={setOpen}
        disabled={isDisabled}
        mask="00/00/0000"
        error={error}
      />

      {isOpen && (
        <Portal>
          <DatePicker value={validValue} onChange={handleChange} menuRef={menuRef} innerStyle={style} />
        </Portal>
      )}
    </Wrapper>
  );
};

export default DatePickerInput;
