import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Router } from 'react-router-dom';
import GlobalStyle from '@ubeya/shared-web/style/global';
import themeMaker from '@ubeya/shared-web/style/themeMaker';
import App from 'app';

const history = createBrowserHistory();

const REACT_QUERY_CONFIG = { defaultOptions: { queries: { staleTime: Infinity, refetchOnWindowFocus: false } } };

const theme = themeMaker();

const queryClient = new QueryClient(REACT_QUERY_CONFIG);

const CustomGlobalStyle = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.colors.gray000};
    background:${({ theme }) => theme.colors.surface}!important;
  }
`;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <GlobalStyle />
      <CustomGlobalStyle />
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
