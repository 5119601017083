import moment from 'moment';
import { get, post, put, httpDelete, patch, delay } from '../utils/http';
import dashboardFinancial from './mock/dashboardFinancial.json';

export const fetchUser = () => get('/auth/me');

export const refreshAdminToken = () => post(`${process.env.API_AUTH_URL}/admin/refresh`);

export const refreshUserToken = () => post(`${process.env.API_AUTH_URL}/user/refresh`);

export const fetchFields = ({ accountId }) => get(`/accounts/${accountId}/fields`);

export const fetchPositions = ({ accountId }) => get(`/accounts/${accountId}/positions`);

export const fetchBranches = ({ accountId }) => get(`/accounts/${accountId}/branches`);

export const fetchFileTypes = () => get('/documents/types');

export const uploadImage = (files) => {
  const formData = new FormData();
  formData.append('file', files[0]);
  return post(`/upload`, formData, { 'Content-Type': 'multipart/form-data' });
};

export const fetchAccount = ({ accountId }) =>
  get(`/accounts/${accountId}`, { includes: ['withCurrency', 'withLanguage', 'withCountry'] });

export const addAccount = (params) => post('/accounts', params);

export const fetchEmployees = ({ accountId, ...params }) => get(`/accounts/${accountId}/employees`, params);

export const fetchCandidates = ({ accountId, ...params }) => get(`/accounts/${accountId}/candidates`, params);

export const patchCandidate = ({ accountId, requestId, isApproved }) =>
  patch(`/accounts/${accountId}/candidates/${requestId}`, { isApproved });

export const fetchEmployeesStats = ({ accountId, filters, searchTerm, branches }) =>
  get(`/accounts/${accountId}/employees/stats`, { filters, searchTerm, branches });

export const fetchEmployee = ({ accountId, employeeId }) => get(`/accounts/${accountId}/employees/${employeeId}`);
export const fetchOSEmployee = ({ accountId, employeeId }) => get(`/accounts/${accountId}/employees/os/${employeeId}`);

export const addEmployee = ({ accountId, ...params }) => post(`/accounts/${accountId}/employees`, params);

export const updateEmployee = ({ accountId, employeeId, ...params }) =>
  patch(`/accounts/${accountId}/employees/${employeeId}`, params);

export const deleteEmployee = ({ accountId, employeeId }) =>
  httpDelete(`/accounts/${accountId}/employees/${employeeId}`);

export const inviteEmployee = ({ accountId, employeeId }) =>
  post(`/accounts/${accountId}/employees/${employeeId}/invite`);

export const updateBulkEmployees = ({ accountId, employees }) =>
  post(`/accounts/${accountId}/employees/batch`, { employees });

export const fetchTimeSheet = ({
  accountId,
  branchIds,
  from,
  to,
  employeeIds,
  shiftIds,
  filters,
  withFields,
  warnings = true
}) => {
  return get(`/accounts/${accountId}/jobs`, {
    branches: branchIds,
    from,
    to,
    employeeIds,
    shiftIds: shiftIds?.join('#') || undefined,
    filters,
    warnings,
    withFields
  });
};

export const updateTimeSheet = ({ accountId, timesheetId, ...params }) =>
  patch(`/accounts/${accountId}/timesheets/${timesheetId}`, params);

export const createJob = ({ accountId, ...params }) => post(`/accounts/${accountId}/jobs`, params);

export const updateJob = ({ accountId, jobId, ...params }) => patch(`/accounts/${accountId}/jobs/${jobId}`, params);

export const deleteJob = ({ accountId, jobId }) => httpDelete(`/accounts/${accountId}/jobs/${jobId}`);

export const approveAll = ({ accountId, ids }) => put(`/accounts/${accountId}/timesheets/approve`, { ids });

export const uploadExpense = () => delay({ data: { path: 'test.doc' } });

export const addBranch = ({ accountId, branch }) => post(`/accounts/${accountId}/branches`, branch);

export const updateBranch = ({ accountId, branchId, branch }) =>
  patch(`/accounts/${accountId}/branches/${branchId}`, branch);

export const addField = ({ accountId, ...params }) => post(`/accounts/${accountId}/fields`, params);

export const updateField = ({ accountId, fieldId, ...params }) =>
  patch(`/accounts/${accountId}/fields/${fieldId}`, params);

export const deleteField = ({ accountId, fieldId }) => httpDelete(`/accounts/${accountId}/fields/${fieldId}`);

export const syncFields = ({ accountId, fields }) => put(`/accounts/${accountId}/fields`, fields);

export const sortFields = ({ accountId, fields }) => put(`/accounts/${accountId}/fields/sort`, { fields });

export const fetchAdmins = ({ accountId }) => get(`/accounts/${accountId}/admins`);

export const addAdmin = ({ accountId, admin }) => post(`/accounts/${accountId}/admins`, admin);

export const updateAdmin = ({ accountId, adminId, admin }) => patch(`/accounts/${accountId}/admins/${adminId}`, admin);

export const deleteAdmin = ({ accountId, adminId }) => httpDelete(`/accounts/${accountId}/admins/${adminId}`);

export const fetchBranchAdmins = ({ accountId, branchId }) => get(`/accounts/${accountId}/branches/${branchId}/admins`);

export const addBranchAdmin = ({ accountId, branchId, admin }) =>
  post(`/accounts/${accountId}/branches/${branchId}/admins`, admin);

export const updateBranchAdmin = ({ accountId, branchId, adminId, admin }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/admins/${adminId}`, admin);

export const deleteBranchAdmin = ({ accountId, branchId, adminId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/admins/${adminId}`);

export const fetchAccountConfig = ({ accountId, getNextPayment }) =>
  get(`/accounts/${accountId}`, { includes: ['withConfig', 'withPayrollConfig'], getNextPayment });

export const getBilling = ({ type, date }) => get(`/billing`, { period: { type, date } });

export const getBillingHistory = ({ type, date }) => get(`/billing/history`, { period: { type, date } });

export const createBilling = ({ body }) => post(`/billing`, body);

export const addBillingMethod = ({ accountId, body }) => post(`accounts/${accountId}/billing/methods`, body);

export const updateAccountConfig = ({ accountId, account }) => patch(`/accounts/${accountId}`, account);

export const fetchRegions = () => get('/regions');

export const fetchGenericFields = () => get('/fields');

export const fetchGenericPositions = () => get('/positions');

export const fetchModules = () => get('/modules');

export const fetchIndustries = () => get('/industries');

export const fetchJobTypes = () => get('/jobs/types');

export const fetchEmploymentTypes = () => get('/employment-types');

export const addPosition = ({ accountId, position }) => post(`/accounts/${accountId}/positions`, position);

export const updatePosition = ({ accountId, positionId, position }) =>
  patch(`/accounts/${accountId}/positions/${positionId}`, position);

export const updatePositions = ({ accountId, positions }) => put(`/accounts/${accountId}/positions`, positions);

export const fetchLocations = ({ accountId }) => get(`/accounts/${accountId}/locations`);

export const addLocation = ({ accountId, branchId, location: { location, ...rest } }) =>
  post(`/accounts/${accountId}/branches/${branchId}/locations`, { ...location, ...rest });

export const updateLocation = ({ accountId, branchId, locationId, location: { name } }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/locations/${locationId}`, { name });

export const deleteLocation = ({ accountId, branchId, locationId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/locations/${locationId}`);

export const fetchTags = ({ accountId }) => get(`/accounts/${accountId}/tags`);

export const addTag = ({ accountId, tag }) => post(`/accounts/${accountId}/tags`, tag);

export const updateTag = ({ accountId, tagId, tag }) => patch(`/accounts/${accountId}/tags/${tagId}`, tag);

export const deleteTag = ({ accountId, tagId }) => httpDelete(`/accounts/${accountId}/tags/${tagId}`);

export const fetchUniforms = ({ accountId }) => get(`/accounts/${accountId}/uniforms`);

export const addUniform = ({ accountId, branchId, uniform }) =>
  post(`/accounts/${accountId}/branches/${branchId}/uniforms`, uniform);

export const updateUniform = ({ accountId, branchId, uniformId, uniform }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/uniforms/${uniformId}`, uniform);

export const deleteUniform = ({ accountId, branchId, uniformId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/uniforms/${uniformId}`);

export const fetchDepartments = ({ accountId }) => get(`/accounts/${accountId}/departments`);

export const addDepartment = ({ accountId, department }) => post(`/accounts/${accountId}/departments`, department);

export const updateDepartment = ({ accountId, departmentId, department }) =>
  patch(`/accounts/${accountId}/departments/${departmentId}`, department);

export const deleteDepartment = ({ accountId, departmentId }) =>
  httpDelete(`/accounts/${accountId}/departments/${departmentId}`);

export const fetchRoles = () => get('/roles');

export const fetchPrivileges = () => get('/privileges');

export const fetchLabels = ({ accountId }) => get(`/accounts/${accountId}/labels`);

export const addLabel = ({ accountId, branchId, title, startTime, endTime }) =>
  post(`/accounts/${accountId}/branches/${branchId}/labels`, { title, startTime, endTime });

export const updateLabel = ({ accountId, branchId, labelId, title, startTime, endTime, isDeleted }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/labels/${labelId}`, { title, startTime, endTime, isDeleted });

export const deleteLabel = ({ accountId, branchId, labelId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/labels/${labelId}`);

export const updateLabels = ({ accountId, branchId, labels }) =>
  put(`/accounts/${accountId}/branches/${branchId}/labels`, { labels });

export const fetchProjects = ({ accountId, branchIds, from, to, allEmployees, filters, isArchived, full }) =>
  get(`/accounts/${accountId}/projects`, {
    branches: branchIds,
    from,
    to,
    employees: allEmployees ? 'all' : 'booked',
    filters,
    isArchived,
    listType: true,
    warnings: full,
    full
  });

export const fetchPresentationInfo = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/presentationInfo`);

export const fetchProject = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}`);

export const fetchProjectHourly = ({ accountId, branchId, from, to }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/hourly`, { from, to });

export const addProjects = ({ accountId, branchId, projects }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects`, projects);

export const updateProject = ({ accountId, branchId, projectId, project, deleteEmptyProject = true }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}`, { ...project, deleteEmptyProject });

export const deleteProject = ({ accountId, branchId, projectId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}`);

export const orderProject = ({ accountId, projectId, supplierBranchId, comments, uniforms, shifts }) =>
  post(`/accounts/${accountId}/projects/${projectId}/order`, { supplierBranchId, comments, uniforms, shifts });

export const editProjectOSSlot = ({ accountId, branchId, projectId, slotId, slot }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/os/slots/${slotId}`, slot);

export const editOSTimesheet = ({ accountId, timesheetId, timesheet }) =>
  patch(`/accounts/${accountId}/timesheets/os/${timesheetId}`, timesheet);

export const fetchProjectsPayroll = ({ accountId, branchIds, projectIds }) =>
  get(`/accounts/${accountId}/projects/payroll`, { branches: branchIds, projectIds });

export const fetchProjectPayroll = ({ accountId, branchId, projectId, employeeIds }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/payroll`, { employeeIds });

export const addSchedule = ({ accountId, branchId, projectId, shiftId, slotId, employeeId }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/booking`, {
    slotId,
    employeeId
  });

export const removeSchedule = ({ accountId, branchId, projectId, shiftId, slotId, isSoftDelete, push }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/booking`, {
    slotId,
    isSoftDelete,
    push
  });

export const updateSlot = ({ accountId, branchId, projectId, shiftId, slotId, slot }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/slots/${slotId}`, slot);

export const updateShowStatusUnbooking = ({ accountId, branchId, projectId, shiftId, unbookingId, noShow }) =>
  patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/unbookings/${unbookingId}`,
    { noShow }
  );

export const createConfirmation = ({ accountId, branchId, projectId, shiftIds, message }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/confirmations`, {
    shifts: shiftIds,
    message
  });

export const resendConfirmations = ({ accountId, branchId, projectId }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/confirmations/resend`);

export const updateConfirmationSlot = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  slotId,
  confirmationId,
  isConfirmed
}) =>
  patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/slots/${slotId}/confirmations/${confirmationId}`,
    { isConfirmed }
  );

export const autoFillTimeSheet = ({ accountId, ids }) => put(`/accounts/${accountId}/timesheets/autofill`, { ids });
export const autoFillOSTimeSheet = ({ accountId, ids }) =>
  put(`/accounts/${accountId}/timesheets/os/autofill`, { ids });

export const prefetchAvailableEmployees = ({ accountId, branchId, pageSize, projectIds }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/availability`, {
    projectIds,
    pageSize,
    token: '0-0',
    kinds: ['available', 'applied', 'other', 'applied-rejection', 'available-rejection'],
    warnings: true,
    info: false
  });

export const fetchSearchAvailableEmployees = ({ accountId, branchId, projectId, shiftId, name, pageSize, token }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/availability`, {
    name,
    pageSize,
    token: token || '0-0',
    kinds: ['available', 'applied', 'other', 'applied-rejection', 'available-rejection'],
    warnings: true,
    info: false
  });

export const fetchAvailableEmployees = ({ accountId, branchId, projectId, name, showRejected }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/availability`, {
    name,
    projectIds: [projectId],
    pageSize: 100,
    token: '0-0',
    kinds: showRejected
      ? ['available', 'applied', 'applied-rejection', 'available-rejection']
      : ['available', 'applied'],
    warnings: true,
    info: true
  });

export const fetchProjectInfo = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/info`);

export const fetchOpenShifts = ({ accountId, branchId, employeeId, date }) =>
  get(`/accounts/${accountId}/branches/${branchId}/employees/${employeeId}/availability`, { date });

export const publishProjects = ({ accountId, branchId, projectIds }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/broadcast`, { ids: projectIds });

export const publishProjectEmployeeSlot = ({ accountId, branchId, projectId, employeeSlotsIds }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/broadcast/slots`, { ids: employeeSlotsIds });

export const copyProjects = ({ accountId, branchId, data }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/copy`, data);

export const fetchPosts = ({ accountId, branches, token }) =>
  get(`/accounts/${accountId}/posts`, { branches, pageSize: 3, token: token || 0 });

export const fetchPost = ({ accountId, postId }) => get(`/accounts/${accountId}/posts/${postId}`);

export const addPost = ({ accountId, branches, ...params }) =>
  post(`/accounts/${accountId}/posts`, { branches, ...params });

export const editPost = ({ accountId, id: postId, ...params }) =>
  patch(`/accounts/${accountId}/posts/${postId}`, params);

export const deletePost = ({ accountId, id: postId }) => httpDelete(`/accounts/${accountId}/posts/${postId}`);

export const getFilters = ({ accountId }) => get(`/accounts/${accountId}/filters`);

export const fetchPublication = ({ accountId, publicationId }) =>
  get(`/accounts/${accountId}/publications/${publicationId}`);

export const fetchPublicationsBatch = ({ accountId, publicationIds }) =>
  get(`/accounts/${accountId}/publications/batch`, { publicationIds });

export const addPublication = ({ accountId, ...params }) => post(`/accounts/${accountId}/publications`, params);

export const updatePublication = ({ accountId, publicationId, ...params }) =>
  put(`/accounts/${accountId}/publications/${publicationId}`, params);

export const notifyPublication = ({ accountId, publicationId, ...params }) =>
  post(`/accounts/${accountId}/publications/${publicationId}/notification`, params);

export const updatePublicationInfo = ({ accountId, publicationId, ...params }) =>
  patch(`/accounts/${accountId}/publications/${publicationId}`, params);

export const fetchReports = () => get('/reports');

export const fetchReportCategories = () => get('/categories');

export const fetchReport = ({ accountId, reportId, fromDate, toDate }) =>
  get(`accounts/${accountId}/reports/${reportId}`, { fromDate, toDate });

export const fetchActiveAccountModules = ({ accountId }) => get(`/accounts/${accountId}/modules`);

export const updateModules = ({ accountId, modules }) => put(`/accounts/${accountId}/modules`, { moduleIds: modules });

export const postAction = () => delay({});

export const fetchClients = ({ accountId }) => get(`/accounts/${accountId}/clients`);

export const fetchClient = ({ accountId, clientId }) => get(`/accounts/${accountId}/clients/${clientId}`);

export const addClient = ({ accountId, ...params }) => post(`/accounts/${accountId}/clients`, params);

export const updateClient = ({ accountId, clientId, ...params }) =>
  patch(`/accounts/${accountId}/clients/${clientId}`, params);

export const deleteClient = ({ accountId, clientId }) => httpDelete(`/accounts/${accountId}/clients/${clientId}`);

export const inviteClientAdmin = ({ accountId, clientId, adminId }) =>
  post(`/accounts/${accountId}/clients/${clientId}/admins/${adminId}`);

export const fetchSuppliers = ({ accountId }) => get(`/accounts/${accountId}/suppliers`);
export const fetchSupplier = ({ accountId, supplierId }) => get(`/accounts/${accountId}/suppliers/${supplierId}`);

export const addSupplier = ({ accountId, ...params }) => post(`/accounts/${accountId}/suppliers`, params);

export const fetchSmartAnalytics = ({ from, to }) => get(`/analytics/highlights`, { from, to });

export const fetchNewAccounts = ({ from, to }) => get(`/analytics/newAccounts`, { from, to });

export const fetchNewAdmins = ({ from, to }) => get(`/analytics/newAdmins`, { from, to });

export const fetchNewAppUsers = ({ from, to }) => get(`/analytics/newAppUsers`, { from, to });

export const fetchSchedulingOverview = ({ from, to }) => get(`/analytics/schedulingOverview`, { from, to });

export const fetchTimeclockOverview = ({ from, to }) => get(`/analytics/timeclockOverview`, { from, to });

export const fetchAccountsStatuses = ({ from, to }) => get(`/analytics/accountsStatuses`, { from, to });

export const fetchAccountsCountries = ({ from, to }) => get(`/analytics/accountsCountries`, { from, to });

export const fetchAccountsIndustries = ({ from, to }) => get(`/analytics/accountsIndustries`, { from, to });

export const fetchAccountsCharts = ({ from, to }) => get(`/analytics/accountChart`, { from, to });

export const fetchFinancial = () => delay(dashboardFinancial);

export const fetchPlans = () => get(`/plans`);

export const fetchPayroll = ({ accountId, branches, from, to, employeeIds }) =>
  get(`/accounts/${accountId}/payroll`, { from, to, employeeIds, branches });

export const updateWage = ({ accountId, wageId, wage }) => patch(`/accounts/${accountId}/wages/${wageId}`, wage);

export const updateJobPayrollField = ({ accountId, jobId, payrollFieldId, value }) =>
  patch(`/accounts/${accountId}/jobs/${jobId}/payroll/fields/${payrollFieldId}`, { value });

export const updateSlotPayrollField = ({ accountId, employeeSlotId, payrollFieldId, value }) =>
  patch(`/accounts/${accountId}/employees-slots/${employeeSlotId}/payroll/fields/${payrollFieldId}`, { value });

export const fetchPayrollFields = ({ accountId }) => get(`/accounts/${accountId}/payroll/fields`);

export const addPayrollField = ({ accountId, field }) => post(`/accounts/${accountId}/payroll/fields`, field);

export const updatePayrollField = ({ accountId, fieldId, field }) =>
  patch(`/accounts/${accountId}/payroll/fields/${fieldId}`, field);

export const deletePayrollField = ({ accountId, fieldId }) =>
  patch(`/accounts/${accountId}/payroll/fields/${fieldId}`, { isDeleted: true });

export const fetchPayrollPeriods = () => get('/payroll-period-types');

export const fetchRequests = ({ accountId }) => get(`/accounts/${accountId}/requests`);

export const fetchRequestsTypes = () => get('/requests/types');

export const updateRequest = ({ accountId, requestId, ...body }) =>
  patch(`/accounts/${accountId}/requests/${requestId}`, body);

export const fetchPeriod = ({ accountId, branchId, date }) =>
  get(`/accounts/${accountId}/branches/${branchId}/periods`, { date });

export const updatePeriod = ({ accountId, branchId, id, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/periods/${id}`, params);

export const createPeriod = ({ accountId, branchId, date }) =>
  post(`/accounts/${accountId}/branches/${branchId}/periods`, { date });

export const fetchManagers = ({ accountId, adminId }) => get(`/accounts/${accountId}/managers/${adminId}/employees`);

export const updateManagers = ({ accountId, adminId, ids }) =>
  put(`/accounts/${accountId}/managers/${adminId}/employees`, { ids });

export const resendAdminEmail = ({ accountId, employeeId }) =>
  post(`/accounts/${accountId}/employees/${employeeId}/admins/invite`);

export const autoFillProjects = ({ accountId, branchId, date }) =>
  post(`/accounts/${accountId}/branches/${branchId}/schedule/auto`, { date });

export const autoFillProjectTimesheet = ({ accountId, branchId, projectId, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/timesheets`, params);

export const autoFillOSProjectTimesheet = ({ accountId, branchId, projectId, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/timesheets/os`, params);

export const addSchedulesShift = ({ accountId, branchId, projectId, shiftId, employeeIds }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/booking/bulk`, {
    employeeIds
  });

export const fetchAvailabilities = ({ accountId, start, end, branches, employeeIds }) =>
  get(`/accounts/${accountId}/availabilities`, { start, end, branches, employeeIds });

export const updateAvailabilities = ({ accountId, body }) => put(`/accounts/${accountId}/availabilities`, body);

export const fetchSequences = ({ accountId }) => get(`/accounts/${accountId}/sequences`);

export const fetchSequence = ({ accountId, sequenceId }) => get(`/accounts/${accountId}/sequences/${sequenceId}`);

export const fetchSequenceResult = ({ accountId, sequenceId, instanceId }) =>
  get(`/accounts/${accountId}/sequences/${sequenceId}/instances/${instanceId}/export`);

export const addSequence = ({ accountId, ...body }) => post(`/accounts/${accountId}/sequences`, body);

export const updateSequence = ({ accountId, sequenceId, ...body }) =>
  patch(`/accounts/${accountId}/sequences/${sequenceId}`, body);

export const deleteSequence = ({ accountId, sequenceId }) =>
  httpDelete(`/accounts/${accountId}/sequences/${sequenceId}`);

export const fetchProjectTodo = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos`);

export const addProjectTodo = ({ accountId, branchId, projectId, ...params }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos`, params);

export const updateProjectTodo = ({ accountId, branchId, projectId, todoId, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos/${todoId}`, params);

export const deleteProjectTodo = ({ accountId, branchId, projectId, todoId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos/${todoId}`);

export const fetchOrganization = ({ accountId }) => get(`/accounts/${accountId}/managers`);

//DASHBOARD

export const fetchAccountOverview = ({ accountId }) => get(`/accounts/${accountId}/dashboard/steps`);

export const fetchBranchesOverview = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/dashboard/branches/payroll`, { from, to });

export const fetchBranchesLocations = ({ accountId }) => get(`/accounts/${accountId}/dashboard/locations`);

export const fetchEmployeesOverview = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/dashboard/employees/milestones`, { from, to });

export const fetchPayrollOverview = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/dashboard/account/payroll`, { from, to });

export const enableRevenue = ({ accountId }) => post(`/accounts/${accountId}/dashboard/revenue`);

export const fetchGenericIntegrations = () => get('/integrations');

export const fetchIntegrations = ({ accountId }) => get(`/accounts/${accountId}/integrations`);

export const updateIntegrations = ({ accountId, integrationId, ...params }) =>
  put(`/accounts/${accountId}/integrations/${integrationId}`, params);

// CREW

export const getAccounts = () => get('/accounts');

export const getBranches = () => get('/branches');

export const getLabels = () => get('/labels');

export const getPositions = () => get('/positions');

export const addBreaktime = ({ timesheetId }) => post(`/timesheets/${timesheetId}/breaktimes`);

export const editBreaktime = ({ timesheetId, breaktimeId }) =>
  patch(`/timesheets/${timesheetId}/breaktimes/${breaktimeId}`, { clockType: 'out' });

export const deleteBreaktime = ({ accountId, timesheetId, breaktimeId }) =>
  httpDelete(`/accounts/${accountId}/timesheets/${timesheetId}/breaktimes/${breaktimeId}`);

export const clock = ({ id, clockType }) => patch(`/timesheets/${id}/clock`, { clockType });

export const getTimesheets = () => get('/timesheets');

export const addJob = ({ date, branchId }) => post('/jobs', { date, branchId });

export const deleteEmployeeApplication = ({ accountId, branchId, employeeIds, projectId, shiftId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/employees`, {
    employeeIds
  });
export const fetchEmployeeShiftUnbookings = ({ accountId, employeeId }) =>
  get(`/accounts/${accountId}/employees/${employeeId}/unbookings`);

export const fetchEmployeeApplications = ({ accountId, employeeId }) =>
  get(`/accounts/${accountId}/employees/${employeeId}/applications`);

export const updateProjectRegistration = ({ accountId, branchId, projectId, isPaused, message }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/registration`, { isPaused, message });

export const fetchAuditLogs = ({ accountId, timesheetIds, jobIds }) =>
  get(`/accounts/${accountId}/audit-logs`, { timesheetIds, jobIds });

export const fetchPresentations = ({ accountId, projectId, presentationHash }) =>
  get(`/accounts/${accountId}/projects/${projectId}/presentation`, { presentationHash });

export const shiftAvailabilityRelease = ({ accountId, branchId, projectId, shiftId, message }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/releaseEmployees`, {
    message
  });

export const addShift = ({ accountId, branchId, projectId, values }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts`, values);

export const fetchTimeAvailabilities = ({ accountId, branchIds, from, to }) =>
  get(`/accounts/${accountId}/timeAvailabilities`, { branchIds, from, to });

export const fetchEmployeesTimeAvailabilities = ({ accountId, branchIds, from, to, employeeIds }) =>
  get(`/accounts/${accountId}/timeAvailabilities`, { branchIds, from, to, employeeIds });

export const addTimeAvailabilities = ({ accountId, timeAvailabilities, employeeId }) =>
  post(`/accounts/${accountId}/timeAvailabilities`, { timeAvailabilities, employeeId });

export const deleteTimeAvailability = ({ accountId, timeAvailabilityId }) =>
  httpDelete(`/accounts/${accountId}/timeAvailabilities/${timeAvailabilityId}`);

export const tempFix = () => post(`/accounts/tempFix`);

export const fetchEmployeeJobStats = ({
  accountId,
  employeeId,
  futureFromDate,
  futureToDate,
  pastFromDate,
  pastToDate
}) =>
  get(`/accounts/${accountId}/employees/${employeeId}/stats/jobs`, {
    futureFromDate,
    futureToDate,
    pastFromDate,
    pastToDate
  });

export const fetchBranchOrdersForecast = ({ accountId, branches, from, to }) =>
  get(`/accounts/${accountId}/branch-orders-forecast`, {
    branches,
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD')
  });

export const updateBranchOrdersForecast = ({ accountId, data }) =>
  put(`/accounts/${accountId}/branch-orders-forecast`, { data });
