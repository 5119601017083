import React from 'react';
import { useTranslation } from 'react-i18next';
import { required } from '@ubeya/shared/utils/form';
import InputField from '../../FormFields/InputField';
import CheckBoxField from '../../FormFields/CheckBoxField';

const TextFieldForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        validate={required}
        required
        autoFocus
        className="full"
      />
      <CheckBoxField name="isMultiline" title={t('isMultiline')} className="full" />
    </>
  );
};

export default TextFieldForm;
