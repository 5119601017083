import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useField } from 'react-final-form';
import BaseRichTextEditor from 'react-rte';
import { scrollbar } from '../../../style';

const RichTextEditor = styled(BaseRichTextEditor)`
  font-family: inherit;

  span[class^='ImageSpan_'] {
    letter-spacing: 0 !important;
    background-size: contain !important;
    width: 100% !important;
    height: auto !important;
    font-size: medium !important;
    line-height: 200px !important;
  }

  div[class^='RichTextEditor__editor'] {
    height: 280px;
  }

  div.public-DraftEditor-content {
    overflow: auto;
    ${scrollbar};
  }
`;

const toolbarConfig = {
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Strikethrough', style: 'STRIKETHROUGH' },
    { label: 'Monospace', style: 'CODE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
    { label: 'Code Block', style: 'code-block' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Blockquote', style: 'blockquote' }
  ]
};

const DescriptionFieldForm = () => {
  const {
    input: { value, onChange }
  } = useField('metadata.text');

  const [state, setState] = useState(
    RichTextEditor.createValueFromString(value, 'markdown') || RichTextEditor.createEmptyValue()
  );

  const handleSetChange = useCallback(
    (value) => {
      setState(value);
      onChange(value.toString('markdown'));
    },
    [onChange]
  );

  return (
    <div className="full">
      <RichTextEditor value={state} onChange={handleSetChange} autoFocus toolbarConfig={toolbarConfig} />
    </div>
  );
};

export default DescriptionFieldForm;
