import { createCachedSelector } from 're-reselect';
import { useQuery, useMutation } from 'react-query';
import * as api from '../../services/api';
import { mappedArray } from '../../utils/array';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data) => data.storeKey,
  (data) => {
    const clients = data;
    const activeClients = (clients || []).filter(({ isDeleted }) => !isDeleted);
    const inactiveClients = (clients || []).filter(({ isDeleted }) => isDeleted);

    const clientsOptions = activeClients.reduce(
      (prev, { name, branches }) => [
        ...prev,
        ...branches.map((branch) => ({
          value: branch.id,
          label: name === branch.name ? name : `${name} - ${branch.name}`
        }))
      ],
      []
    );

    const mappedClientBranches = (data || []).reduce(
      (prev, client) => ({
        ...prev,
        ...(client?.branches || []).reduce(
          (all, { id, name }) => ({
            [id]: { ...client, name: client.name === name ? client.name : `${client.name} - ${name}` }
          }),
          {}
        )
      }),
      {}
    );

    const mappedClients = mappedArray(clients || []);
    const mappedClientsByAccountId = mappedArray(clients || [], (item) => item.accountId);

    return {
      clients,
      activeClients,
      inactiveClients,
      clientsOptions,
      mappedClientBranches,
      mappedClients,
      mappedClientsByAccountId
    };
  }
)({
  keySelector: (data, storeKey) => storeKey
});

const useCRM = () => {
  const { accountId } = useAccount();
  const storeKey = ['clients', accountId];
  const { isLoading, data } = useQuery(storeKey, () => api.fetchClients({ accountId }), {
    select: (data) => selector(data, storeKey.join('#'))
  });
  const {
    clients = [],
    activeClients = [],
    inactiveClients = [],
    clientsOptions = [],
    mappedClientBranches = {},
    mappedClients = {},
    mappedClientsByAccountId = {}
  } = data || {};

  const { mutateAsync: inviteClientAdmin } = useMutation(api.inviteClientAdmin);

  return {
    isLoading,
    clients,
    activeClients,
    inactiveClients,
    clientsOptions,
    mappedClientBranches,
    mappedClients,
    mappedClientsByAccountId,
    inviteClientAdmin
  };
};

export default useCRM;
