import { useCallback, useEffect } from 'react';

const usePressKey = ({ which, ref, onSelect, preventDefault = true }) => {
  const handlePress = useCallback(
    (e) => {
      if (e.which === which) {
        preventDefault && e.preventDefault();
        onSelect();
      }
    },
    [onSelect, preventDefault, which]
  );

  useEffect(() => {
    const node = ref.current;
    if (!node) return;
    node.addEventListener('keydown', handlePress);

    return () => {
      node.removeEventListener('keydown', handlePress);
    };
  }, [ref, handlePress]);
};

export default usePressKey;
