const { useState, useMemo } = require('react');

// when virtualized is on - we need to add a dummy sticky header which will not be unmount

const useStickyDrawer = ({ data, flatData, extendedRows, renderRow }) => {
  // define the first visible index for adding sticky drawer
  const [visibleIndex, setVisibleIndex] = useState();

  const stickyDrawerIndex = useMemo(() => flatData[visibleIndex]?.realIndex, [flatData, visibleIndex]);

  const stickyDrawer = useMemo(
    () =>
      data[stickyDrawerIndex] &&
      extendedRows[stickyDrawerIndex] &&
      renderRow(-1, { ...data[stickyDrawerIndex], realIndex: stickyDrawerIndex }, undefined, true),
    [data, extendedRows, renderRow, stickyDrawerIndex]
  );

  return { stickyDrawer, setVisibleIndex };
};

export default useStickyDrawer;
