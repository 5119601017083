import styled from 'styled-components';
import scrollbar from '../../../style/scrollbar';
import { card } from '../../Card';

const Styles = styled.div.attrs(({ width }) => ({ className: 'table', style: { width } }))`
  background: ${({ theme }) => theme.colors.surface};
  position: relative;
  ${card}
  ${({ theme, shadow }) => !shadow && `box-shadow: none; border: 1px solid ${theme.colors.gray900};`}
  will-change: width;

  > .grid {
    ${({ autoHeight, height }) => !autoHeight && `height: ${height}px`};
    overflow: ${({ autoHeight }) => (autoHeight ? 'visible' : ' auto')}!important;
    position: relative;
    direction: ltr !important;
    ${scrollbar};
    opacity: ${({ isLoading }) => (isLoading ? 0.3 : 1)};
    outline: none;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

export default Styles;
