import { useState, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';

const useExtendedRow = (defaultExtendsRows) => {
  const [extendedRows, setExtendedRows] = useState(() => defaultExtendsRows || {});

  useEffect(() => {
    if (defaultExtendsRows && !isEqual(defaultExtendsRows, extendedRows)) {
      setExtendedRows(defaultExtendsRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExtendsRows]);

  const handleToggleRow = useCallback((index) => setExtendedRows((prev) => ({ ...prev, [index]: !prev[index] })), []);

  return { extendedRows, handleToggleRow };
};

export default useExtendedRow;
