import React from 'react';
import styled, { css } from 'styled-components';
import { body } from '../Typography';
import { Loader as BaseLoader } from '../Loader';
import { Tooltip } from '../Tooltip';
import withPrivileges from '../../hoc/withPrivileges';

const generalCss = (type) => css`
  border-width: ${({ theme }) => theme.button[type].general?.borderWidth};
  padding: ${({ theme }) => theme.button[type].general?.padding};
  border-radius: ${({ theme }) => theme.button[type].general?.borderRadius};
`;

const themeCss = (type, state = 'normal') => css`
  background: ${({ theme }) => theme.button[type][state]?.background};
  border-color: ${({ theme }) => theme.button[type][state]?.border};
  color: ${({ theme }) => theme.button[type][state]?.color};
  box-shadow: ${({ theme }) => theme.button[type][state]?.shadow};
`;

const Wrapper = styled.button`
  padding: 13px 24px;
  border-radius: 3px;
  border: 1px solid transparent;
  ${body};
  cursor: pointer;
  transition: 200ms;
  outline: none;
  position: relative;
  align-items: center;
  ${({ primary }) => primary && generalCss('primary')}
  ${({ secondary }) => secondary && generalCss('secondary')}
  ${({ tertiary }) => tertiary && generalCss('tertiary')}
  ${({ success }) => success && generalCss('success')}
  ${({ link }) => link && generalCss('link')}


  ${({ primary }) => primary && themeCss('primary')}
  ${({ secondary }) => secondary && themeCss('secondary')}
  ${({ tertiary }) => tertiary && themeCss('tertiary')}
  ${({ success }) => success && themeCss('success')}
  ${({ link }) => link && themeCss('link')}

  &:not(:disabled):hover {
    ${({ primary }) => primary && themeCss('primary', 'hover')}
    ${({ secondary }) => secondary && themeCss('secondary', 'hover')}
		${({ tertiary }) => tertiary && themeCss('tertiary', 'hover')}
		${({ success }) => success && themeCss('success', 'hover')}
		${({ link }) => link && themeCss('link', 'hover')}
  }

  ${({ primary, isSelected }) => primary && isSelected && themeCss('primary', 'hover')}
  ${({ secondary, isSelected }) => secondary && isSelected && themeCss('secondary', 'hover')}
	${({ tertiary, isSelected }) => tertiary && isSelected && themeCss('tertiary', 'hover')}
	${({ success, isSelected }) => success && isSelected && themeCss('success', 'hover')}
	${({ link, isSelected }) => link && isSelected && themeCss('link', 'hover')}

	&:disabled {
    cursor: no-drop;
    ${({ primary }) => primary && themeCss('primary', 'disabled')}
    ${({ secondary }) => secondary && themeCss('secondary', 'disabled')}
		${({ tertiary }) => tertiary && themeCss('tertiary', 'disabled')}
		${({ success }) => success && themeCss('success', 'disabled')}
		${({ link }) => link && themeCss('link', 'disabled')}
  }

  &:focus {
    ${({ primary }) => primary && themeCss('primary', 'pressed')}
    ${({ secondary }) => secondary && themeCss('secondary', 'pressed')}
		${({ tertiary }) => tertiary && themeCss('tertiary', 'pressed')}
		${({ success }) => success && themeCss('success', 'pressed')}
		${({ link }) => link && themeCss('link', 'pressed')}
  }

  ${({ narrow }) => narrow && 'padding: 5px 20px;'}
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: ${({ narrow }) => (narrow ? 7 : 14)}px;
  left: calc(50% - 10px);
`;

const Button = ({ isLoading, children, disabled, narrow, tooltip, ...props }) => (
  <Tooltip body={tooltip} disabled={!tooltip}>
    <Wrapper {...props} disabled={isLoading || disabled} narrow={narrow}>
      {children}
      {isLoading && <Loader size="small" narrow={narrow} />}
    </Wrapper>
  </Tooltip>
);

export default withPrivileges(Button);
