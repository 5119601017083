import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useFields from '@ubeya/shared/hooks/account/useFields';
import { CheckBoxField } from '../FormFields';
import FormModal from '../FormModal';
import { SmallBody } from '../Typography';

const HIDE_DYNAMIC_FIELDS = ['start-employment-date', 'end-employment-date', 'employee-id'];

const Hint = styled(SmallBody)`
  color: ${({ theme }) => theme.colors.info};
  margin: -20px 0 24px;
`;

const ProfileInputModal = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();

  const { fields, mappedFields } = useFields();

  return (
    <FormModal
      validate={(values) =>
        Object.entries(values || {}).filter(([, value]) => !!value).length > 0 ? undefined : { required: true }
      }
      onSubmit={(values) => {
        const selectedFields = Object.entries(values || {})
          .filter(([, value]) => !!value)
          .map(([key]) => ({ ...mappedFields[parseInt(key.replace('profile-field-', ''), 10)], isRequired: true }));

        onConfirm(selectedFields);
        onClose();
      }}
      onClose={onClose}
      size="small"
      title={t('profileField')}
      confirmText="add"
      disabledOnInvalid>
      <Hint>{t('clickToRemoveFields')}</Hint>

      {fields
        .filter(({ slug }) => !HIDE_DYNAMIC_FIELDS.includes(slug))
        .map(({ id, isGeneric, name, slug }) => (
          <CheckBoxField key={id} name={`profile-field-${id}`} title={isGeneric ? t(slug) : name} />
        ))}
    </FormModal>
  );
};

export default ProfileInputModal;
