import { createCachedSelector } from 're-reselect';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import * as api from '../../services/api';
import useCRUD from '../useCRUD';
import * as sharedAtoms from '../../atoms/shared';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data, branchId) => branchId,
  (data, branchId) => {
    const locations = (data || []).filter(({ branches }) => branches.includes(branchId));
    const locationsOptions = (locations || []).map(({ id, name }) => ({ value: id, label: name }));
    const mappedLocations = (data || []).reduce((prev, { id, ...location }) => ({ ...prev, [id]: location }), {});

    return { locations, locationsOptions, mappedLocations };
  }
)({
  keySelector: (data, branchId, storeKey) => `${storeKey.join('#')}#${branchId}`
});

const useLocations = ({ branchId: customBranchId } = {}) => {
  const { accountId } = useAccount();
  const defaultBranchId = useRecoilValue(sharedAtoms.selectedBranch);
  const branchId = customBranchId ? parseInt(customBranchId, 10) : defaultBranchId;

  const storeKey = ['locations', accountId];
  const { isLoading, data, refetch } = useQuery(storeKey, () => api.fetchLocations({ accountId }), {
    select: (data) => selector(data, branchId, storeKey)
  });

  const { locations = [], locationsOptions = [], mappedLocations = {} } = data || {};

  const { addItem, editItem, deleteItem } = useCRUD(
    storeKey,
    {
      addApi: (location) => api.addLocation({ accountId, branchId, location }),
      editApi: ({ id, ...location }) => api.updateLocation({ accountId, branchId, locationId: id, location }),
      deleteApi: ({ id }) => api.deleteLocation({ accountId, branchId, locationId: id })
    },
    { formatItem: (item) => ({ ...item, branches: [branchId] }) }
  );

  return {
    isLoading,
    locations,
    refetch,
    locationsOptions,
    mappedLocations,
    deleteLocation: deleteItem,
    addLocation: addItem,
    editLocation: editItem
  };
};

export default useLocations;
