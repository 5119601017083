import React, { useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { FlexMiddle } from '../Flex';
import { responsiveShow, responsiveHide } from '../../style/responsive';
import BaseModal from '../Modal';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { linkTab } from './LinkTab';

const Wrapper = styled(FlexMiddle)`
  position: relative;
`;

const Modal = styled(BaseModal)`
  .content {
    align-items: center;
  }
`;

const SelectedValue = styled(FlexMiddle)`
  cursor: pointer;
  ${responsiveHide.tablet};

  > svg {
    margin-left: -13px;
    transform: rotate(${({ isOpen }) => (isOpen ? -90 : 90)}deg);
  }

  > a,
  > div {
    display: none;
    pointer-events: none;
    margin-right: 30px;
    &.active {
      display: flex;
    }
  }
`;

const Options = styled(FlexMiddle)`
  ${responsiveShow.tablet};
`;

const Option = styled(FlexMiddle)`
  > a,
  > div {
    ${linkTab};
    margin-right: 0;
    background: ${({ theme }) => theme.colors.surface}!important;
    color: ${({ theme }) => theme.colors.gray200}!important;
  }
`;

const LinksContainer = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false), true, 'modal');

  const childrenWithCloseClick = useMemo(
    () => React.Children.map(children, (child) => <Option onClick={() => setIsOpen(false)}>{child}</Option>),
    [children]
  );

  return (
    <Wrapper ref={ref} className={className}>
      <SelectedValue isOpen={isOpen} onClick={() => setIsOpen(true)}>
        {children}
        <Arrow />
      </SelectedValue>
      <Options>{children}</Options>

      {isOpen && (
        <Modal size="xs" showConfirmButton={false} onClose={() => setIsOpen(false)} usePortal>
          {childrenWithCloseClick}
        </Modal>
      )}
    </Wrapper>
  );
};

export default LinksContainer;
