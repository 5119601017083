import React from 'react';
import styled, { css } from 'styled-components';
import { flexCenter } from '../Flex';
import { Tooltip } from '../Tooltip';
import { ReactComponent as LogoIcon } from '../../assets/logo-empty.svg';
import star from '../../assets/star-fill.svg';

const SIZES = {
  xs: { image: 20, font: 10 },
  small: { image: 24, font: 11 },
  medium: { image: 28, font: 13 },
  medium2: { image: 34, font: 13 },
  big: { image: 50, font: 13 },
  large: { image: 150, font: 52 }
};

const Image = styled.div.attrs(({ image }) => ({ className: 'avatar', style: { backgroundImage: `url(${image})` } }))`
  position: relative;
  ${flexCenter};
  border-radius: 100%;
  width: ${({ size }) => SIZES[size]?.image || size}px;
  height: ${({ size }) => SIZES[size]?.image || size}px;
  min-width: ${({ size }) => SIZES[size]?.image || size}px;
  min-height: ${({ size }) => SIZES[size]?.image || size}px;
  background: #e9ebf0;
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.primary};
  border: ${({ image }) => (image ? 0 : 1)} solid ${({ theme }) => theme.colors.gray800};
  text-align: center;
  font-weight: 500;
  font-size: ${({ size }) => SIZES[size]?.font}rem;
  ${({ onClick }) => onClick && 'cursor:pointer'};
  > svg {
    width: ${({ size }) => (SIZES[size]?.image || size) / 1.5}px;
    height: ${({ size }) => (SIZES[size]?.image || size) / 1.5}px;
  }

  ${({ $showStar }) =>
    $showStar &&
    css`
      &:after {
        content: '';
        width: 14px;
        height: 14px;
        background: url('${star}');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: -4px;
        bottom: 1px;
      }
    `}
`;

const Avatar = ({
  className,
  firstName,
  lastName,
  image,
  onClick,
  size = 'medium2',
  tooltip = false,
  isAccount = false,
  showStar = false
}) => {
  const name = [firstName, lastName].join(' ');
  if (image) {
    return (
      <Tooltip body={name} disabled={!tooltip}>
        <Image image={image} className={className} size={size} $showStar={showStar} onClick={onClick} />
      </Tooltip>
    );
  }

  if (isAccount) {
    return (
      <Tooltip body={name} disabled={!tooltip}>
        <Image className={className} size={size} $showStar={showStar} onClick={onClick}>
          <LogoIcon />
        </Image>
      </Tooltip>
    );
  }

  if (firstName || lastName) {
    return (
      <Tooltip body={name} disabled={!tooltip}>
        <Image className={className} size={size} $showStar={showStar} onClick={onClick}>
          {[firstName?.charAt?.(0), lastName?.charAt?.(0)].join('')}
        </Image>
      </Tooltip>
    );
  }

  return (
    <Image className={className} size={size} $showStar={showStar} onClick={onClick}>
      N/A
    </Image>
  );
};

export default Avatar;
