import axios from 'axios';
import qs from 'qs';

const baseURL = process.env.API_BASE_URL ?? 'http://localhost:7400';

const api = (fn, args) =>
  fn.apply(axios, args).then(
    (res) => res.data,
    (res) => Promise.reject(res)
  );

const _get = (...rest) => api(axios.get, rest);

const _post = (...rest) => api(axios.post, rest);

const _put = (...rest) => api(axios.put, rest);

const _httpDelete = (...rest) => api(axios.delete, rest);

const _patch = (...rest) => api(axios.patch, rest);

const httpOptions = () => ({ baseURL, responseType: 'json', withCredentials: true });

export const get = (url, params = {}, options = {}) =>
  _get(url, {
    ...httpOptions(),
    ...options,
    params,
    paramsSerializer: (params) => {
      const { branches, projectIds, ...rest } = params || {};

      let base = qs.stringify(rest, { arrayFormat: 'indices', indices: true });

      if (
        branches &&
        Array.isArray(branches) &&
        branches.every(function (element) {
          return typeof element === 'number';
        })
      ) {
        base = `${base}${base.length === 0 ? '' : '&'}branches=[${(branches || []).join(',')}]`;
      }

      if (
        (branches &&
          Array.isArray(branches) &&
          !branches.every(function (element) {
            return typeof element === 'number';
          })) ||
        (branches && !Array.isArray(branches))
      ) {
        base = `${base}${base.length === 0 ? '' : '&'}${qs
          .stringify({ branches }, { arrayFormat: 'indices', indices: true })
          .slice(1)}`;
      }

      if (
        projectIds &&
        Array.isArray(projectIds) &&
        projectIds.every(function (element) {
          return typeof element === 'number';
        })
      ) {
        base = `${base}${base.length === 0 ? '' : '&'}projectIds=[${(projectIds || []).join(',')}]`;
      }

      if (
        (projectIds &&
          Array.isArray(projectIds) &&
          !projectIds.every(function (element) {
            return typeof element === 'number';
          })) ||
        (projectIds && !Array.isArray(projectIds))
      ) {
        base = `${base}${base.length === 0 ? '' : '&'}${qs
          .stringify({ projectIds }, { arrayFormat: 'indices', indices: true })
          .slice(1)}}`;
      }

      return base;
    }
  });

export const post = (url, body = '', options = {}) => _post(url, body, { ...httpOptions(), ...options });

export const put = (url, body = '') => _put(url, body, { ...httpOptions() });

export const patch = (url, body = '') => _patch(url, body, { ...httpOptions() });

export const httpDelete = (url, data = {}) => _httpDelete(url, { ...httpOptions(), data });

export const delay = (data, millisecs = 1000) =>
  new Promise((resolve) => setTimeout(() => resolve(typeof data === 'function' ? data() : data), millisecs));
