import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexSpace } from '../Flex';
import { Header2 } from '../Typography';
import scrollbar from '../../style/scrollbar';

const Wrapper = styled(FlexColumn)`
  flex: 1;
`;

const TitleRow = styled(FlexSpace)`
  padding: 17px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
  z-index: 1;
  min-height: 70px;
`;

const Content = styled(FlexColumn)`
  flex: 1;
  padding: 40px 50px;
  overflow: auto;
  ${scrollbar};
`;

const Scrollable = styled.div`
  overflow: visible;
  height: 0;
`;

const SettingsPage = ({ title, actionBar, children, scrollable }) => {
  const Container = scrollable ? Scrollable : React.Fragment;

  return (
    <Wrapper>
      <TitleRow>
        <Header2>{title}</Header2>
        {actionBar}
      </TitleRow>

      <Content>
        <Container>{children}</Container>
      </Content>
    </Wrapper>
  );
};

export default SettingsPage;
