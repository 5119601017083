import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// FORMS
import TextFieldForm from '../components/Fields/FieldsForm/TextFieldForm';
import SimpleFieldForm from '../components/Fields/FieldsForm/SimpleFieldForm';
import DropDownFieldForm from '../components/Fields/FieldsForm/DropDownFieldForm';
import CheckboxFieldForm from '../components/Fields/FieldsForm/CheckboxFieldForm';
import SliderFieldForm from '../components/Fields/FieldsForm/SliderFieldForm';
import RatingFieldForm from '../components/Fields/FieldsForm/RatingFieldForm';
import DescriptionFieldForm from '../components/Fields/FieldsForm/DescriptionFieldForm';
import ImageFieldForm from '../components/Fields/FieldsForm/ImageFieldForm';

// FIELDS
import DateField from '../components/FormFields/DateField';
import InputField from '../components/FormFields/InputField';
import SelectField from '../components/FormFields/SelectField';
import ToggleField from '../components/FormFields/ToggleField';
import LocationField from '../components/FormFields/PlacesField';
import SliderField from '../components/FormFields/SliderField';
import PhoneField from '../components/FormFields/PhoneField';
import CheckBoxField from '../components/FormFields/CheckBoxField';
import RatingField from '../components/FormFields/RatingField';
import DescriptionField from '../components/Fields/DescriptionField';
import ImageField from '../components/Fields/ImageField';
import UploadField from '../components/Fields/UploadField';

//ICONS

import { ReactComponent as TextFieldIcon } from '../assets/text-field.svg';
import { ReactComponent as DateFieldIcon } from '../assets/date-field.svg';
import { ReactComponent as DropdownFieldIcon } from '../assets/dropdown-field.svg';
import { ReactComponent as ToggleFieldIcon } from '../assets/toggle-field.svg';
import { ReactComponent as LocationFieldIcon } from '../assets/location-field.svg';
import { ReactComponent as SliderFieldIcon } from '../assets/slider-field.svg';
import { ReactComponent as EmailFieldIcon } from '../assets/email-field.svg';
import { ReactComponent as PhoneFieldIcon } from '../assets/phone-field.svg';
import { ReactComponent as CheckboxFieldIcon } from '../assets/checkbox-field.svg';
import { ReactComponent as RatingFieldIcon } from '../assets/rating-field.svg';
import { ReactComponent as ParagraphFieldIcon } from '../assets/paragraph-field.svg';
import { ReactComponent as ImageFieldIcon } from '../assets/photo.svg';
import { ReactComponent as SignatureFieldIcon } from '../assets/signature.svg';
import { ReactComponent as UploadIcon } from '../assets/upload.svg';
import PrivilegesContext from '../contexts/PrivilegesContext';
import privileges from '../privileges';

export const FIELDS_MAP = {
  1: {
    slug: 'text',
    FormComponent: TextFieldForm,
    Component: InputField,
    viewFormatter: (value) => value,
    supported: true,
    icon: TextFieldIcon,
    index: 1
  },
  2: {
    slug: 'date',
    FormComponent: SimpleFieldForm,
    Component: DateField,
    viewFormatter: (value, { format }) => (value ? moment(value).format(format) : ''),
    supported: true,
    icon: DateFieldIcon,
    index: 2
  },
  3: {
    slug: 'dropdown',
    FormComponent: DropDownFieldForm,
    initialValues: { options: [{ title: '' }, { title: '' }] },
    Component: SelectField,
    viewFormatter: (value, { mapOptions }) =>
      value && Array.isArray(value) ? value.map((option) => mapOptions[option]) : mapOptions[value],
    supported: true,
    icon: DropdownFieldIcon,
    index: 3
  },
  4: {
    slug: 'image',
    FormComponent: ImageFieldForm,
    Component: ImageField,
    content: true,
    icon: ImageFieldIcon,
    index: 5
  },
  5: {
    slug: 'paragraph',
    FormComponent: DescriptionFieldForm,
    Component: DescriptionField,
    content: true,
    icon: ParagraphFieldIcon,
    index: 4
  },
  6: {
    slug: 'yesNo',
    FormComponent: CheckboxFieldForm,
    Component: ToggleField,
    viewFormatter: (value) => (value ? 'YES' : 'NO'),
    icon: ToggleFieldIcon,
    index: 6
  },
  7: { slug: 'location', FormComponent: SimpleFieldForm, Component: LocationField, icon: LocationFieldIcon, index: 7 },
  8: {
    slug: 'slider',
    FormComponent: SliderFieldForm,
    initialValues: { min: 0, max: 10 },
    Component: SliderField,
    icon: SliderFieldIcon,
    index: 8
  },
  9: { slug: 'email', FormComponent: SimpleFieldForm, Component: InputField, icon: EmailFieldIcon, index: 9 },
  10: {
    slug: 'phone',
    FormComponent: SimpleFieldForm,
    Component: PhoneField,
    viewFormatter: (value) => (value ? parsePhoneNumberFromString(`+${value}`).formatNational() : ''),
    icon: PhoneFieldIcon,
    index: 10
  },
  11: {
    slug: 'checkbox',
    FormComponent: SimpleFieldForm,
    Component: CheckBoxField,
    icon: CheckboxFieldIcon,
    showAndNotSupported: true,
    index: 11
  },
  12: {
    slug: 'rating',
    FormComponent: RatingFieldForm,
    Component: RatingField,
    initialValues: { stars: 5 },
    icon: RatingFieldIcon,
    index: 12
  },
  13: {
    slug: 'signature',
    FormComponent: SimpleFieldForm,
    Component: InputField,
    icon: SignatureFieldIcon,
    showAndNotSupported: true,
    index: 13
  },
  14: {
    slug: 'uploadDocument',
    FormComponent: SimpleFieldForm,
    Component: UploadField,
    icon: UploadIcon,
    showAndNotSupported: true,
    index: 14
  }
};

export const SHOW_AND_NOT_SUPPORTED = Object.entries(FIELDS_MAP)
  .filter(([, { showAndNotSupported }]) => showAndNotSupported)
  .map((key) => parseInt(key, 10));

const useFormFields = (includeUnsupportedInputs, includeContent, includeShowNotSupportedInputs) => {
  const { t } = useTranslation();
  const myPrivileges = useContext(PrivilegesContext);

  const fieldTypesOptions = useMemo(() => {
    const inputFields = Object.entries(FIELDS_MAP)
      .sort(([, { index: a }], [, { index: b }]) => a - b)
      .filter(([, { content }]) => !content)
      .filter(([, { supported, showAndNotSupported }]) =>
        includeUnsupportedInputs
          ? true
          : myPrivileges[privileges.UBEYA_ADMIN] && includeShowNotSupportedInputs
          ? supported || showAndNotSupported
          : supported
      );

    const contentFields = Object.entries(FIELDS_MAP)
      .sort(([, { index: a }], [, { index: b }]) => a - b)
      .filter(([, { content }]) => content);

    const allFields = includeContent ? [...inputFields, ...contentFields] : inputFields;

    return allFields.map(([key, value]) => ({
      value: parseInt(key, 10),
      label: t(value?.slug),
      content: value?.content || false,
      icon: value?.icon
    }));
  }, [includeContent, includeShowNotSupportedInputs, myPrivileges, includeUnsupportedInputs, t]);

  const fieldsBySlug = useMemo(
    () => Object.values(FIELDS_MAP).reduce((prev, { slug, ...rest }) => ({ ...prev, [slug]: rest }), {}),
    []
  );

  return { fieldsMap: FIELDS_MAP, fieldTypesOptions, fieldsBySlug };
};

export default useFormFields;
