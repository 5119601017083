import React from 'react';
import styled from 'styled-components';
import { FlexMiddle, FlexColumn } from '../Flex';
import { body, smallBody, small } from '../Typography';
import { ReactComponent as Star } from '../../assets/star.svg';
import { TextWithTooltip } from '../Tooltip';
import BaseAvatar from './Avatar';

const SIZES = {
  xs: small,
  small: smallBody,
  medium: smallBody,
  big: body,
  large: body
};

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'user' }))`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  &:hover .name {
    ${({ $withUnderline, onClick }) => onClick && $withUnderline && 'text-decoration: underline;'}
  }
`;

const Avatar = styled(BaseAvatar)`
  margin-right: ${({ size }) => (size === 'xs' ? 6 : 15)}px;
`;

const Content = styled(FlexColumn)`
  overflow: hidden;
`;

const Name = styled(TextWithTooltip).attrs(() => ({ className: 'name' }))`
  ${smallBody};
  ${({ size }) => SIZES[size]};
`;

const RatingRow = styled(FlexMiddle)`
  ${small};
  margin-top: 6px;
  > svg {
    margin-right: 5px;
    > path {
      fill: ${({ theme }) => theme.colors.success};
    }
  }
`;

const User = ({
  firstName,
  lastName,
  image,
  rating,
  isAdmin,
  showRating = false,
  size,
  onClick,
  className,
  renderBottom,
  withUnderline
}) => (
  <Wrapper onClick={onClick} className={className} $withUnderline={withUnderline}>
    <Avatar image={image} firstName={firstName} lastName={lastName} size={size} showStar={isAdmin} />
    <Content>
      <Name size={size}>{[firstName, lastName].join(' ')}</Name>

      {showRating && rating && (
        <RatingRow>
          <Star />
          {rating}
        </RatingRow>
      )}

      {renderBottom}
    </Content>
  </Wrapper>
);

export default User;
