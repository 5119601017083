import React, { useCallback } from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';
import { sortArray } from '@ubeya/shared/utils/sorting';
import { FlexColumn, FlexSpace, FlexMiddle } from '../Flex';
import { body } from '../Typography';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { SortableHandler } from '../Table';
import useFormFields from '../../hooks/useFormFields';
import { scrollbar } from '../../style';
import BaseCircleIcon from '../CircleIcon';
import Toggle from '../Toggle';
import { Tooltip } from '../Tooltip';

const Wrapper = styled(FlexColumn)`
  overflow: auto;
  ${scrollbar}
`;

const CircleIcon = styled(BaseCircleIcon)`
  visibility: hidden;
`;

const Item = styled(FlexMiddle)`
  margin-bottom: 10px;
  z-index: 5;

  &:hover ${CircleIcon} {
    visibility: visible;
  }
`;

const Name = styled(FlexSpace)`
  ${body}
  flex: 1;
  padding: 4px 13px;
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  border-radius: 5px;
  min-height: 46px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  > div:first-child > svg {
    margin-right: 16px;
    min-width: 20px;
  }
`;

const SortableItem = SortableElement(({ sortIndex, field, onEdit, onDelete, onToggleRequire, isDisabled }) => {
  const { t } = useTranslation();

  const { fieldsMap } = useFormFields();

  const Icon = fieldsMap[field.fieldTypeId]?.icon;

  const textName = field.metadata?.text?.replace?.(/\*|_|~|\+|>|`|#/g, '');
  return (
    <Item key={sortIndex}>
      {!isDisabled && <SortableHandler />}

      <Name onClick={isDisabled ? undefined : () => onEdit(field)}>
        <FlexMiddle>
          {Icon && <Icon />}
          <div>{field.name || `${textName?.slice?.(0, 20)}${textName.length > 20 ? '...' : ''}`}</div>
        </FlexMiddle>

        {!isDisabled && (
          <FlexMiddle>
            <CircleIcon icon={EditIcon} onClick={() => onEdit(field)} tooltip={t('edit')} />
            <CircleIcon
              icon={DeleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(sortIndex);
              }}
              tooltip={t('delete')}
            />
            {onToggleRequire && !fieldsMap[field.fieldTypeId]?.content && (
              <Tooltip body={t('required')}>
                <div>
                  <Toggle
                    value={field.isRequired}
                    onChange={(isRequired, e) => {
                      e.stopPropagation();
                      onToggleRequire(sortIndex, isRequired);
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </FlexMiddle>
        )}
      </Name>
    </Item>
  );
});

const SortableList = SortableContainer(({ items, onEdit, onDelete, onToggleRequire, isDisabled }) => (
  <div>
    {items.map?.((field, index) => (
      <SortableItem
        key={index}
        index={index}
        {...{ field, sortIndex: index, onEdit, onDelete, onToggleRequire, isDisabled }}
      />
    ))}
  </div>
));

const FieldsList = ({ fields, onEdit, onDelete, onToggleRequire, onSort, isDisabled, className }) => {
  const handleSort = useCallback(({ oldIndex, newIndex }) => onSort(sortArray(fields, oldIndex, newIndex)), [
    fields,
    onSort
  ]);

  if (!fields.length) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <SortableList
        {...{ items: fields, onEdit, onDelete, onToggleRequire, isDisabled }}
        onSortEnd={handleSort}
        useDragHandle
      />
    </Wrapper>
  );
};

export default FieldsList;
