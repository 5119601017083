import React from 'react';
import styled from 'styled-components';
import { FileUpload as BaseFileUpload, flexCenter } from '@ubeya/shared-web/components';

const FileUpload = styled(BaseFileUpload)`
  ${flexCenter};
  margin: 0 auto;
  font-size: 13px;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  min-height: 70px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.gray400};
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  text-align: center;
`;

const UploadField = ({ title }) => (
  <>
    {title}
    <FileUpload enabled={false}>Upload Here</FileUpload>
  </>
);

export default UploadField;
