const defaultColors = {
  primary: '#645df6',
  primaryLight: '#8278ff',
  primarySemiLight: '#6659FF',
  primaryDark: '#5f53ed',
  primaryGradient: 'linear-gradient(227deg, #6659ff, #c3beff 4%, #c3beff 4%)',
  secondary: '#3ad4e0',
  background: '#fafcff',
  backgroundLight: '#f4f7ff',
  backgroundDark: '#050849',
  surface: '#ffffff',
  success: '#44d7b6',
  successLight: '#44d7b65e',
  successLighten1: '#44d7b600',
  successDark: '#17ab89',
  failure: '#ff6163',
  failureLight: '#ff61630d',
  failureLighten1: '#eb3953',
  failureLighten2: '#eb39530d',
  warning: '#f7b500',
  info: '#0091ff',
  info2: '#fd61a9',
  booked: 'rgb(68, 215, 182,0.2)',
  outsource: '#ff8833',
  palette: [
    { light: '#fff2d9', dark: '#d28313', gradient: 'linear-gradient(to left, #ffce73 83%, #ffefd2 1%)' },
    { light: '#d5f5ff', dark: '#2290b0', gradient: 'linear-gradient(to right, #e0f9ff 0%, #a0d7e7 70%)' },
    { light: '#d4d0ff', dark: '#4825d2', gradient: 'linear-gradient(to right, #b091f4 -12%, #4825d2 112%)' },
    { light: '#ffd4e2', dark: '#ee3873', gradient: 'linear-gradient(to right, #ffdfeb -4%, #ff5d91 126%)' },
    { light: '#e5ebf2', dark: '#798a9e', gradient: 'linear-gradient(to right, #c5ccd4 -20%, #798a9e 109%)' },
    { light: '#ecd6ff', dark: '#9720e0', gradient: 'linear-gradient(to right, #e7b0f9 -20%, #9720e0 116%)' },
    { light: '#e7f0ff', dark: '#1c66f2', gradient: 'linear-gradient(to bottom, #e4f2ff 0%, #1c66f2)' },
    { light: '#ffe6d0', dark: '#df6a0c', gradient: 'linear-gradient(to left, #ee750f 93%, #ffeada 1%)' }
  ],
  reportPalette: [
    '#6659ff',
    '#94d8ec',
    '#ff78a3',
    '#ffce74',
    '#ffa55e',
    '#bc6cee',
    '#70a2ff',
    '#a7b2c0',
    '#bbf0ff',
    '#ad98fe',
    '#8085f0',
    '#ffbdd2'
  ],
  hourlyViewPalette: {
    available: '#6467ef',
    bookedConfirmed: '#46cc88',
    booked: 'rgba(0, 208, 129, 0.3)'
  },
  gray000: '#000000',
  gray100: '#424242',
  gray200: '#252525',
  gray300: '#858585',
  gray400: '#979797',
  gray500: '#BFBFBF',
  gray600: '#D7D9DE',
  gray700: '#D9D9D9',
  gray800: '#DCDCDC',
  gray900: '#E8E8E8',
  gray910: '#F2F3F5',
  gray920: '#FBFDFE',
  link: '#016dff',
  linkDark: '#0b73ff',
  error: '#e22212',
  inputShadow: '0 0 3px 1px rgba(78, 57, 255, 0.25);',

  whitesmoke: '#f5f5f5',
  primaryOrange: '#FF8833',
  primaryLight2: '#efefef'
};

const themeMaker = (themeColors = {}) => {
  const colors = { ...defaultColors, ...themeColors };

  return {
    colors,
    button: {
      primary: {
        normal: {
          background: colors.primary,
          color: colors.surface,
          border: 'transparent'
        },
        hover: {
          background: colors.primaryLight,
          color: colors.surface,
          border: 'transparent'
        },
        disabled: {
          background: colors.gray600,
          color: colors.surface,
          border: 'transparent'
        },
        pressed: {
          background: colors.primaryDark
        }
      },
      secondary: {
        normal: {
          background: colors.surface,
          color: colors.primary,
          border: colors.primary
        },
        hover: {
          background: colors.surface,
          color: colors.primary,
          border: colors.primary,
          shadow: colors.inputShadow
        },
        disabled: {
          background: colors.surface,
          color: colors.gray600,
          border: colors.gray600
        },
        pressed: {
          background: colors.surface
        }
      },
      tertiary: {
        normal: {
          background: colors.surface,
          color: colors.primary,
          border: 'transparent'
        },
        hover: {
          background: colors.background,
          color: colors.primary,
          border: 'transparent'
        },
        disabled: {
          background: colors.surface,
          color: colors.gray400,
          border: 'transparent'
        },
        pressed: {
          background: colors.background
        }
      },
      success: {
        normal: {
          background: colors.success,
          color: colors.surface,
          border: 'transparent'
        },
        hover: {
          background: colors.successDark,
          color: colors.surface,
          border: 'transparent'
        },
        disabled: {
          background: colors.successLight,
          color: colors.surface,
          border: 'transparent'
        },
        pressed: {
          background: colors.successDark
        }
      },
      link: {
        general: {
          borderWidth: '0 0 1px 0',
          padding: 0,
          borderRadius: 0
        },
        normal: {
          background: 'transparent',
          color: colors.primary,
          border: colors.primary
        },
        hover: {
          background: 'transparent',
          color: colors.primaryDark,
          border: colors.primaryDark
        },
        disabled: {
          background: 'transparent',
          color: colors.dark,
          border: colors.dark
        }
      }
    }
  };
};

export default themeMaker;
