import { useCallback, useEffect } from 'react';

const SITE_KEY = '6LelDjMaAAAAAORDlqHORcXkmPV62Gqms9087gh5';

const loadScriptByURL = (id, url) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }
};

const useRecaptcha = () => {
  useEffect(() => {
    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
  }, []);

  const getToken = useCallback(
    () =>
      new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(SITE_KEY, { action: 'submit' })
            .then((token) => resolve(token))
            .catch(() => reject());
        });
      }),
    []
  );

  return getToken;
};

export default useRecaptcha;
