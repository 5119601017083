import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';
import { smallSemiBold, Ellipsis } from '../Typography';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

const Wrapper = styled(FlexMiddle)`
  ${smallSemiBold};
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  padding: 7px 10px;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Value = styled(Ellipsis)`
  max-width: 150px;
  margin-left: 5px;
`;

const RemoveButton = styled(CloseIcon)`
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

const Chip = ({ label, value, onRemove }) => (
  <Wrapper>
    {label}: <Value>{value}</Value>
    {onRemove && <RemoveButton onClick={onRemove} />}
  </Wrapper>
);

export default Chip;
