import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Map, Marker, InfoWindow } from 'google-maps-react';
import { ExtraSmallSemiBold } from '../Typography';
import locationPurple from '../../assets/map-marker-1.svg';
import locationYellow from '../../assets/map-marker-2.svg';
import locationBlack from '../../assets/map-marker-3.svg';

const COLORS = window.google
  ? {
      purple: new window.google.maps.MarkerImage(locationPurple),
      yellow: new window.google.maps.MarkerImage(locationYellow),
      black: new window.google.maps.MarkerImage(locationBlack)
    }
  : { purple: '#000', yellow: '#000', black: '#000' };

const containerStyle = {
  position: 'relative'
};

const styles = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }]
  }
];

const LocationName = styled(ExtraSmallSemiBold)`
  color: #424242;
`;

const MapContainer = ({ width = '100%', height = '100%', markers = [] }) => {
  const { google } = window;
  const filteredMarkers = useMemo(() => markers.filter((marker) => marker?.lat && marker?.lng), [markers]);

  const adjustMap = useCallback(
    (mapProps, map) => {
      if (!google) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();

      filteredMarkers.forEach((marker) => {
        const { lat, lng } = marker;
        bounds.extend(new google.maps.LatLng(lat, lng));
      });

      map.fitBounds(bounds, 1000);
      map.setZoom(50);
      // google.maps.event.trigger(map, 'resize');
    },
    [filteredMarkers, google]
  );

  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const onMarkerClick = useCallback((props, marker) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }, []);

  const onMapClicked = useCallback(() => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  }, [showingInfoWindow]);

  const center = useMemo(
    () =>
      filteredMarkers && filteredMarkers.length > 0
        ? { lat: filteredMarkers[0].lat, lng: filteredMarkers[0].lng }
        : { lat: 19.95, lng: 30.33 },
    [filteredMarkers]
  );

  return (
    <Map
      google={google}
      zoom={14}
      containerStyle={{ ...containerStyle, width, height }}
      fullscreenControl={false}
      zoomControl={false}
      mapTypeControl={false}
      streetViewControl={false}
      // onReady={adjustMap}
      // onLoad={adjustMap}
      initialCenter={center}
      onClick={onMapClicked}
      styles={styles}>
      {filteredMarkers.map(({ lat, lng, color = 'purple', ...markerProps }, index) => (
        <Marker
          key={index}
          position={{ lat, lng }}
          onClick={(_, marker) => onMarkerClick(markerProps, marker)}
          icon={color && COLORS[color]}
        />
      ))}
      <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
        <LocationName>{selectedPlace.title}</LocationName>
      </InfoWindow>
    </Map>
  );
};

export default React.memo(MapContainer);
