import React from 'react';
import styled from 'styled-components';
import Container from '../Container';
import { FlexColumn, flexSpace, FlexMiddle, FlexSpace } from '../Flex';
import { Header2SemiBold } from '../Typography';

const Wrapper = styled(FlexColumn)`
  z-index: 1;
`;

const BaseHeader = styled(Container)`
  ${flexSpace};
  background: ${({ theme }) => theme.colors.surface};
  min-height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 30px;'}
`;

const FirstHeader = styled(BaseHeader)`
  z-index: ${({ marginBottom }) => (marginBottom ? 0 : 1)};
`;

const SecondHeader = styled(BaseHeader)`
  z-index: 0;
`;

const Title = styled(Header2SemiBold)`
  margin-right: 50px;
`;

const Combined = styled(FlexSpace)`
  flex: 1;
`;

const Header = ({
  title,
  leftSide,
  rightSide,
  children,
  className,
  marginBottom = true,
  showFirstHeader = true,
  firstHeaderContent,
  combined = false
}) => {
  const showSecondHeader = !combined && (leftSide || rightSide || children);

  const secondContent = (
    <>
      <FlexMiddle>{leftSide}</FlexMiddle>

      <FlexMiddle>{rightSide}</FlexMiddle>

      {children}
    </>
  );

  return (
    <Wrapper className={className}>
      {showFirstHeader && (
        <FirstHeader marginBottom={combined && marginBottom}>
          {title && <Title>{title}</Title>}

          {firstHeaderContent}

          {combined && <Combined>{secondContent}</Combined>}
        </FirstHeader>
      )}

      {showSecondHeader && <SecondHeader marginBottom={marginBottom}>{secondContent}</SecondHeader>}
    </Wrapper>
  );
};

export default Header;
