import React from 'react';
import { useTranslation } from 'react-i18next';
import { required } from '@ubeya/shared/utils/form';
import InputField from '../../FormFields/InputField';

const CheckboxFieldForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        validate={required}
        autoFocus
        required
        className="full"
      />
      <InputField name="yesText" title={t('yesText')} />
      <InputField name="noText" title={t('noText')} />
    </>
  );
};

export default CheckboxFieldForm;
