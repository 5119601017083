import { css } from 'styled-components';

const scrollbar = css`
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border: solid 1px ${({ theme }) => theme.colors.gray900};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray500};
  }
`;

export const scrollbar2 = css`
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

export default scrollbar;
