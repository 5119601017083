import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Trans } from 'react-i18next';
import { Form } from 'react-final-form';
import usePrevious from '@ubeya/shared/hooks/usePrevious';
import { flexColumn, FlexMiddle, FlexColumn } from '../Flex';
import Button from '../Button';
import media from '../../style/media';
import BaseWizardBar from './WizardBar';

const Container = styled.form`
  ${flexColumn};
  flex: 1;
`;

const Buttons = styled(FlexMiddle)`
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.gray900};
  padding: 10px;

  > button {
    margin-left: 10px;
  }
`;

const WizardBar = styled(BaseWizardBar)`
  position: absolute;
  left: 42px;
  right: 42px;
  ${media.mobile`justify-content: center;`}
`;

const slideRight = keyframes`
  0% {transform: translateX(100%); opacity: 0;}
  100% {transform: translateX(0); opacity: 1;}
`;

const slideLeft = keyframes`
  0% {transform: translateX(-100%); opacity: 0;}
  100% {transform: translateX(0); opacity: 1;}
`;

const PageWrapper = styled(FlexColumn)`
  overflow: hidden;
  flex: 1;
  ${({ $active }) =>
    $active &&
    css`
      animation: ${({ $isRight }) => (!$isRight ? slideRight : slideLeft)} ease-out 300ms;
    `};
`;

const Wizard = ({
  initialValues,
  mutators,
  children,
  onSubmit,
  onPageChange,
  showPrevious = true,
  doneText = 'done',
  disabledOnInvalid = true,
  renderExtraDoneButton,
  renderLeft,
  buttonSubmitted
}) => {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialValues);
  const [dirtyFields, setDirtyFields] = useState({});

  const filteredChildren = useMemo(() => React.Children.toArray(children).filter(Boolean), [children]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleNext = useCallback(
    (values, form) => {
      const newPage = Math.min(page + 1, filteredChildren.length - 1);
      setPage(newPage);
      setValues(values);
      onPageChange?.(newPage, values);
      setDirtyFields((prev) => ({ ...prev, ...form.getState().dirtyFields }));
    },
    [filteredChildren.length, onPageChange, page]
  );

  const handlePrevious = useCallback(() => {
    const newPage = Math.max(page - 1, 0);
    setPage(newPage);
    onPageChange?.(newPage);
  }, [onPageChange, page]);

  const validate = useCallback(
    (values) => {
      const activePage = React.Children.toArray(filteredChildren)[page];
      return activePage.props.validate ? activePage.props.validate(values) : {};
    },
    [filteredChildren, page]
  );

  const handleSubmit = useCallback(
    (values, form) => {
      const isLastPage = page === React.Children.count(filteredChildren) - 1;
      if (isLastPage) {
        return onSubmit(values, { ...dirtyFields, ...form.getState().dirtyFields }, form);
      }
      return handleNext(values, form);
    },
    [page, filteredChildren, handleNext, onSubmit, dirtyFields]
  );

  const activePage = React.Children.toArray(filteredChildren)[page];
  const isLastPage = page === React.Children.count(filteredChildren) - 1;

  const lastActivePage = usePrevious(page);

  return (
    <Form initialValues={values} validate={validate} mutators={mutators} onSubmit={handleSubmit}>
      {({ handleSubmit, submitting, valid, values }) => (
        <Container
          onSubmit={(e) => {
            if (buttonSubmitted) {
              buttonSubmitted.current = 'main';
            }
            handleSubmit(e);
          }}>
          {React.cloneElement(activePage, {
            active: page !== 0 || lastActivePage !== 0,
            isRight: lastActivePage > page
          })}

          <Buttons className="buttons">
            {renderLeft?.({ page, totalPages: React.Children.count(filteredChildren) })}

            <WizardBar page={page} stepsCount={filteredChildren.length} />

            {page > 0 && showPrevious && (
              <Button secondary type="button" onClick={handlePrevious}>
                <Trans>previous</Trans>
              </Button>
            )}

            {!isLastPage && (
              <Button primary type="submit" disabled={!valid || values.isLoading} id="next-step">
                <Trans>next</Trans>
              </Button>
            )}

            {isLastPage && (
              <>
                <Button
                  primary={!renderExtraDoneButton}
                  secondary={renderExtraDoneButton}
                  type="submit"
                  disabled={submitting || (disabledOnInvalid ? !valid : false)}
                  isLoading={submitting && (!buttonSubmitted || buttonSubmitted?.current === 'main')}>
                  <Trans>{doneText}</Trans>
                </Button>

                {renderExtraDoneButton?.({
                  disabled: submitting || (disabledOnInvalid ? !valid : false),
                  isLoading: submitting,
                  onClick: (e) => {
                    e.preventDefault();
                    if (buttonSubmitted) {
                      buttonSubmitted.current = 'external';
                    }
                    handleSubmit();
                  }
                })}
              </>
            )}
          </Buttons>
        </Container>
      )}
    </Form>
  );
};

Wizard.Page = ({ active, isRight, children }) => (
  <PageWrapper $active={active} $isRight={isRight}>
    {children}
  </PageWrapper>
);

export default Wizard;
