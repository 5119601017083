import React, { useCallback, useContext } from 'react';
import useSafeState from '@ubeya/shared/hooks/useSafeState';
import DropDownContext from '../../contexts/DropDownContext';
import ConfirmationModal from '../ConfirmationModal';

const PromptClick = ({
  onClick,
  children,
  title = 'areYouSure',
  body = 'areYouSure',
  renderBody,
  confirmText,
  disabledConfirm,
  showCancelButton = true,
  usePortal,
  stopPropagation = false,
  enable = true,
  isOpen: defaultIsOpen = false,
  onClose,
  isWaitingForResponse = true
}) => {
  const { closeMenu } = useContext(DropDownContext);
  const [isOpen, setIsOpen] = useSafeState(defaultIsOpen);
  const [isLoading, setIsLoading] = useSafeState(false);

  const handleClose = useCallback(() => {
    if (defaultIsOpen) {
      onClose();
    } else {
      setIsOpen(false);
      closeMenu?.();
      onClose?.();
    }
  }, [closeMenu, defaultIsOpen, onClose, setIsOpen]);

  if (!enable) {
    return React.cloneElement(React.Children.only(children), { onClick });
  }

  if (!isOpen) {
    return React.cloneElement(React.Children.only(children), {
      onClick: (e) => {
        if (stopPropagation) e.stopPropagation();
        setIsOpen(true);
      }
    });
  }
  return (
    <>
      {children}
      <ConfirmationModal
        onClose={handleClose}
        title={title}
        body={renderBody ? undefined : body}
        onConfirm={async (e) => {
          if (isWaitingForResponse) {
            setIsLoading(true);
            await onClick(e);
            setIsLoading(false);
          } else {
            onClick(e);
          }
          handleClose();
        }}
        confirmText={confirmText}
        loadingConfirm={isLoading}
        disabledConfirm={disabledConfirm}
        showCancelButton={showCancelButton}
        usePortal={usePortal}>
        {renderBody}
      </ConfirmationModal>
    </>
  );
};

export default PromptClick;
