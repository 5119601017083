import React, { useMemo } from 'react';
import flattenData from '../../../utils/table';

const RawTable = ({ data, innerScroll, extendedRows, renderRow }) => {
  // flat data with sub rows
  const flatData = useMemo(() => flattenData(data, extendedRows), [data, extendedRows]);

  return (
    <div className="grid" ref={innerScroll}>
      {flatData.map((_, index) => renderRow(index, flatData[index]))}
    </div>
  );
};

export default React.memo(RawTable);
