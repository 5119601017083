import React, { useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AutoSizer } from 'react-virtualized';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FlexCenter, flexMiddle } from '../Flex';
import CircleIcon from '../CircleIcon';
import useVisibleColumns from '../../hooks/Table/useVisibleColumns';
import useCheckboxColumn from '../../hooks/Table/useCheckboxColumn';
import useScrollToTop from '../../hooks/Table/useScrollToTop';
import useExtendedRow from '../../hooks/Table/useExtendedRow';
import { ReactComponent as EditColumn } from '../../assets/edit.svg';
import { Loader } from '../Loader';
import media from '../../style/media';
import Styles from './_internal/Styles';
import ColumnsMenu from './_internal/ColumnsMenu';
import HeaderCell from './_internal/HeaderCell';
import VirtualizedTable from './_internal/VirtualizedTable';
import RawTable from './_internal/RawTable';
import RowCell from './_internal/RowCell';

const ROW_HEIGHT = 63;

const LoaderWrapper = styled(FlexCenter)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const EditColumnButton = styled(CircleIcon)`
  position: absolute;
  right: 25px;
  display: none;
  cursor: pointer;
  ${media.tablet`display: block;`}
`;

const Row = styled.div`
  ${flexMiddle};
  height: ${({ rowHeight }) => rowHeight}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
  background: ${({ theme }) => theme.colors.surface};

  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &.clickable {
    cursor: pointer;
  }

  .cell.on-hover {
    visibility: hidden;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray920};
    .cell.on-hover {
      visibility: visible;
    }
  }
`;

const HeaderRow = styled(Row)`
  padding-right: 8px;
  background: ${({ theme }) => theme.colors.surface};
  position: relative;

  &:hover {
    background: transparent;
  }
`;

const Table = ({
  columns: allColumns,
  data,
  isLoading,
  autoHeight,
  virtualized = false,
  sortable = false,
  sortBy,
  sortDirection,
  setSort,
  noDataRenderer,
  rowStyle,
  className,
  columnsMenuTitle,
  showColumnsMenu,
  scrollToTopOnDataChange = false,
  onRowClick,
  defaultExtendsRows,
  headless = false,
  checkboxData,
  storageName,
  shadow = true,
  reorder = false,
  width: tableWidth,
  ...listProps
}) => {
  const innerScroll = useRef();
  const { isMenuOpen, setIsMenuOpen, visibleColumns, updateVisibleColumns, resetToDefault } = useVisibleColumns(
    allColumns,
    storageName
  );
  const columns = useCheckboxColumn({ columns: visibleColumns, checkboxData });
  const { extendedRows, handleToggleRow } = useExtendedRow(defaultExtendsRows);
  useScrollToTop(scrollToTopOnDataChange && innerScroll, [data]);

  const headers = useMemo(
    () =>
      columns.map((column) => (
        <HeaderCell
          {...{
            key: column.dataKey,
            column,
            sortable,
            sortBy,
            sortDirection,
            setSort,
            hasCheckboxColumn: !!checkboxData
          }}
        />
      )),
    [checkboxData, setSort, sortBy, sortDirection, sortable, columns]
  );

  const renderRow = useCallback(
    (index, row, style, stickyDrawer = false) => {
      const subRow = !row.subRows;
      const rowClassName = `row ${subRow ? 'sub-row' : 'header-row'}`;
      const stickyClassName = stickyDrawer ? 'sticky' : '';
      const rowCustomClassName = rowStyle ? rowStyle(row) : '';
      const clickableRowClassName = onRowClick ? 'clickable' : '';
      const isExpended = extendedRows[row.realIndex];
      const isExpendedClassName = isExpended ? 'open' : 'close';
      const toggleRow = () => handleToggleRow(row.realIndex);

      const Container = reorder ? SortableElement(Row) : Row;

      return (
        <Container
          key={index}
          index={index}
          style={style}
          rowHeight={ROW_HEIGHT}
          className={`${rowClassName} ${stickyClassName} ${rowCustomClassName} ${clickableRowClassName} ${isExpendedClassName}`}
          onClick={(e) => onRowClick?.({ event: e, rowData: row, toggleRow })}>
          {columns.map((column) => (
            <RowCell
              {...{
                key: column.dataKey,
                index,
                column,
                row,
                isExpended,
                toggleRow,
                subRow,
                hasCheckboxColumn: !!checkboxData
              }}
            />
          ))}
        </Container>
      );
    },
    [rowStyle, onRowClick, extendedRows, reorder, columns, handleToggleRow, checkboxData]
  );

  const Container = useMemo(() => (autoHeight ? ({ children }) => children({ height: 0 }) : AutoSizer), [autoHeight]);

  return (
    <Container>
      {({ height, width }) => {
        const actualHeight = height - (headless ? 0 : ROW_HEIGHT);

        return (
          <Styles
            autoHeight={autoHeight}
            height={actualHeight}
            width={tableWidth || width}
            rowHeight={ROW_HEIGHT}
            isLoading={isLoading}
            shadow={shadow}
            className={className}>
            {isLoading && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}

            {isMenuOpen && (
              <ColumnsMenu
                columnsMenuTitle={columnsMenuTitle}
                allColumns={allColumns}
                visibleColumns={visibleColumns}
                onClose={() => setIsMenuOpen(false)}
                updateVisibleColumn={updateVisibleColumns}
                resetToDefault={resetToDefault}
              />
            )}

            {!headless && (
              <HeaderRow className="header-row">
                {headers}
                {showColumnsMenu && (
                  <EditColumnButton icon={EditColumn} onClick={() => setIsMenuOpen(true)} tooltip={columnsMenuTitle} />
                )}
              </HeaderRow>
            )}

            {!isLoading && data.length === 0 ? (
              <div className="grid" ref={innerScroll}>
                {noDataRenderer?.()}
              </div>
            ) : virtualized ? (
              <VirtualizedTable
                {...{
                  height: actualHeight,
                  width: tableWidth || width - (shadow ? 0 : 2),
                  data,
                  extendedRows,
                  rowHeight: ROW_HEIGHT,
                  innerScroll,
                  renderRow,
                  listProps
                }}
              />
            ) : (
              <RawTable {...{ data, innerScroll, extendedRows, renderRow }} />
            )}
          </Styles>
        );
      }}
    </Container>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]).isRequired,
      dataKey: PropTypes.string.isRequired,
      Aggregate: PropTypes.func,
      Cell: PropTypes.func,
      columnData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      formatter: PropTypes.func,
      width: PropTypes.number,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
      sortable: PropTypes.bool,
      withTooltip: PropTypes.bool,
      visible: PropTypes.bool,
      mustStay: PropTypes.bool,
      category: PropTypes.string,
      showOnHover: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    })
  ),
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  sortable: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  setSort: PropTypes.func,
  noDataRenderer: PropTypes.func,
  rowStyle: PropTypes.func,
  autoHeight: PropTypes.bool,
  className: PropTypes.string,
  columnsMenuTitle: PropTypes.string,
  showColumnsMenu: PropTypes.bool
};

export default SortableContainer(Table);
