import React from 'react';
import styled, { css } from 'styled-components';
import useMenu from '../../hooks/useMenu';
import { DropDownContextProvider } from '../../contexts/DropDownContext';
import { FlexMiddle, FlexColumn } from '../Flex';
import { card } from '../Card';
import { ReactComponent as BaseArrow } from '../../assets/arrow.svg';
import scrollbar from '../../style/scrollbar';
import DropDownItem from './DropDownItem';
import DropDownSeparator from './DropDownSeparator';

const Wrapper = styled.div`
  position: relative;

  &&& {
    ${({ $isOpen }) => $isOpen && 'display:block;'}
  }
`;

const Clickable = styled(FlexMiddle)`
  cursor: pointer;
`;

const Arrow = styled(BaseArrow)`
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 90)}deg);
  width: 12px;
  margin-left: 7px;
  > path {
    fill: ${({ theme }) => theme.colors.surface};
  }
`;

const Options = styled(FlexColumn)`
  ${card};
  position: fixed;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  z-index: 5;
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}

  ${({ menuOffset: { top, right } = {} }) =>
    css`
      ${top !== undefined && `top: ${top}px;`}
      ${right !== undefined && `right: ${right}px;`}
    `}

  min-width: ${({ menuMinWidth }) => menuMinWidth}px;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow: auto;
      ${scrollbar}
    `}
`;

const DropDown = ({
  className,
  children,
  renderValue,
  menuOffset,
  menuMinWidth = 90,
  showArrow = true,
  clickableContainer: ClickableContainer,
  scrollingContainer,
  usePortal,
  openFromRight = true,
  maxHeight
}) => {
  const { Portal, wrapperRef, isOpen, setToggle, setClose, style, menuRef } = useMenu({
    usePortal,
    openFromRight,
    menuOffset,
    scrollingContainer
  });

  const Container = ClickableContainer ?? Clickable;
  const filteredChildren = React.Children.toArray(children).filter(
    (child) => child.props.isVisible === undefined || child.props.isVisible
  );

  return (
    <DropDownContextProvider value={{ isOpen, closeMenu: setClose }}>
      <Wrapper className={className} ref={wrapperRef} $isOpen={isOpen}>
        <Container onClick={setToggle} isActive={isOpen}>
          {React.isValidElement(renderValue) ? React.cloneElement(renderValue, { isActive: isOpen }) : renderValue}
          {showArrow && <Arrow $isOpen={isOpen} />}
        </Container>

        {isOpen && (
          <Portal>
            <Options ref={menuRef} innerStyle={style} menuMinWidth={menuMinWidth} maxHeight={maxHeight}>
              {filteredChildren}
            </Options>
          </Portal>
        )}
      </Wrapper>
    </DropDownContextProvider>
  );
};

DropDown.Item = DropDownItem;
DropDown.Separator = DropDownSeparator;

export default DropDown;
