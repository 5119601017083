import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';
import { header3 } from '../Typography';

const Wrapper = styled(FlexMiddle)`
  ${header3};
  input[type='radio'] + label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;

    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 7px;
      left: 0;
      border: 1px solid ${({ theme }) => theme.colors.gray500};
      background-color: #fff;
      border-radius: 50%;
    }
  }

  &:hover input[type='radio'] + label:before {
    border: 5px solid ${({ theme }) => theme.colors.successLight};
  }

  input[type='radio']:disabled + label {
    cursor: no-drop;
  }

  input[type='radio'] {
    display: none !important;

    &:checked + label {
      &:before {
        border: 5px solid ${({ theme }) => theme.colors.success};
      }
    }
  }
`;

const RadioButton = ({ id, value, onChange, content, children, className, disabled }) => (
  <Wrapper className={className}>
    <input type="radio" id={id} checked={value === id} onChange={() => !disabled && onChange(id)} disabled={disabled} />
    <label htmlFor={id}>{content || children}</label>
  </Wrapper>
);

export default RadioButton;
