import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';
import { Loader as BaseLoader } from '../Loader';
import { TextInput as BaseTextInput } from '../Input';

const TextInput = styled(BaseTextInput)`
  > input {
    border-color: transparent;
    padding: 10px 5px;
  }
`;

const Wrapper = styled(FlexMiddle)`
  position: relative;
  input {
    background-color: ${({ color }) => color || 'auto'};
  }

  &:hover ${TextInput} > input {
    border-color: ${({ theme }) => theme.colors.gray700};
    background-color: ${({ theme }) => theme.colors.surface};
  }
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  left: 0;
  right: 0;
`;

const EditableInput = ({
  isLoading,
  value,
  onChange,
  onSubmitChange,
  prefix,
  color,
  readonly = false,
  placeholder = ''
}) => (
  <Wrapper color={color}>
    {prefix && <span>{prefix}</span>}
    {readonly && <span>{value}</span>}

    {!readonly && (
      <TextInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={(e) => e.which === 13 && onSubmitChange()}
        onBlur={onSubmitChange}
      />
    )}

    {isLoading && <Loader size="xsmall" />}
  </Wrapper>
);

export default EditableInput;
