import React from 'react';
import { useTranslation } from 'react-i18next';
import { required } from '@ubeya/shared/utils/form';
import InputField from '../../FormFields/InputField';
import NumberField from '../../FormFields/NumberField';

const RatingFieldForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        className="full"
        validate={required}
        required
        autoFocus
      />
      <NumberField name="stars" title={t('stars')} />
    </>
  );
};

export default RatingFieldForm;
