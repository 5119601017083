import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { body, ellipsis } from '../../Typography';
import { ReactComponent as BaseClearIcon } from '../../../assets/close.svg';

const Wrapper = styled.div.attrs(() => ({ className: 'select-container' }))`
  position: relative;
  flex: 1;
  align-self: flex-start;
  ${({ $isSearchable }) => !$isSearchable && 'padding-right: 40px;'}

  &:hover {
    .clear-icon {
      visibility: visible;
    }
`;

const Label = styled.label.attrs(() => ({ className: 'select-label' }))`
  ${body};
  ${ellipsis}
  position: absolute;
  top: 0;
  bottom: 0;
  max-width: 85%;
  display: inline-block;
  line-height: 25px;
  pointer-events: none;
  padding: 10px;

  ${({ theme, $isPlaceholder }) => $isPlaceholder && `color:${theme.colors.gray400}`};
  ${({ theme, $isDisabled }) => $isDisabled && `color:${theme.colors.gray800}`};
  ${({ $isSearchable }) =>
    !$isSearchable &&
    css`
      pointer-events: all;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
    `}
`;

const Input = styled.input.attrs(() => ({ className: 'select-input' }))`
  ${body};
  border-radius: 5px;
  width: 100%;
  outline: 0;
  padding: 10px;
  background: transparent;
  border: none;
  min-height: 46px;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
  }
`;

const ClearIcon = styled(BaseClearIcon).attrs(() => ({ className: 'clear-icon' }))`
  position: absolute;
  right: 31px;
  top: 8px;
  width: 11px;
  height: 19px;
  padding: 5px 6px;
  border-left: 1px solid ${({ theme }) => theme.colors.gray800};
  cursor: pointer;
  ${({ visible }) => !visible && `visibility: hidden`}
`;

const Value = ({
  isRaw,
  value: selectedValue,
  options,
  formatValue,
  onClick,
  placeholder,
  search,
  setSearch,
  isDisabled,
  name,
  onBlur,
  searchable,
  valuesComparator,
  isOpen,
  clearable,
  onClear,
  handleSelect,
  ...restProps
}) => {
  const displayValue = useMemo(() => {
    const selectedOption = options.find((option) => valuesComparator(option.value, selectedValue));
    if (!selectedOption) {
      return undefined;
    }
    return formatValue?.({ value: selectedValue, displayValue: selectedOption.label }) || selectedOption.label;
  }, [formatValue, options, selectedValue, valuesComparator]);
  const value = isRaw ? selectedValue : displayValue;

  return (
    <Wrapper onClick={isDisabled ? undefined : onClick} data-is-empty={!value} $isSearchable={searchable}>
      {!search && (
        <Label data-is-empty={!value} $isPlaceholder={!value} $isDisabled={isDisabled} $isSearchable={searchable}>
          {value || placeholder}
        </Label>
      )}
      {searchable && (
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          disabled={isDisabled}
          autoComplete="new-password"
          onBlur={(e) => {
            onBlur?.(e);
            !isOpen && setSearch('');
          }}
          {...restProps}
        />
      )}

      {!!value && clearable && !isDisabled && (
        <ClearIcon
          visible={restProps?.visible}
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        />
      )}
    </Wrapper>
  );
};

export default Value;
