import React from 'react';
import styled from 'styled-components';
import { DynamicField as BaseDynamicField } from '../FormFields';
import AppPreview from '../AppPreview';

const DynamicField = styled(BaseDynamicField)`
  margin-bottom: 15px;
`;

const FieldsPreview = ({ fields, children, className }) => (
  <AppPreview className={className}>
    {fields?.map((field, index) => (
      <DynamicField key={index} title={field.name?.toString?.()} {...field} name={`field-${index}`} />
    ))}
    {children}
  </AppPreview>
);

export default FieldsPreview;
