import React, { useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { convertTimeStringToMoment } from '@ubeya/shared/utils/time';
import { API_TIME_FORMAT, API_DATE_TIME_FORMAT, API_DATE_FORMAT } from '@ubeya/shared/constants';
import ConfigContext from '../../contexts/ConfigContext';
import Select from '../Select';

const CREATE_OPTIONS = (interval) =>
  [...Array(24 * 4)].map((_, index) =>
    moment()
      .startOf('day')
      .add(index * interval, 'minutes')
  );

const OPTIONS = CREATE_OPTIONS(15);

const MULTIPLE_OPTIONS = [...OPTIONS, ...OPTIONS];

const convertToMoment = (value, timeOnly) => {
  if (!value) {
    return;
  }

  if (timeOnly) {
    return convertTimeStringToMoment(value);
  }

  return moment(value, API_DATE_TIME_FORMAT);
};

// value should be YYYY-MM-DD HH:mm:ss or HH:mm:ss
const TimePicker = ({
  title,
  required,
  className,
  value,
  onChange,
  startTime,
  defaultValue,
  timeOnly,
  usePortal,
  small,
  isDisabled,
  placeholder = '__:__',
  clearable,
  onOutsideClickSave,
  onBlur,
  interval,
  ...restSelectProps
}) => {
  const { t } = useTranslation();
  const { timeFormat } = useContext(ConfigContext);

  const timeMoment = useMemo(() => convertToMoment(value, timeOnly) || moment(), [timeOnly, value]);
  const timeString = useMemo(() => (value ? timeMoment.format(timeFormat) : ''), [timeFormat, timeMoment, value]);

  const defaultString = useMemo(() => defaultValue && convertToMoment(defaultValue, timeOnly).format(timeFormat), [
    defaultValue,
    timeFormat,
    timeOnly
  ]);

  const startMoment = useMemo(() => convertToMoment(startTime, timeOnly), [startTime, timeOnly]);

  const options = useMemo(() => (!interval ? OPTIONS : CREATE_OPTIONS(interval)), [interval]);
  const multipleOptions = useMemo(() => (!interval ? MULTIPLE_OPTIONS : [...options, ...options]), [interval, options]);

  const handleSelect = useCallback(
    (newTime) => {
      if (!newTime) {
        onChange(null);
      } else {
        const date = timeMoment.format(API_DATE_FORMAT);
        const newDate = moment(`${date} ${newTime}`, `${API_DATE_FORMAT} ${timeFormat}`);

        onChange(
          newDate.isValid() ? (timeOnly ? newDate.format(API_TIME_FORMAT) : newDate.format(API_DATE_TIME_FORMAT)) : null
        );
      }
    },
    [onChange, timeMoment, timeFormat, timeOnly]
  );

  const formatOption = useCallback(
    (value) => {
      if (!value) {
        return { label: '__:__', value: '' };
      }

      const label = value.format(timeFormat);

      if (!startMoment) {
        return { label, value: label };
      }

      if (timeOnly) {
        value = convertTimeStringToMoment(label);
      } else {
        const date = startMoment.format(API_DATE_FORMAT);
        value = moment(`${date} ${value.format(timeFormat)}`, `${API_DATE_FORMAT} ${timeFormat}`);
      }

      let totalMinutes = value.diff(startMoment, 'minutes');
      totalMinutes = totalMinutes < 0 ? totalMinutes + 60 * 24 : totalMinutes;

      const hours = parseInt(totalMinutes / 60, 10);
      const minutes = totalMinutes % 60;

      return {
        label: `${label} ${hours} ${t('hours')}${minutes > 0 ? ` ${minutes} ${t('minutes')}` : ''}`,
        value: label
      };
    },
    [startMoment, t, timeFormat, timeOnly]
  );

  const filterFunc = useCallback(
    (value, search) => {
      const label = value?.format?.(timeFormat) || '';
      return label?.replace(/^0/, '')?.startsWith?.(search.replace(/^0/, ''));
    },
    [timeFormat]
  );

  return (
    <Select
      isRaw
      title={title}
      required={required}
      className={className}
      placeholder={placeholder}
      value={timeString}
      onChange={handleSelect}
      formatOption={formatOption}
      onBlur={onOutsideClickSave ? (e) => e.target.value && handleSelect(e.target.value) : onBlur}
      filterFunc={filterFunc}
      defaultValue={defaultString}
      options={startTime ? multipleOptions : options}
      usePortal={usePortal}
      small={small}
      isDisabled={isDisabled}
      menuClassName={startMoment ? 'time-with-duration' : 'time'}
      clearable={clearable}
      creatable
      {...restSelectProps}
    />
  );
};

export default TimePicker;
