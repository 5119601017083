import React, { createContext, useMemo, useState } from 'react';

const LanguageContext = createContext({ language: 'en' });

export default LanguageContext;

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const value = useMemo(() => ({ language, setLanguage }), [language, setLanguage]);

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
