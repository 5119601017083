import React, { useMemo } from 'react';
import useFormFields from '../../hooks/useFormFields';

const DynamicField = ({ name, title, type = 'text', fieldTypeId, options = [], allowMultiple, ...restProps }) => {
  const formattedOptions = useMemo(
    () => options.map(({ id, value, label, title }) => ({ label: title || label, value: id || value })),
    [options]
  );

  const { fieldsMap, fieldsBySlug } = useFormFields();
  const selectedComponent = fieldTypeId ? fieldsMap[fieldTypeId] : fieldsBySlug[type];

  const { Component } = selectedComponent;

  return (
    <Component
      title={title}
      name={name}
      options={formattedOptions}
      type={type}
      usePortal
      isMulti={allowMultiple}
      {...restProps}
    />
  );
};

export default DynamicField;
