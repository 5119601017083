import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { counterFormatter } from '../../utils/filters';
import { ReactComponent as FilterIcon } from '../../assets/filter.svg';
import { FlexCenter } from '../Flex';
import { extraSmall } from '../Typography';
import { Tooltip } from '../Tooltip';
import DropDown from '../DropDown';
import CircleIcon from '../CircleIcon';
import FilterOptions from './FilterOptions';

const ValueWrapper = styled.div`
  position: relative;
`;

const Counter = styled(FlexCenter)`
  ${extraSmall};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 -2px 10px 0 ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 15px;
  height: 15px;
`;

const Value = ({ isActive, filters }) => {
  const { t } = useTranslation();
  const counter = useMemo(
    () =>
      Object.values(filters).reduce(
        (sum, array) => sum + array.reduce((sum, { value }) => sum + (counterFormatter(value) ? 1 : 0), 0),
        0
      ),
    [filters]
  );

  return (
    <Tooltip body={t('filter')}>
      <ValueWrapper>
        <CircleIcon isActive={isActive} icon={FilterIcon} />
        {counter > 0 && <Counter>{counter}</Counter>}
      </ValueWrapper>
    </Tooltip>
  );
};

const Filter = ({ selectedKey, filters = {}, setFilters, disabledFilters = [], usePortal }) => (
  <DropDown
    showArrow={false}
    renderValue={<Value filters={filters} />}
    menuMinWidth={210}
    maxHeight={400}
    menuOffset={{ top: 10 }}
    usePortal={usePortal}>
    <FilterOptions
      selectedKey={selectedKey}
      filters={filters}
      setFilters={setFilters}
      disabledFilters={disabledFilters}
    />
  </DropDown>
);

export default Filter;
