import React, { memo, forwardRef } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import Cell from './Cell';
import { RowWrapper, ActionColumn } from './styles';

const Row = (
  {
    rowIdx,
    row,
    viewportColumns,
    top,
    rowStyle,
    CellRenderer,
    onRowClick,
    toggleRow,
    actionRenderer,
    gridRef,
    reorder,
    isSticky
  },
  ref
) => (
  <RowWrapper
    ref={ref}
    row={row}
    isSticky={isSticky}
    className={rowStyle?.(row)}
    {...(reorder ? { $top: top } : { style: { top } })}>
    {viewportColumns.map((column) => (
      <Cell
        key={column.dataKey}
        rowIdx={rowIdx}
        column={column}
        row={row}
        CellRenderer={CellRenderer}
        onRowClick={onRowClick}
        toggleRow={toggleRow}
      />
    ))}

    {actionRenderer && <ActionColumn>{actionRenderer({ rowData: row, gridRef })}</ActionColumn>}
  </RowWrapper>
);

export default memo(SortableElement(forwardRef(Row)));
