import { atom } from 'recoil';
import { isMobile } from 'react-device-detect';

export const selectedBranches = atom({ key: 'selectedBranches', default: null });

export const isChatOpen = atom({ key: 'isChatOpen', default: false });

export const selectedBranch = atom({ key: 'selectedBranch', default: null });

export const viewAsClient = atom({ key: 'viewAsClient', default: false });

export const toaster = atom({ key: 'toaster', default: [] });

export const confetti = atom({ key: 'confetti', default: false });

export const selectedEmployee = atom({ key: 'selectedEmployee', default: null });

export const chatCount = atom({ key: 'chatCount', default: null });

export const openViaMobile = atom({ key: 'isMobile', default: isMobile });

export const fileUpload = atom({ key: 'fileUpload', default: false });

export const chatIframeRef = atom({ key: 'chatIframeRef', default: null });
