import React, { useCallback, useMemo } from 'react';
import { defaultCellRangeRenderer } from 'react-virtualized';
import { List } from '../../List';
import useStickyDrawer from '../../../hooks/Table/useStickyDrawer';
import flattenData from '../../../utils/table';

const VirtualizedTable = ({ height, width, data, extendedRows, rowHeight, innerScroll, renderRow, listProps }) => {
  // flat data with sub rows
  const flatData = useMemo(() => flattenData(data, extendedRows), [data, extendedRows]);

  const { stickyDrawer, setVisibleIndex } = useStickyDrawer({ data, flatData, extendedRows, renderRow });

  const onRowsRendered = useCallback(
    (props) => {
      setVisibleIndex(props.startIndex);
      listProps?.onRowsRendered?.(props);
    },
    [listProps, setVisibleIndex]
  );

  const rowRenderer = useCallback(({ index, style }) => renderRow(index, flatData[index], style), [
    flatData,
    renderRow
  ]);

  const cellRangeRenderer = useCallback(
    (props) => {
      const children = defaultCellRangeRenderer(props);
      children.unshift(stickyDrawer);
      return children;
    },
    [stickyDrawer]
  );

  return (
    <List
      height={height}
      width={width}
      rowCount={flatData.length}
      rowHeight={rowHeight}
      innerRef={innerScroll}
      className="grid"
      {...listProps}
      onRowsRendered={onRowsRendered}
      cellRangeRenderer={cellRangeRenderer}
      rowRenderer={rowRenderer}
    />
  );
};

export default React.memo(VirtualizedTable);
