import React from 'react';
import { useTranslation } from 'react-i18next';
import { required } from '@ubeya/shared/utils/form';
import InputField from '../../FormFields/InputField';

const SimpleFieldForm = () => {
  const { t } = useTranslation();

  return (
    <InputField
      name="name"
      title={t('name')}
      placeholder={t('selectNameToField')}
      validate={required}
      required
      autoFocus
      className="full"
    />
  );
};

export default SimpleFieldForm;
