import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexSpace } from '../Flex';
import CircleIcon from '../CircleIcon';
import { smallBodySemiBold } from '../Typography';
import { ReactComponent as BaseArrow } from '../../assets/arrow.svg';

const BoxWrapper = styled(FlexSpace)`
  ${smallBodySemiBold};
  border: 1px solid ${({ theme }) => theme.colors.gray900};
  background: ${({ theme }) => theme.colors.background};
  padding: 4px 20px;
  cursor: pointer;
  &:not(:first-child) {
    border-top: none;
  }
`;

const BoxContent = styled(FlexColumn)`
  border-left: 1px solid ${({ theme }) => theme.colors.gray900};
  border-right: 1px solid ${({ theme }) => theme.colors.gray900};
  background: ${({ theme }) => theme.colors.surface};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  box-shadow: inset 0px 7px 7px -5px rgba(47, 60, 79, 0.12);
`;

const Arrow = styled(BaseArrow)`
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 90)}deg);
`;

const Box = ({ title, isOpen, onClick, children }) => (
  <>
    <BoxWrapper onClick={onClick} className="group">
      <span>{title}</span>
      <CircleIcon icon={Arrow} $isOpen={isOpen} />
    </BoxWrapper>

    <BoxContent isOpen={isOpen} className="content">
      {children}
    </BoxContent>
  </>
);

const Accordion = ({ className, defaultOpenRows, children }) => {
  const [openRows, setOpenRows] = useState(() => defaultOpenRows || {});

  return (
    <FlexColumn className={className}>
      {React.Children.map(
        React.Children.toArray(children).filter((child) => !!child.props),
        (child, index) =>
          React.cloneElement(child, {
            isOpen: openRows[index],
            onClick: () => setOpenRows({ ...openRows, [index]: !openRows[index] })
          })
      )}
    </FlexColumn>
  );
};

Accordion.Box = Box;

export default Accordion;
