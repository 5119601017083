import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { body } from '../Typography';
import { FlexMiddle } from '../Flex';
import Label from '../Label';

const Container = styled(FlexMiddle)`
  width: 120px;
`;

const common = css`
  ${body};
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 10px;
  background: ${({ theme }) => theme.colors.surface};
  min-height: 48px;
`;

const Input = styled.input`
  ${common};
  width: 100%;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Button = styled.button.attrs(() => ({ type: 'button' }))`
  ${common};
  cursor: pointer;
  border-radius: ${({ isLeft }) => (isLeft ? `5px 0 0 5px` : `0 5px 5px 0`)};
  border-width: ${({ isLeft }) => (isLeft ? `1px 0 1px 1px` : `1px 1px 1px 0`)};
`;

const NumberInput = ({ title, value, onChange, className, required, min = -Infinity, max = Infinity }) => {
  const handleChange = useCallback(
    (value) => {
      if (value >= min && value <= max) {
        onChange(value);
      }
    },
    [max, min, onChange]
  );

  return (
    <Label title={title} required={required} className={className}>
      <Container>
        <Button isLeft type="button" onClick={() => handleChange(parseInt(value, 10) - 1)}>
          -
        </Button>
        <Input type="number" value={value} onChange={(e) => handleChange(e.target.value)} min={min} />
        <Button type="button" onClick={() => handleChange(parseInt(value, 10) + 1)}>
          +
        </Button>
      </Container>
    </Label>
  );
};

export default NumberInput;
