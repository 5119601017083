import React from 'react';
import PropTypes from 'prop-types';
import useSafeState from '@ubeya/shared/hooks/useSafeState';
import { ReactComponent as MoreIcon } from '../../assets/menu.svg';
import CircleIcon from '../CircleIcon';
import DropDown from '../DropDown';
import PromptClick from '../PromptClick/PromptClick';

const MENU_OFFSET = { top: 5, right: 10 };

const ContextMenu = ({
  className,
  children,
  options = [],
  menuOffset,
  usePortal,
  openFromRight,
  maxHeight,
  scrollingContainer,
  menuMinWidth,
  useTooltip
}) => {
  const [promptOptions, setPromptOptions] = useSafeState();

  return (
    <>
      <DropDown
        showArrow={false}
        renderValue={children || <CircleIcon icon={MoreIcon} />}
        className={className}
        menuOffset={menuOffset ?? MENU_OFFSET}
        usePortal={usePortal}
        openFromRight={openFromRight}
        maxHeight={maxHeight}
        menuMinWidth={menuMinWidth}
        scrollingContainer={scrollingContainer}>
        {options
          .filter(({ visible }) => visible === undefined || visible)
          .map((option, index) => (
            <DropDown.Item
              key={index}
              onClick={(e) => (option.confirm ? setPromptOptions(option) : option.onClick(e))}
              internalCss={option.css}
              icon={option.icon}
              isDisabled={option.disabled}
              useTooltip={useTooltip}>
              {option.label}
            </DropDown.Item>
          ))}
      </DropDown>

      {promptOptions && (
        <PromptClick
          title={promptOptions.confirmTitle}
          body={promptOptions.confirmBody}
          renderBody={promptOptions.confirmRenderBody}
          isOpen
          onClose={() => setPromptOptions()}
          onClick={promptOptions.onClick}
          confirmText={promptOptions.confirmText}
          showCancelButton={promptOptions.confirmCancelButton}
          usePortal={promptOptions.confirmPortal}
          isWaitingForResponse={promptOptions.confirmIsWaitingForResponse}
        />
      )}
    </>
  );
};

ContextMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      confirm: PropTypes.bool,
      confirmBody: PropTypes.string,
      confirmText: PropTypes.string,
      confirmPortal: PropTypes.bool
    })
  )
};

export default ContextMenu;
