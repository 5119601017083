const flattenData = (data, extendedRows = {}) =>
  data.reduce(
    (all, item, index) => [
      ...all,
      { ...item, realIndex: index },
      ...(extendedRows[index] ? (item.subRows || []).map((item) => ({ ...item, realIndex: index })) : [])
    ],
    []
  );

export default flattenData;
