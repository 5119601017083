import React, { useMemo, useContext } from 'react';
import styled, { css } from 'styled-components';
import DropDownContext from '../../contexts/DropDownContext';
import { FlexMiddle, FlexCenter } from '../Flex';
import { smallBody } from '../Typography';
import TextWithTooltip from '../Tooltip/TextWithTooltip';

const hoverOption = css`
  background: ${({ theme }) => theme.colors.backgroundLight};
`;

const Option = styled(FlexMiddle).attrs(() => ({ className: 'context-option' }))`
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  ${smallBody};
  ${({ internalCss }) => internalCss}

  ${({ isTooltip }) => isTooltip && 'max-width:200px'};

  &:hover {
    ${({ isClickable }) => isClickable && hoverOption};
  }

  ${({ isSelected }) => isSelected && hoverOption};

  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      background: ${theme.colors.gray910}!important;
      color: ${theme.colors.gray500}!important;
      cursor: no-drop;
    `}
`;

const IconWrapper = styled(FlexCenter)`
  margin-right: 10px;
`;

const DropDownItem = ({ icon: Icon, children, onClick, closeOnClick = true, isDisabled, internalCss, useTooltip }) => {
  const { closeMenu } = useContext(DropDownContext);

  const handleClick = useMemo(
    () =>
      !isDisabled && onClick
        ? (e) => {
            e.stopPropagation();
            onClick(e);
            closeOnClick && closeMenu?.(false);
          }
        : undefined,
    [closeMenu, closeOnClick, isDisabled, onClick]
  );

  return (
    <Option
      isClickable={!!onClick}
      onClick={handleClick}
      isDisabled={isDisabled}
      internalCss={internalCss}
      isTooltip={!!useTooltip}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      {useTooltip ? <TextWithTooltip> {children}</TextWithTooltip> : children}
    </Option>
  );
};

export default DropDownItem;
