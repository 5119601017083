import React from 'react';
import { Field, useField } from 'react-final-form';
import PlacesAutocomplete from '../PlacesAutocomplete';

const PlacesField = ({ title, name, validate, locationName, ...restProps }) => {
  const {
    input: { onChange: setLocation }
  } = useField(locationName);
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta }) => (
        <PlacesAutocomplete
          {...input}
          onChange={(value, location) => {
            input.onChange(value);
            locationName && setLocation?.(location);
          }}
          title={title}
          error={meta.touched && meta.error}
          {...restProps}
        />
      )}
    />
  );
};

export default PlacesField;
