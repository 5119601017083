import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import BaseTooltip from './Tooltip';

const Wrapper = styled.div`
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;

const Tooltip = styled(BaseTooltip)`
  max-width: 250px;
`;

const TextWithTooltip = ({ children, className, enabled = true, hidden = true, ...props }) => {
  const [hasOverflowingChildren, setHasOverflowingChildren] = useState(true);
  const [text, setText] = useState();

  const updateOverflow = useCallback(
    (e) => {
      const el = e.target;

      if (el.scrollWidth > el.clientWidth) {
        setHasOverflowingChildren(true);
        if (el.textContent !== text) {
          setText(el.textContent);
        }
      } else {
        setHasOverflowingChildren(false);
      }
    },
    [text]
  );

  if (!hidden) {
    return children;
  }

  if (!enabled) {
    return (
      <Wrapper className={className} {...props}>
        {children}
      </Wrapper>
    );
  }

  return (
    <Tooltip body={text} disabled={!hasOverflowingChildren}>
      <Wrapper className={className} onMouseEnter={updateOverflow} {...props}>
        {children}
      </Wrapper>
    </Tooltip>
  );
};

export default TextWithTooltip;
