import { get } from 'lodash';
import moment from 'moment';
import { API_DATE_TIME_FORMAT } from '../constants';

export const SORT_BY_STRING = (key) => (a, b) => {
  const aValue = get(a, key, '');
  const bValue = get(b, key, '');

  return aValue?.localeCompare?.(bValue) || 0;
};

export const SORT_BY_NAME = (a, b) =>
  `${a?.employee?.firstName || a?.firstName}${a?.employee?.lastName || a?.lastName}`.localeCompare(
    `${b?.employee?.firstName || b?.firstName}${b?.employee?.lastName || b?.lastName}`
  );

export const SORT_BY_DATE = (key, includeTime = false) => (a, b) => {
  const formatDate = includeTime ? 'YYYYMMDDHHmm' : 'YYYYMMDD';

  const aValue = get(a, key);
  const bValue = get(b, key);

  if (aValue && !bValue) {
    return 1;
  }
  if (!bValue && aValue) {
    return -1;
  }
  if (!aValue && !bValue) {
    return 0;
  }

  return (
    moment(get(a, key), API_DATE_TIME_FORMAT).format(formatDate) -
    moment(get(b, key), API_DATE_TIME_FORMAT).format(formatDate)
  );
};

export const SORT_BY_TIME = (key, format) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  if (aValue && !bValue) {
    return -1;
  }
  if (!bValue && aValue) {
    return 1;
  }
  if (!aValue && !bValue) {
    return 0;
  }

  return (
    moment(aValue, format ?? API_DATE_TIME_FORMAT).format('HHmm') -
    moment(bValue, format ?? API_DATE_TIME_FORMAT).format('HHmm')
  );
};

const totalTime = (start, end) => (start && end ? moment(end).diff(moment(start)) : 0);

export const SORT_BY_TOTAL = (a, b) => totalTime(a.actualIn, a.actualOut) - totalTime(b.actualIn, b.actualOut);
export const SORT_BY_ACTUAL_TOTAL = (a, b) => a.actualTotal - b.actualTotal;

export const SORT_BY_NUMBER = (key, secondKey) => (a, b) => {
  let aValue = get(a, key) || 0;
  let bValue = get(b, key) || 0;

  const result = aValue - bValue;

  if (result !== 0 || !secondKey) {
    return result;
  }

  aValue = get(a, secondKey);
  bValue = get(b, secondKey);
  return aValue - bValue;
};

export const SORT_BY_BOOLEAN = (key) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  if (aValue && !bValue) {
    return -1;
  }
  if (!bValue && aValue) {
    return 1;
  }
  return 0;
};

export const SORT_MAP = {
  text: SORT_BY_STRING,
  number: SORT_BY_NUMBER,
  boolean: SORT_BY_BOOLEAN,
  date: SORT_BY_DATE,
  time: SORT_BY_NUMBER,
  currency: SORT_BY_NUMBER,
  percentage: SORT_BY_NUMBER,
  user: () => SORT_BY_NAME,
  duration: SORT_BY_DATE,
  i18n: SORT_BY_STRING,
  undefined: SORT_BY_STRING
};

export const sortArray = (array, oldIndex, newIndex) => {
  const field = { ...array[oldIndex] };
  const without = [...array.slice(0, oldIndex), ...array.slice(oldIndex + 1)];
  const withField = [...without.slice(0, newIndex), field, ...without.slice(newIndex)];
  return withField;
};
