import React from 'react';
import styled from 'styled-components';
import { Small } from '../Typography';
import { ReactComponent as UbeyaIconBase } from '../../assets/logo.svg';
import { Flex, FlexCenter } from '../Flex';

const Title = styled(Small)`
  text-align: center;
  color: ${({ theme }) => theme.colors.surface};
  padding: 0 10px;
`;
const Circle = styled(FlexCenter)`
  background: ${({ theme }) => theme.colors.primary};
  height: 25px;
  width: 25px;
  border-radius: 12px;
  align-self: flex-end;
`;
const UbeyaIcon = styled(UbeyaIconBase)`
  width: 13px;
  height: 13px;
`;

const Wrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.outsource};
  height: 25px;
  border-radius: 13px;
  margin: 3px 18px 1px 0;
  align-items: center;
`;

const OutsourceBadge = () => {
  return (
    <Wrapper>
      <Title>Outsourced</Title>
      <Circle>
        <UbeyaIcon />
      </Circle>
    </Wrapper>
  );
};

export default OutsourceBadge;
