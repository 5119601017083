import React from 'react';
import { useTranslation } from 'react-i18next';
import { required } from '@ubeya/shared/utils/form';
import CheckBoxField from '../../FormFields/CheckBoxField';
import InputField from '../../FormFields/InputField';
import NumberField from '../../FormFields/NumberField';

const SliderFieldForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <InputField
        name="name"
        title={t('name')}
        placeholder={t('selectNameToField')}
        className="full"
        validate={required}
        required
        autoFocus
      />
      <NumberField name="min" title={t('from')} />
      <NumberField name="max" title={t('to')} />
      <CheckBoxField name="isFloat" title={t('isFloat')} />
    </>
  );
};

export default SliderFieldForm;
