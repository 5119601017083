import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';
import { SmallBody, Small } from '../Typography';
import { FlexColumn, FlexSpace } from '../Flex';

const Wrapper = styled(FlexColumn)`
  position: relative;
`;

const Title = styled(SmallBody).attrs(() => ({ className: 'label' }))`
  color: ${({ theme }) => theme.colors.gray300};
  white-space: nowrap;
  margin-bottom: 3px;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
        margin-left: 2px;
      }
    `}
`;

const ErrorText = styled(Small)`
  color: ${({ theme }) => theme.colors.failure};
`;

const Label = ({ title, children, className, required, error }) => (
  <Wrapper className={className}>
    {title && (
      <FlexSpace>
        <Title required={required}>{title}</Title>
        {error && (
          <ErrorText>
            <Trans>{error}</Trans>
          </ErrorText>
        )}
      </FlexSpace>
    )}
    {children}
  </Wrapper>
);
export default Label;
