import { createCachedSelector } from 're-reselect';
import { useQuery } from 'react-query';
import * as api from '../../services/api';
import useCRUD from '../useCRUD';
import useAccount from './useAccount';

const COLORS = [
  { id: 1, color: '#1abc9c' },
  { id: 2, color: '#2ecc71' },
  { id: 3, color: '#3498db' },
  { id: 4, color: '#9b59b6' },
  { id: 5, color: '#34495e' },
  { id: 6, color: '#f1c40f' },
  { id: 7, color: '#e67e22' },
  { id: 8, color: '#e74c3c' },
  { id: 9, color: '#bdc3c7' },
  { id: 10, color: '#9c88ff' },
  { id: 11, color: '#273c75' },
  { id: 12, color: '#192a56' }
];

const selector = createCachedSelector(
  (data) => data.data,
  (data) => {
    const colorsOptions = COLORS.map(({ id, color }) => ({ value: id, label: color }));

    const mappedColors = colorsOptions.reduce((prev, { value, label }) => ({ ...prev, [value]: label }), {});

    const tagsOptions = (data || []).map(({ id, title, color }) => ({
      value: id,
      label: title,
      color: mappedColors[color]
    }));

    const mappedTags = tagsOptions.reduce((prev, tag) => ({ ...prev, [tag.value]: tag }), {});

    return { colorsOptions, mappedColors, tagsOptions, mappedTags };
  }
)({
  keySelector: (data, storeKey) => storeKey.join('#')
});

const useTags = () => {
  const { accountId } = useAccount();
  const storeKey = ['tags', accountId];
  const { isLoading, data } = useQuery(storeKey, () => api.fetchTags({ accountId }), {
    enabled: !!accountId,
    select: (data) => selector(data, storeKey)
  });

  const { colorsOptions = [], mappedColors = {}, tagsOptions = [], mappedTags = {} } = data || {};

  const { addItem, editItem, deleteItem } = useCRUD(storeKey, {
    addApi: (tag) => api.addTag({ accountId, tag }),
    editApi: ({ id, ...tag }) => api.updateTag({ accountId, tagId: id, tag }),
    deleteApi: ({ id }) => api.deleteTag({ accountId, tagId: id })
  });

  return {
    isLoading,
    tags: data?.data || [],
    deleteTag: deleteItem,
    addTag: addItem,
    editTag: editItem,
    colorsOptions,
    tagsOptions,
    mappedColors,
    mappedTags
  };
};

export default useTags;
