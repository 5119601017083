import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { TextInput } from '../Input';
import { Card } from '../Card';
import useMenu from '../../hooks/useMenu';

const Wrapper = styled.div`
  position: relative;
`;

const Options = styled(Card)`
  z-index: 6;
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}
`;

const Option = styled.div`
  padding: 15px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundLight};
  }
`;

const PlacesAutocomplete = ({
  title,
  value: selectedValue,
  onChange,
  disabled,
  usePortal,
  className,
  required,
  error,
  ...restProps
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300
  });

  const { Portal, wrapperRef, style, menuRef, isOpen, setOpen } = useMenu({ usePortal, onClose: clearSuggestions });

  useEffect(() => {
    if (value && !isOpen) {
      setOpen();
    }
  }, [isOpen, value, setOpen]);

  const handleSelect = useCallback(
    ({ description }) => async () => {
      setValue(description, false);
      clearSuggestions();

      try {
        const results = await getGeocode({ address: description });
        const { lat, lng } = await getLatLng(results[0]);
        onChange(description, { lat, lng });
      } catch (err) {
        // error
      }
    },
    [clearSuggestions, onChange, setValue]
  );

  return (
    <Wrapper
      ref={(node) => {
        wrapperRef.current = node;
      }}
      className={className}>
      <TextInput
        title={title}
        value={value || selectedValue}
        onChange={setValue}
        disabled={!ready || disabled}
        required={required}
        error={error}
        {...restProps}
      />
      {status === 'OK' && (
        <Portal>
          <Options ref={menuRef} innerStyle={style}>
            {data.map((suggestion, index) => {
              const {
                structured_formatting: { main_text: main, secondary_text: secondary }
              } = suggestion;

              return (
                <Option key={index} onClick={handleSelect(suggestion)}>
                  <strong>{main}</strong> <small>{secondary}</small>
                </Option>
              );
            })}
          </Options>
        </Portal>
      )}
    </Wrapper>
  );
};

export default PlacesAutocomplete;
