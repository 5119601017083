import React, { createContext } from 'react';
import moment from 'moment';
import { TIME_FORMAT } from '@ubeya/shared/constants';

const ConfigContext = createContext({
  timeFormat: TIME_FORMAT[24],
  dateFormat: 'DD/MM/YYYY',
  shortDateFormat: 'DD/MM/YY',
  shortDateMonthFormat: 'DD/MM',
  firstDayOfWeek: 1,
  currency: '$',
  countryCode: 'US'
});

export default ConfigContext;

export const ConfigContextProvider = ({ config, children }) => {
  moment.updateLocale('en', { week: { dow: config.firstDayOfWeek - 1 } });

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
