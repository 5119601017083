import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { FlexMiddle } from '../Flex';
import Label from '../Label';
import Toggle from '../Toggle';

const ToggleWrapper = styled(FlexMiddle)`
  min-height: 44px;
`;

const ToggleField = ({ title, name, validate, className, text }) => (
  <Field
    name={name}
    validate={validate}
    render={({ input }) => (
      <Label title={title} className={className}>
        <ToggleWrapper>
          <Toggle {...input} text={text} />
        </ToggleWrapper>
      </Label>
    )}
  />
);

export default ToggleField;
