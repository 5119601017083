import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import usePortalHook from '../../hooks/usePortal';
import { media } from '../../style';
import { FlexCenter, FlexColumn, FlexMiddle, FlexColumnCenter } from '../Flex';
import { body, Header2SemiBold } from '../Typography';
import Button from '../Button';
import CircleIcon from '../CircleIcon';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { card } from '../Card';
import usePressKey from '../../hooks/usePressKey';

const Wrapper = styled(FlexCenter)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(19, 21, 41, 0.3);
  z-index: 5;
`;

const Container = styled(FlexColumn)`
  position: relative;
  ${card}
  width: 90vw;
  max-height: 60vh;
  padding: 40px 35px;
  ${media.mobile`max-width: 500px;`}
`;

const TitleColumn = styled(FlexColumnCenter)`
  color: ${({ theme }) => theme.colors.primary};
`;

const CloseButton = styled(CircleIcon)`
  position: absolute;
  top: 28px;
  right: 33px;
`;

const Title = styled(Header2SemiBold)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin: 12px 0 0;
`;

const Content = styled(FlexColumn)`
  flex: 1;
  ${body}
  padding: 34px 0;
  text-align: center;
`;

const Buttons = styled(FlexMiddle)`
  justify-content: center;
  flex-direction: row-reverse;
  padding: 10px;

  > button {
    min-width: 145px;
    &:not(:first-child) {
      margin-right: 10px;
    }
  }
`;

const ConfirmationModal = ({
  children,
  onClose,
  title,
  body,
  confirmText = 'update',
  onConfirm,
  disabledConfirm = false,
  loadingConfirm = false,
  cancelText = 'cancel',
  closeOnClickOutside = true,
  className,
  usePortal,
  showCancelButton = true
}) => {
  const { t } = useTranslation();

  const container = useRef();

  const { Portal } = usePortalHook({
    enabled: usePortal,
    closeOnClickOutside: false,
    portalId: 'modal'
  });

  // ESC
  usePressKey({
    which: 27,
    ref: { current: document },
    onSelect: () => closeOnClickOutside && onClose()
  });

  return (
    <Portal>
      <Wrapper onClick={() => closeOnClickOutside && onClose()}>
        <Container ref={container} className={className} onClick={(e) => e.stopPropagation()}>
          <TitleColumn>
            <InfoIcon />
            <Title>{t(title)}</Title>

            <CloseButton icon={CloseIcon} onClick={() => onClose()} />
          </TitleColumn>

          <Content>{t(body) || children}</Content>

          <Buttons>
            <Button
              primary
              type="button"
              disabled={disabledConfirm}
              isLoading={loadingConfirm}
              onClick={onConfirm}
              autoFocus>
              {t(confirmText)}
            </Button>

            {showCancelButton && (
              <Button secondary type="button" onClick={() => onClose()}>
                {t(cancelText)}
              </Button>
            )}
          </Buttons>
        </Container>
      </Wrapper>
    </Portal>
  );
};

export default ConfirmationModal;
