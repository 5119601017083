import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { useMutation } from 'react-query';
import {
  Button,
  CheckBoxField as BaseCheckBoxField,
  InputField,
  PhoneField,
  smallBody,
  AutoCompleteOff,
  SelectField
} from '@ubeya/shared-web/components';
import { composeValidators, required, validEmail, validPassword, passwordIndicator } from '@ubeya/shared/utils/form';
import * as api from 'shared/services/api';
import useRecaptcha from '../hooks/useRecaptcha';
import Layout from './Layout';
import countries from './countries.json';

const ContainerForm = styled.form`
  display: grid;
  grid-template-rows: repeat(7, auto);
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  > * {
    grid-column: 1/-1;

    &:first-child {
      grid-column: 1;
    }

    &:nth-child(2) {
      grid-column: 2;
    }
  }
`;

const Errors = styled.div`
  min-height: 17px;
  color: ${({ theme }) => theme.colors.failure};
  margin-bottom: -5px;
  padding: 6px 0;
`;

const Warning = styled(Errors)`
  color: ${({ theme }) => theme.colors.warning};
`;

const PasswordIndicator = styled(Errors)`
  color: ${({ theme }) => theme.colors.gray100};
`;

const Checkboxes = styled.div`
  margin: 0 0 30px 0;
`;

const CheckBoxField = styled(BaseCheckBoxField)`
  margin-left: -20px;
  padding: 5px 0;
  &&& label {
    ${smallBody}
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
`;

const decorators = [
  //   createDecorator({
  //     field: 'password',
  //     updates: { passwordIndicator }
  //   })
];

const AccountForm = ({ setAccountExists, setAccountInfo, email }) => {
  const { t } = useTranslation();

  const { mutateAsync: createAccount } = useMutation(api.createAccount, {
    throwOnError: true,
    onSuccess: (data, values) => setAccountInfo(values),
    onError: (error) => error?.response?.data?.errorCode !== 'failedRecaptchaVerification' && setAccountExists(true)
  });

  const getToken = useRecaptcha();

  const handleSubmit = useCallback(
    async (values) => {
      const token = await getToken();
      return createAccount({ ...values, token });
    },
    [createAccount, getToken]
  );

  const countriesOptions = useMemo(() => (countries || []).map(({ id, name }) => ({ value: id, label: name })), []);

  const initialValues = useMemo(() => (email ? { email } : {}), [email]);

  return (
    <Layout title={t('createAnAccount')}>
      <AutoCompleteOff />
      <Form
        onSubmit={handleSubmit}
        decorators={decorators}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, values, errors, touched }) => {
          return (
            <ContainerForm onSubmit={handleSubmit}>
              <InputField name="firstName" placeholder={`${t('firstName')} *`} validate={required} />
              <InputField name="lastName" placeholder={`${t('lastName')} *`} validate={required} />

              <InputField name="company" placeholder={`${t('company')} *`} validate={required} />
              <InputField
                name="email"
                placeholder={`${t('workEmail')} *`}
                validate={composeValidators(required, validEmail)}
              />
              {/* <InputField
                name="password"
                type="password"
                placeholder={`${t('createPassword')} *`}
                validate={validPassword}
              /> */}
              <SelectField name="countryId" placeholder={t('country')} options={countriesOptions} isMulti={false} />
              <PhoneField name="phone" type="phone" validate={required} />

              {values.email?.includes?.('@gmail.') && touched.email && <Warning>{t('useWorkEmail')}</Warning>}

              <Checkboxes>
                <CheckBoxField
                  name="agree"
                  title={
                    <Trans i18nKey="agreeSignup">
                      <Link href="https://www.ubeya.com/privacy/sw-eula/" target="_blank" rel="noreferrer" />
                      <Link href="https://www.ubeya.com/platform-privacy-policy" target="_blank" rel="noreferrer" />
                    </Trans>
                  }
                  validate={(value) => required(value, true)}
                />
                {/* {values.passwordIndicator ? (
                  <PasswordIndicator>{t(values.passwordIndicator)}</PasswordIndicator>
                ) : (
                  touched.password && <Errors>{t(errors.password)}</Errors>
                )} */}
                {touched.firstName && errors.firstName && <Errors>{t('firstNameWarning')}</Errors>}
                {touched.lastName && errors.lastName && <Errors>{t('lastNameWarning')}</Errors>}
                {touched.email && errors.email && <Errors>{t('emailWarning')}</Errors>}
                {touched.agree && errors.agree && <Errors>{t('onboardingAcceptTermsWarning')}</Errors>}
                {touched.phone && errors.phone && <Errors>{t('phoneWarning')}</Errors>}
              </Checkboxes>

              <Button primary type="submit" isLoading={submitting}>
                {t('signUp')}
              </Button>
            </ContainerForm>
          );
        }}
      />
    </Layout>
  );
};

export default AccountForm;
