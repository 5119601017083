import React from 'react';
import { Field } from 'react-final-form';
import { DatePickerInput } from '../DatePicker';

const DateField = ({ title, name, validate, onChange, ...restProps }) => (
  <Field
    name={name}
    validate={validate}
    render={({ input, meta }) => (
      <DatePickerInput
        title={title}
        {...restProps}
        {...input}
        onChange={(value) => {
          onChange?.(value);
          input.onChange(value);
        }}
        error={meta.touched && meta.error}
      />
    )}
  />
);

export default DateField;
