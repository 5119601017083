import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { Body, Button as BaseButton } from '@ubeya/shared-web/components';
import Layout from './Layout';

const Button = styled(BaseButton)`
  margin-top: 30px;
  max-width: 180px;
`;

const AccountExists = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('accountExistsTitle')} left width={500}>
      <Body>
        <Trans>accountExists</Trans>
      </Body>

      <Button primary onClick={() => (window.location = process.env.AUTH_URL)}>
        {t('signIn')}
      </Button>
    </Layout>
  );
};

export default AccountExists;
