import React, { useState, useCallback, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import usePortalHook from '../../hooks/usePortal';
import { media, scrollbar } from '../../style';
import { FlexCenter, FlexColumn, FlexSpace, FlexMiddle } from '../Flex';
import { Header2SemiBold } from '../Typography';
import Button from '../Button';
import CircleIcon from '../CircleIcon';
import ConfirmationModal from '../ConfirmationModal';
import PromptClick from '../PromptClick/PromptClick';
import { card } from '../Card';
import usePressKey from '../../hooks/usePressKey';

const jumpIn = keyframes`
  0%{transform: scale(0.3); opacity: 0.7;}
  90%{transform: scale(1.01); opacity: 1;}
  100%{transform: scale(1);}
`;

const SIZES = {
  full: css`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;

    ${media.mobile`
      width: 96vw;
      height: 96vh;
    `}
  `,
  xlarge: css`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;

    ${media.mobile`
      width: 96vw;
      height: 96vh;
      max-width: 1100px;
      max-height: 700px;
    `}
  `,
  large: css`
    width: 96vw;
    height: 96vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;

    ${media.mobile`
      width: 90vw;
      height: 80vh;
      max-width: 1100px;
      max-height: 700px;
    `}
  `,
  medium: css`
    width: 90vw;
    max-height: 60vh;

    ${media.mobile`
      max-width: 780px;
    `}
  `,
  small: css`
    width: 90vw;
    max-height: 60vh;

    ${media.mobile`
      max-width: 600px;
    `}
  `,
  xs: css`
    width: 80vw;
    max-height: 60vh;

    ${media.mobile`
      width: 90vw;
      max-width: 400px;
    `}
  `,
  long: css`
    width: 90vw;
    max-height: 90vh;
    height: 90vh;

    ${media.mobile`
      max-width: 650px;
    `}
  `
};

const Wrapper = styled(FlexCenter)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(19, 21, 41, 0.7);
  z-index: 5;
`;

const Container = styled(FlexColumn)`
  position: relative;
  ${card}
  ${({ size }) => SIZES[size]}
`;

const TitleRow = styled(FlexSpace)`
  padding: 22px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
`;

const TitleContent = styled(Header2SemiBold)`
  flex: 1;
`;
const Content = styled(FlexColumn)`
  flex: 1;
  ${({ $isScrollableContent }) => $isScrollableContent && 'overflow: hidden auto;'}
  ${({ $isPaddingContent }) => $isPaddingContent && ' padding: 34px 42px;'}
  ${scrollbar}
`;

const Buttons = styled(FlexMiddle)`
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray900};

  > button:not(:first-child) {
    margin-left: 10px;
  }
`;

const Modal = ({
  size = 'large',
  children,
  onClose,
  title,
  body,
  showConfirmButton = true,
  confirmText = 'update',
  submit = false,
  onConfirm,
  disabledConfirm = false,
  loadingConfirm = false,
  showCancelButton = false,
  cancelText = 'cancel',
  onCancel,
  closeOnClickOutside = true,
  prompt = false,
  promptOnConfirm = false,
  promptTitle = 'areYouSure',
  promptBody = 'areYouSureDiscardChanges',
  promptConfirmText = 'discard',
  className,
  usePortal,
  isScrollableContent = true,
  isPaddingContent = true,
  renderButtons,
  isForm = false,
  ...formProps
}) => {
  const { t } = useTranslation();

  const container = useRef();

  const [promptContext, setPromptContext] = useState();

  const [isPromptOpen, setIsPromptOpen] = useState(false);

  const handleClose = useCallback(() => {
    if (prompt) {
      setIsPromptOpen(true);
      setPromptContext('close');
    } else {
      onClose();
    }
  }, [onClose, prompt]);

  const { Portal } = usePortalHook({
    enabled: usePortal,
    closeOnClickOutside: false,
    portalId: 'modal'
  });

  // ESC
  usePressKey({
    which: 27,
    ref: { current: document },
    onSelect: () => closeOnClickOutside && handleCancel()
  });

  const handleCancel = useCallback(() => {
    if (prompt) {
      setIsPromptOpen(true);
      setPromptContext('cancel');
    } else {
      onCancel ? onCancel() : onClose();
    }
  }, [onCancel, onClose, prompt]);

  const handleConfirmPrompt = useCallback(() => {
    if (promptContext === 'cancel' && onCancel) {
      onCancel();
    } else {
      onClose();
    }
    setIsPromptOpen(false);
  }, [onCancel, onClose, promptContext]);

  return (
    <Portal>
      <Wrapper as={isForm ? 'form' : undefined} {...formProps} onClick={() => closeOnClickOutside && handleCancel()}>
        <Container ref={container} className={className} size={size} onClick={(e) => e.stopPropagation()}>
          {title && (
            <TitleRow className="title">
              <TitleContent>{typeof title === 'string' ? t(title) : title}</TitleContent>

              <CircleIcon icon={CloseIcon} onClick={handleClose} />
            </TitleRow>
          )}

          <Content
            className="content"
            id="modal-container"
            $isScrollableContent={isScrollableContent}
            $isPaddingContent={isPaddingContent}>
            {t(body) || children}
          </Content>

          {(showConfirmButton || showCancelButton || renderButtons) && (
            <Buttons className="buttons">
              {renderButtons?.({ loadingConfirm })}

              {showCancelButton && (
                <Button secondary type="reset" disabled={loadingConfirm} onClick={handleCancel}>
                  {t(cancelText)}
                </Button>
              )}

              {showConfirmButton && (
                <PromptClick
                  onClick={onConfirm}
                  enable={promptOnConfirm}
                  title={promptTitle}
                  body={promptBody}
                  confirmText={promptConfirmText}
                  showCancelButton>
                  <Button
                    primary
                    type={submit ? 'submit' : 'button'}
                    disabled={disabledConfirm}
                    isLoading={loadingConfirm}>
                    {t(confirmText)}
                  </Button>
                </PromptClick>
              )}
            </Buttons>
          )}
        </Container>
      </Wrapper>

      {isPromptOpen && (
        <ConfirmationModal
          onClose={() => setIsPromptOpen(false)}
          title={t(promptTitle)}
          body={t(promptBody)}
          onConfirm={handleConfirmPrompt}
          confirmText={promptConfirmText}
          showCancelButton
        />
      )}
    </Portal>
  );
};

export default Modal;
