import React, { useMemo, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import useScrollToSelectedValue from '../../../hooks/Select/useScrollToSelectedValue';
import usePressKey from '../../../hooks/usePressKey';
import { Card } from '../../Card';
import scrollbar from '../../../style/scrollbar';
import { SmallBody } from '../../Typography';

const Wrapper = styled(Card).attrs(() => ({ className: 'select-menu' }))`
  z-index: 5;
  max-height: 250px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  border-radius: 6px;
  ${scrollbar};
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}
`;

const activeOption = css`
  background: ${({ theme }) => theme.colors.backgroundLight};
`;

const Option = styled(SmallBody)`
  white-space: nowrap;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  padding: 10px;

  &:hover {
    ${activeOption}
  }

  ${({ isSelected }) => isSelected && activeOption}
`;

const DEFAULT_OPTION_FORMAT = (value) => value;
const DEFAULT_FILTER_FUNC = (option, search) =>
  option.value !== null && option.label.toLowerCase().includes(search.toLowerCase());

const Options = ({
  isMulti,
  value,
  formatOption = DEFAULT_OPTION_FORMAT,
  defaultValue,
  handleSelect,
  search,
  filterFunc = DEFAULT_FILTER_FUNC,
  options: allOptions,
  creatable,
  wrapperRef,
  menuRef,
  style,
  className
}) => {
  const { t } = useTranslation();
  const filteredOptions = useMemo(
    () => (search ? allOptions.filter((option) => filterFunc(option, search)) : allOptions),
    [filterFunc, allOptions, search]
  );

  const options = useMemo(() => {
    const formattedOptions = filteredOptions.map(formatOption);
    if (search && formattedOptions.length === 0) {
      return [{ label: t(creatable ? 'addNewOption' : 'noResults', { search }), value: search, clickable: creatable }];
    }

    return formattedOptions;
  }, [creatable, filteredOptions, formatOption, search, t]);

  const isOptionClickable = (option) => option?.clickable !== false;

  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    let newSelectedIndex = 0;
    if (isMulti) {
      newSelectedIndex = 0;
    } else if (value) {
      newSelectedIndex = options.findIndex((option) => option.value === value);
    } else if (defaultValue) {
      newSelectedIndex = options.findIndex((option) => option.value === defaultValue);
    }

    setSelectedIndex(newSelectedIndex === -1 ? 0 : newSelectedIndex);
  }, [defaultValue, options, isMulti, value]);

  const menuOptionsRef = useRef();
  useScrollToSelectedValue({ menuRef: menuOptionsRef, selectedValue: value || defaultValue });

  // TAB
  usePressKey({
    which: 9,
    ref: wrapperRef,
    onSelect: () => (isOptionClickable(options[selectedIndex]) ? handleSelect(options[selectedIndex]?.value) : {})
  });

  // ENTER
  usePressKey({
    which: 13,
    ref: wrapperRef,
    onSelect: () => (isOptionClickable(options[selectedIndex]) ? handleSelect(options[selectedIndex]?.value) : {})
  });

  // UP
  usePressKey({
    which: 38,
    ref: wrapperRef,
    onSelect: () => {
      const newSelectedIndex = selectedIndex > 0 ? selectedIndex - 1 : selectedIndex;
      setSelectedIndex(newSelectedIndex);
      menuOptionsRef.current.children[newSelectedIndex].scrollIntoView({ block: 'nearest' });
    }
  });

  // DOWN
  usePressKey({
    which: 40,
    ref: wrapperRef,
    onSelect: () => {
      const newSelectedIndex = selectedIndex < options.length - 1 ? selectedIndex + 1 : selectedIndex;
      setSelectedIndex(newSelectedIndex);
      menuOptionsRef.current.children[newSelectedIndex].scrollIntoView({ block: 'nearest' });
    }
  });

  return (
    <Wrapper
      innerStyle={style}
      className={className}
      ref={(node) => {
        menuRef(node);
        menuOptionsRef.current = node;
      }}>
      {options.map((option, index) => (
        <Option
          key={`${option.value}-${index}`}
          data-attr={option.value}
          isSelected={option.value === value || index === selectedIndex}
          onClick={isOptionClickable(option) ? () => handleSelect(option.value) : undefined}>
          {option.label}
        </Option>
      ))}
    </Wrapper>
  );
};

export default Options;
