import styled, { css } from 'styled-components';

export const card = css`
  background: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 2px 10px 0 rgba(47, 60, 79, 0.12), 0 0 3px 0 rgba(89, 89, 89, 0.2);
`;

export const Card = styled.div`
  ${card};
`;
