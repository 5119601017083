import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Flex } from '../../Flex';
import { body } from '../../Typography';
import Chip from './Chip';

const Wrapper = styled.div.attrs(() => ({ className: 'select-container' }))`
  position: relative;
  flex: 1;
  align-self: flex-start;
`;

const Container = styled(Flex).attrs(() => ({ className: 'select-chips' }))`
  flex-wrap: wrap;
  padding: 5px;
`;

const Input = styled.input.attrs(() => ({ className: 'select-input' }))`
  ${body};
  border-radius: 5px;
  flex: 1;
  outline: 0;
  padding: 0 10px;
  background: transparent;
  border: none;
  min-height: 36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

const MultiValue = ({
  value,
  options,
  onClick,
  placeholder,
  search,
  setSearch,
  isDisabled,
  onRemoveChip,
  name,
  onBlur,
  isOpen,
  ...restProps
}) => {
  const displayChips = useMemo(() => options.filter((option) => value.includes(option.value)), [options, value]);

  return (
    <Wrapper onClick={onClick}>
      <Container>
        {displayChips.map((chip) => (
          <Chip key={chip.value} {...chip} isDisabled={isDisabled} onRemove={onRemoveChip} />
        ))}

        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={isDisabled ? '' : placeholder}
          disabled={isDisabled}
          onBlur={(e) => {
            onBlur?.(e);
            !isOpen && setSearch('');
          }}
          {...restProps}
        />
      </Container>
    </Wrapper>
  );
};

export default MultiValue;
