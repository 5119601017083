import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Carousel, { Modal, ModalGateway } from 'react-images';

const Container = styled.div`
  line-height: 0;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  max-width: 640px;
  margin: 0 auto;
`;

const file = css`
  height: auto;
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  margin: auto;
`;

const Image = styled.img`
  ${file};
`;

const Video = styled.video.attrs(() => ({ controls: true }))`
  ${file};
  width: 640px;
  max-height: 80vh;
  outline: none;
`;

const CustomView = ({ data, currentIndex, index }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (currentIndex === index) {
      videoRef?.current?.play?.();
    } else {
      videoRef?.current?.pause?.();
    }
  }, [currentIndex, index]);

  return (
    <Container>{data.videoUrl ? <Video src={data.source} ref={videoRef} /> : <Image src={data.source} />}</Container>
  );
};

const ImagesCarousel = ({ views = [], currentIndex, onClose }) => {
  if (currentIndex === undefined || views.length === 0) {
    return null;
  }

  return (
    <ModalGateway>
      <Modal onClose={onClose}>
        <Carousel currentIndex={currentIndex} views={views} components={{ View: CustomView }} />
      </Modal>
    </ModalGateway>
  );
};

export default ImagesCarousel;
