import React, { useMemo } from 'react';
import styled from 'styled-components';
import Select from '../Select';
import ContextMenu from '../ContextMenu';
import CircleIcon from '../CircleIcon';
import { responsiveShow, responsiveHide } from '../../style';
import { AutoCompleteOff } from '..';

const BaseSelect = styled(Select)`
  ${responsiveShow.tablet}

  > .select {
    width: 200px;

    .select-label {
      line-height: 14px;
      max-width: 95%;
    }

    .select-input {
      min-height: 24px;
      padding: 5px 10px;
    }

    .select-arrow {
      right: 12px;
    }
  }
`;

const BaseContextMenu = styled(ContextMenu)`
  ${responsiveHide.tablet}
`;

const SelectHeader = ({ icon, ...props }) => {
  const { options, onChange } = props;
  const clickableOptions = useMemo(
    () => options.map((option) => ({ ...option, onClick: () => onChange(option.value) })),
    [onChange, options]
  );

  return (
    <>
      <BaseSelect {...props} usePortal />
      <BaseContextMenu {...props} options={clickableOptions}>
        {icon && <CircleIcon icon={icon} />}
      </BaseContextMenu>
    </>
  );
};

export default SelectHeader;
