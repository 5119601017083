import React from 'react';
import { Field } from 'react-final-form';
import { TextInput, TextArea } from '../Input';

const InputField = ({
  title,
  name,
  validate,
  type,
  placeholder,
  controlledValue = false,
  className,
  onChange = (value) => value,
  mask,
  isDisabled,
  isMultiline,
  innerRef,
  autoFocus,
  required,
  showErrorMessage = true
}) => (
  <Field
    name={name}
    validate={validate}
    type={type}
    render={({ input, meta }) =>
      isMultiline ? (
        <TextArea
          {...input}
          title={title}
          placeholder={placeholder}
          className={className}
          innerRef={innerRef}
          required={required}
        />
      ) : (
        <TextInput
          type={type}
          {...input}
          value={input.value}
          onChange={(value) => (controlledValue ? onChange(value) : input.onChange(onChange(value)))}
          title={title}
          placeholder={placeholder}
          className={className}
          mask={mask}
          disabled={isDisabled}
          autoFocus={autoFocus}
          error={meta.touched && meta.error && (showErrorMessage ? meta.error : ' ')}
          required={required}
        />
      )
    }
  />
);

export default InputField;
