import { useEffect } from 'react';

const useScrollToTop = (ref, dep) => {
  useEffect(() => {
    if (ref.current) {
      try {
        ref.current.scrollTo?.(0);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
};

export default useScrollToTop;
