import moment from 'moment';

export const EMPLOYEES_TABS = {
  active: { statusFilter: 'active', dataKey: 'active' },
  inactive: { statusFilter: 'inactive', dataKey: 'inactive' },
  deleted: { statusFilter: 'deleted', dataKey: 'deleted' },
  admins: { statusFilter: 'active', dataKey: 'admins', onlyAdmins: true },
  candidates: { statusFilter: 'candidates', dataKey: 'candidates' }
};

export const SUPPLIERS_TABS = {
  // overview: { key: 'overview' },
  suppliers: { key: 'suppliers' }
};

export const SETTINGS__ACCOUNTS_TABS = [
  'info',
  'branches',
  'fields',
  'positions',
  'tags',
  'modules',
  'payroll',
  'departments'
];

export const SETTINGS__BRANCH_TABS = ['info', 'timesheet', 'schedule', 'employees', 'locations', 'uniforms'];

export const TIME_FORMAT = {
  12: 'hh:mm A',
  24: 'HH:mm'
};

export const DATE_FORMAT = ['DD/MM/YYYY', 'MM/DD/YYYY'];

export const DAYS_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const getDatesRange = () => ({
  today: { start: moment().startOf('day'), end: moment().endOf('day') },
  thisWeek: { start: moment().startOf('week').startOf('day'), end: moment().endOf('week').endOf('day') },
  lastWeek: {
    start: moment().subtract(1, 'week').startOf('week').startOf('day'),
    end: moment().subtract(1, 'week').endOf('week').endOf('day')
  },
  thisMonth: { start: moment().startOf('month').startOf('day'), end: moment().endOf('month').endOf('day') },
  lastMonth: {
    start: moment().subtract(1, 'month').startOf('month').startOf('day'),
    end: moment().subtract(1, 'month').endOf('month').endOf('day')
  },
  custom: { start: moment().startOf('day'), end: moment().endOf('day') }
});

export const ALLOWED_PHOTO_TYPES = '.jpeg,.jpg,.gif,.png';

export const ALLOWED_VIDEOS_TYPES = '.mp4,.mov,.qt';

export const ALLOWED_DOCUMENT_EXTENSIONS = '.jpeg,.jpg,.gif,.png,.pdf,.doc,.docx,.ppt,.pptx';

export const ATTACHMENTS_TYPES = {
  ...ALLOWED_DOCUMENT_EXTENSIONS.split(',').reduce((all, curr) => ({ ...all, [curr.slice(1)]: 'document' }), {}),
  ...ALLOWED_PHOTO_TYPES.split(',').reduce((all, curr) => ({ ...all, [curr.slice(1)]: 'image' }), {}),
  ...ALLOWED_VIDEOS_TYPES.split(',').reduce((all, curr) => ({ ...all, [curr.slice(1)]: 'video' }), {})
};

export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const API_TIME_FORMAT = 'HH:mm:ss';
export const API_TIME_FORMAT_NO_SECONDS = 'HH:mm';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const POLLING_INTERVAL = 7 * 1000;
export const LONG_POLLING_INTERVAL = 2 * 60 * 1000;

//Chat - Post message
export const PM_EMPLOYEE_CLICKED = 'employee-clicked';
export const PM_CHAT_COUNTER = 'chat-counter';
export const PM_CLOSE_CHAT_MOBILE = 'close-chat-mobile';

//shift duration
export const MAX_HOURS_SHOW_PROMPT = 17;
export const MAX_HOURS_WARNING = 35;
