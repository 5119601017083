import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import { SmallBody, SmallBodySemiBold } from '../Typography';
import useFormFields from '../../hooks/useFormFields';
import { FlexColumn, flexMiddle } from '../Flex';
import { scrollbar } from '../../style';
import { ReactComponent as ProfileFieldIcon } from '../../assets/profile-field.svg';
import ProfileInputModal from './ProfileInputModal';

const Wrapper = styled(FlexColumn)`
  padding: 10px 0;
  overflow: auto;
  ${scrollbar};
  min-width: 165px;
`;

const Title = styled(SmallBodySemiBold)`
  color: ${({ theme }) => theme.colors.primary};
  padding-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  margin: 25px;
  text-align: center;
`;

const FieldType = styled(SmallBody)`
  ${flexMiddle}
  color: ${({ theme }) => theme.colors.gray400};
  cursor: pointer;
  min-height: 40px;
  padding: 5px 20px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundLight};
  }
`;

const StyledIcon = styled.div.attrs(() => ({ dir: 'rtl' }))`
  min-width: 20px;
  margin-right: 16px;
`;

const FieldsTypes = ({
  includeUnsupportedInputs = false,
  includeContent = false,
  includeProfileFields = false,
  includeShowNotSupportedFields = false,
  className,
  onClick,
  fieldsName = 'fields'
}) => {
  const { t } = useTranslation();
  const { fieldTypesOptions } = useFormFields(includeUnsupportedInputs, includeContent, includeShowNotSupportedFields);

  const contentFields = useMemo(() => fieldTypesOptions.filter(({ content }) => content), [fieldTypesOptions]);
  const inputFields = useMemo(() => fieldTypesOptions.filter(({ content }) => !content), [fieldTypesOptions]);

  const [showProfileInput, setShowProfileInput] = useState(false);

  const {
    input: { value: fields, onChange }
  } = useField(fieldsName);

  return (
    <Wrapper className={className}>
      {contentFields.length > 0 && <Title>{t('myContent')}</Title>}

      {contentFields.map(({ value, label, icon: Icon }) => (
        <FieldType key={value} onClick={() => onClick(value)}>
          {Icon && <StyledIcon as={Icon} />}
          {label}
        </FieldType>
      ))}

      {inputFields.length > 0 && <Title>{t('inputField')}</Title>}

      {includeProfileFields && (
        <FieldType onClick={() => setShowProfileInput(true)}>
          <StyledIcon as={ProfileFieldIcon} />
          {t('profileField')}
        </FieldType>
      )}

      {inputFields.map(({ value, label, icon: Icon }) => (
        <FieldType key={value} onClick={() => onClick(value)}>
          {Icon && <StyledIcon as={Icon} />}
          {label}
        </FieldType>
      ))}

      {showProfileInput && (
        <ProfileInputModal
          onClose={() => setShowProfileInput(false)}
          onConfirm={(newFields) => onChange([...fields, ...newFields])}
        />
      )}
    </Wrapper>
  );
};

export default FieldsTypes;
