import React from 'react';
import styled from 'styled-components';
import MaskInput from 'react-maskinput';
import { body } from '../Typography';
import Label from '../Label';

const Input = styled.input`
  ${body};
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  outline: 0;
  padding: 10px;
  background: ${({ theme }) => theme.colors.surface};
  min-height: 44px;

  ${({ theme, error }) => error && `border-color: ${theme.colors.failure}; background:${theme.colors.failureLight};`}
  &:disabled {
    color: ${({ theme }) => theme.colors.gray800};
    border-color: ${({ theme }) => theme.colors.gray800};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }

  &:focus {
    box-shadow: ${({ theme }) => theme.colors.inputShadow};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const TextInput = ({
  title,
  name,
  type = 'text',
  value,
  onChange,
  className,
  required,
  mask,
  autoFocus,
  error,
  ...inputProps
}) => (
  <Label title={title} required={required} error={error} className={className}>
    <Input
      type={type}
      id={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      as={mask ? MaskInput : undefined}
      mask={mask}
      autoFocus={autoFocus}
      autoComplete="new-password"
      error={error ? 1 : 0}
      {...inputProps}
    />
  </Label>
);

export default TextInput;
