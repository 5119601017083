import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Body, flexMiddle } from '@ubeya/shared-web/components';

const Wrapper = styled(Body)`
  ${flexMiddle}
  align-self: flex-end;
  z-index: 1;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  margin-left: 5px;
`;

const AlreadyUser = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {t('alreadyUser')}

      <Link href={process.env.AUTH_URL}>{t('signIn')}</Link>
    </Wrapper>
  );
};

export default AlreadyUser;
