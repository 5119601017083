import { createCachedSelector } from 're-reselect';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import * as api from '../../services/api';
import useOptimisticMutation from '../useOptimisticMutation';
import useCRUD from '../useCRUD';
import { API_DATE_TIME_FORMAT } from '../../constants';
import { sortArray } from '../../utils/sorting';
import { mappedArray } from '../../utils/array';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data) => data.storeKey,
  (data) => {
    const fields = (data || []).map((field) =>
      field.isGeneric
        ? {
            ...field,
            name: field.name,
            slug: field.name,
            options: field.options
              ? field.options.map((option) => ({ ...option, title: option.title, slug: option.title }))
              : undefined
          }
        : field
    );

    const mappedFields = mappedArray(fields);

    const mappedFieldsValue = fields.reduce((prev, { id, fieldTypeId, options }) => {
      if (!options) {
        return {
          ...prev,
          [id]: (value) =>
            fieldTypeId === 2 && value
              ? moment(value, API_DATE_TIME_FORMAT).isValid()
                ? moment(value, API_DATE_TIME_FORMAT).format('DD/MM/YYYY')
                : undefined
              : value
        };
      }
      const mappedOptions = mappedArray(
        options,
        ({ id }) => id,
        ({ title }) => title
      );
      return {
        ...prev,
        [id]: (value) =>
          Array.isArray(value) ? value.map((item) => mappedOptions[item]).join(', ') : mappedOptions[value]
      };
    }, {});

    return { fields, mappedFields, mappedFieldsValue };
  }
)({
  keySelector: (data, storeKey) => storeKey
});

const sortFieldsCondition = (a, b) => {
  if (a.priority === b.priority) {
    return a.isGeneric && !b.isGeneric ? -1 : !a.isGeneric && b.isGeneric ? 1 : a.id - b.id;
  }
  return a.priority - b.priority;
};

const useFields = () => {
  const { t } = useTranslation();
  const { accountId } = useAccount();
  const queryClient = useQueryClient();

  const storeKey = ['fields', accountId];
  const { isLoading, data } = useQuery(
    storeKey,
    async () => {
      const data = await api.fetchFields({ accountId });
      return { ...data, data: (data?.data || []).sort(sortFieldsCondition) };
    },
    { enabled: !!accountId, select: (data) => selector(data, storeKey.join('#')) }
  );

  const { fields = [], mappedFields = {}, mappedFieldsValue = {} } = data || {};

  const { addItem, editItem, deleteItem } = useCRUD(
    storeKey,
    {
      addApi: (field) => api.addField({ accountId, ...field }),
      editApi: (field) => api.updateField({ accountId, fieldId: field.id, ...field }),
      deleteApi: ({ id }) => api.deleteField({ accountId, fieldId: id })
    },
    { onSuccess: () => queryClient.invalidateQueries('filters') }
  );

  const asyncAttachments = useOptimisticMutation(
    storeKey,
    (fields) => api.syncFields({ accountId, fields }),
    ({ previousData, deleted, addedWithName }) => ({
      ...previousData,
      data: [...addedWithName, ...previousData.data.filter((field) => !deleted.includes(field.id))]
    })
  );

  const sortFields = useOptimisticMutation(
    storeKey,
    () => api.sortFields({ accountId, fields: data?.data.map(({ id }, index) => ({ id, priority: index })) }),
    ({ previousData, oldIndex, newIndex }) => ({
      ...previousData,
      data: sortArray(previousData?.data, oldIndex, newIndex)
    }),
    { refetchOnSuccess: true }
  );

  const toggleField = useOptimisticMutation(
    storeKey,
    ({ id, ...value }) => api.updateField({ accountId, fieldId: id, ...value }),
    ({ previousData, id, ...value }) => ({
      ...previousData,
      data: previousData?.data.map((field) => (field.id === id ? { ...field, ...value } : field))
    })
  );

  return {
    isLoading,
    fields,
    mappedFields,
    mappedFieldsValue,
    deleteField: deleteItem,
    addField: addItem,
    editField: editItem,
    asyncAttachments,
    sortFields,
    toggleField
  };
};

export default useFields;
