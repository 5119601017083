import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import scrollbar from '../../style/scrollbar';
import FieldsFilter from './CustomFilters/FieldsFilter';

const Content = styled.div`
  max-height: 350px;
  overflow: auto;
  ${scrollbar}
  margin: 0 -10px -10px;
  padding: 0 10px;

  > .filter-container {
    overflow: unset;
    margin: 0;
  }
`;

const ApplyButton = styled(Button)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray900};
  margin: 10px -10px -10px;
`;

const SearchableOptions = ({ search, basicOptions, fieldsOptions, COMPONENTS_MAP, onApply }) => {
  const [localValues, setLocalValue] = useState({
    basic: basicOptions.reduce((all, { id, value }) => ({ ...all, [id]: value }), {}),
    fields: fieldsOptions.reduce((all, { id, value }) => ({ ...all, [id]: value }), {})
  });

  return (
    <>
      <Content>
        {basicOptions.map(({ id, slug, value }, index) => {
          const Component = COMPONENTS_MAP[slug];
          return Component ? (
            <Component
              key={index}
              id={id}
              defaultValue={value}
              search={search}
              onChange={(newValues) =>
                setLocalValue({ ...localValues, basic: { ...(localValues.basic || {}), [id]: newValues } })
              }
            />
          ) : null;
        })}

        {fieldsOptions.map(({ id, value }, index) => {
          return (
            <FieldsFilter
              key={index}
              id={id}
              defaultValue={value}
              search={search}
              onChange={(newValues) =>
                setLocalValue({ ...localValues, fields: { ...(localValues.fields || {}), [id]: newValues } })
              }
            />
          );
        })}
      </Content>

      <ApplyButton tertiary onClick={() => onApply(localValues)}>
        Done
      </ApplyButton>
    </>
  );
};

export default SearchableOptions;
