import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from '../Input';
import CircleIcon from '../CircleIcon';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { FlexMiddle } from '../Flex';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const Wrapper = styled(FlexMiddle)`
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  max-width: 200px;

  > div:first-child {
    position: absolute;
    right: 1px;
    z-index: 1;
  }

  > div:last-child {
    transition: opacity 150ms, width 500ms;
    opacity: ${({ isOpen }) => (!isOpen ? 0 : 1)};
    width: ${({ isOpen }) => (!isOpen ? '42px' : '100%')};
    max-width: 350px;
    padding: 2px;
    > input {
      padding: 9px 20px;

      &:hover,
      &:focus {
        box-shadow: ${({ theme, keepOpen }) => keepOpen && theme.colors.inputShadow};
      }
    }
  }
`;

const Search = ({ className, value, onChange, keepOpen = false }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef();
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(keepOpen);
  useOnClickOutside(wrapperRef, () => setIsOpen(false), !keepOpen && !value);

  const showCloseIcon = value.length > 0 && isOpen;

  const handleClick = useCallback(() => {
    if (showCloseIcon) {
      onChange('');
      !keepOpen && setIsOpen(false);
    } else {
      setIsOpen(true);
      inputRef.current.focus();
    }
  }, [keepOpen, onChange, showCloseIcon]);

  useEffect(() => {
    if (keepOpen) {
      inputRef.current.focus();
    }
  }, [keepOpen]);

  return (
    <Wrapper isOpen={isOpen} className={className} ref={wrapperRef} keepOpen={keepOpen}>
      <CircleIcon icon={showCloseIcon ? CloseIcon : SearchIcon} onClick={handleClick} tooltip={t('search')} />

      <Input placeholder={t('search')} value={value} onChange={onChange} inputRef={inputRef} />
    </Wrapper>
  );
};

export default Search;
