import React, { useMemo } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { smallBody } from '../../Typography';
import { TextWithTooltip } from '../../Tooltip';
import { cell } from './Cell';

const Wrapper = styled(TextWithTooltip)`
  ${cell};
  ${smallBody};
  padding-top: 13px;
  padding-bottom: 13px;
  ${({ captureRowClick }) => !captureRowClick && 'cursor: default;'}
`;

const RowCell = ({
  index,
  row,
  column: {
    Aggregate,
    Cell,
    formatter,
    dataKey,
    columnData,
    minWidth,
    width,
    maxWidth,
    flexGrow = 1,
    withTooltip,
    mustStay,
    showOnHover,
    captureRowClick = true,
    tooltip
  },
  isExpended,
  toggleRow,
  subRow,
  hasCheckboxColumn
}) => {
  const className = useMemo(
    () => `cell ${typeof showOnHover === 'function' ? showOnHover(row) : showOnHover ? 'on-hover' : ''}`,
    [row, showOnHover]
  );

  const children = useMemo(() => {
    const Container = !subRow && Aggregate ? Aggregate : Cell;
    return Container ? (
      <Container
        rowData={row}
        index={index}
        dataKey={dataKey}
        columnData={typeof columnData === 'function' ? columnData({ rowData: row }) : columnData}
        isExpended={isExpended}
        toggleRow={toggleRow}
      />
    ) : (
      formatter?.(get(row, dataKey), row) || get(row, dataKey)
    );
  }, [Aggregate, Cell, columnData, dataKey, formatter, index, isExpended, row, subRow, toggleRow]);

  const isCaptureRowClick = useMemo(
    () => (typeof captureRowClick === 'function' ? captureRowClick({ rowData: row }) : captureRowClick),
    [captureRowClick, row]
  );

  const handleClick = useMemo(() => (isCaptureRowClick ? undefined : (e) => e.stopPropagation()), [isCaptureRowClick]);

  return (
    <Wrapper
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      flexGrow={flexGrow}
      withTooltip={withTooltip}
      mustStay={mustStay}
      className={className}
      captureRowClick={isCaptureRowClick}
      onClick={handleClick}
      enabled={tooltip}
      hasCheckboxColumn={hasCheckboxColumn}>
      {children}
    </Wrapper>
  );
};

export default RowCell;
