import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useFilters from '@ubeya/shared/hooks/account/useFilters';
import useFields from '@ubeya/shared/hooks/account/useFields';
import DropDown from '../DropDown';
import { Small } from '../Typography';
import { flexColumnCenter } from '../Flex';
import BaseSearch from '../Search';
import { counterFormatter, formatOptions, applyFilter, applyFilters } from '../../utils/filters';
import PositionsFilter from './CustomFilters/PositionsFilter';
import AgeFilter from './CustomFilters/AgeFilter';
import RatingFilter from './CustomFilters/RatingFilter';
import FieldsFilter from './CustomFilters/FieldsFilter';
import EmployeeTypeFilter from './CustomFilters/EmployeeTypeFilter';
import AdminFilter from './CustomFilters/AdminFilter';
import TagsFilter from './CustomFilters/TagsFilter';
import ClientsFilter from './CustomFilters/ClientsFilter';
import LocationsFilter from './CustomFilters/LocationsFilter';
import HapAppFilter from './CustomFilters/HasAppFilter';
import SearchableOptions from './SearchableOptions';

const COMPONENTS_MAP = {
  positions: PositionsFilter,
  age: AgeFilter,
  rating: RatingFilter,
  employeeType: EmployeeTypeFilter,
  adminType: AdminFilter,
  restrictedClient: ClientsFilter,
  tags: TagsFilter,
  clients: ClientsFilter,
  locations: LocationsFilter,
  hasApp: HapAppFilter
};

const Search = styled(BaseSearch)`
  min-height: 40px;
  margin-bottom: 15px;
`;

const Counter = styled(Small)`
  ${flexColumnCenter};
  width: 17px;
  height: 14px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.gray400};
  color: ${({ theme }) => theme.colors.surface};
  margin-left: 7px;
`;

const FilterOptions = ({ selectedKey, filters, setFilters, selectedOpenFilter, disabledFilters }) => {
  const { t } = useTranslation();
  const { filters: data } = useFilters();
  const { mappedFields } = useFields();
  const [openFilter, setOpenFilter] = useState(selectedOpenFilter);
  const [search, setSearch] = useState('');

  const basicOptions = useMemo(() => formatOptions({ options: data[selectedKey], type: 'basic', filters }), [
    data,
    filters,
    selectedKey
  ]);

  const fieldsOptions = useMemo(
    () =>
      formatOptions({
        options: data[selectedKey],
        type: 'fields',
        formatSlug: (id) => mappedFields?.[id]?.name,
        filters
      }),
    [data, filters, mappedFields, selectedKey]
  );

  const getCounter = useCallback(
    (filter) => (counterFormatter(filter.value) ? <Counter>{counterFormatter(filter.value)}</Counter> : null),
    []
  );

  const handleApply = useCallback(
    (value) => {
      const { type, id } = openFilter;
      setFilters(applyFilter({ type, id, value, allFilters: filters }));
      !selectedOpenFilter && setOpenFilter();
    },
    [filters, openFilter, selectedOpenFilter, setFilters]
  );

  const handleApplyAll = useCallback(
    (values) => {
      setFilters(applyFilters(values));
      setSearch('');
    },
    [setFilters]
  );

  if (openFilter) {
    const Component = COMPONENTS_MAP[openFilter.slug] || FieldsFilter;
    return (
      <>
        <Component
          id={openFilter.id}
          defaultValue={openFilter.value}
          handleClose={selectedOpenFilter ? undefined : () => setOpenFilter()}
          onApply={handleApply}
        />
      </>
    );
  }

  return (
    <>
      <Search value={search} onChange={setSearch} keepOpen />

      {search ? (
        <SearchableOptions
          search={search}
          basicOptions={basicOptions}
          fieldsOptions={fieldsOptions}
          COMPONENTS_MAP={COMPONENTS_MAP}
          onApply={handleApplyAll}
        />
      ) : (
        <>
          {basicOptions.map((filter, index) => (
            <DropDown.Item
              key={index}
              onClick={() => setOpenFilter(filter)}
              closeOnClick={false}
              isDisabled={disabledFilters.includes(filter.slug)}>
              {t(filter.slug)} {getCounter(filter)}
            </DropDown.Item>
          ))}

          {basicOptions.length > 0 && fieldsOptions.length > 0 && <DropDown.Separator />}

          {fieldsOptions.map((filter, index) => (
            <DropDown.Item
              key={index}
              onClick={() => setOpenFilter(filter)}
              closeOnClick={false}
              isDisabled={disabledFilters.includes(filter.slug)}>
              {t(filter.slug)} {getCounter(filter)}
            </DropDown.Item>
          ))}
        </>
      )}
    </>
  );
};

export default FilterOptions;
