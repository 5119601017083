import React, { useContext } from 'react';
import { StyleSheetManager } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import { ErrorBoundary } from '@ubeya/shared-web/components';
import useTranslatedApp from '@ubeya/shared/hooks/useTranslatedApp';
import GlobalStyle from '@ubeya/shared-web/style/global';
import LanguageContext, { LanguageContextProvider } from 'shared/context/LanguageContext';
// import Welcome from 'welcome';
import CreateAccount from 'createAccount';

const AuthorizedApp = React.memo(({ children }) => {
  const { language } = useContext(LanguageContext);
  const isRtl = useTranslatedApp(language);
  const rtlSupport = isRtl ? [stylisRTLPlugin] : undefined;

  return (
    <StyleSheetManager stylisPlugins={rtlSupport}>
      <>
        <GlobalStyle isRtl={isRtl} />
        <ErrorBoundary>{children}</ErrorBoundary>
      </>
    </StyleSheetManager>
  );
});

const AppShell = () => (
  <LanguageContextProvider>
    <AuthorizedApp>
      <Switch>
        <Redirect exact from="/" to="/createAccount" />
        <Route path="/createAccount" component={CreateAccount} />
      </Switch>
    </AuthorizedApp>
  </LanguageContextProvider>
);

export default AppShell;
